
import axios from 'axios'
import store from '@/store'
import router from '../router'


export default () => {

    let instance = axios.create({
        baseURL: process.env.VUE_APP_NODE_ENV == "development" || process.env.VUE_APP_NODE_ENV == 'dev' ? process.env.VUE_APP_BASE_URL : process.env.VUE_APP_BASE_URL_PROD,
        headers: {
            // Authorization: `Bearer ${store.state.token}`
            auth: `${store.state.token}`

        }
    })

    instance.interceptors.response.use(
        (response) => {
            return response
        },
        function (error) {
            let originalRequest = error.config
            if (error.response.status === 401 && !originalRequest._retry) {
                store.dispatch('general/setNotification', {
                    title: "Error",
                    message:
                    error.response.data.mensaje || "Error",
                    timeout: "60000",
                    color: "error",
                  })
                originalRequest._retry = true
                store.dispatch("LOGOUT_REQUEST").then(() => {
                    router.push("/login").catch(() => { });
                })
            }
            // Do something with response error
            return Promise.reject(error)
        })


    return instance


}