import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';


import tabladatos from "../components/svg/tabladatos.vue"
import accesoespecialistas from "../components/svg/accesoespecialistas.vue"
import fdtsol from "../components/svg/fdtsol.vue"
import config from "@/components/svg/config.vue"


Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        values: {
            tabladatos: { // name of our custom icon
                component: tabladatos, // our custom component
            },
            accesoespecialistas: { // name of our custom icon
                component: accesoespecialistas, // our custom component
            },
            fdtsol: { // name of our custom icon
                component: fdtsol, // our custom component
            },
            config: {
                component: config
            }
        }
    }

});
