<template>
    <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="true"
        :paginate-elements-by-height="1100" filename="informe" :pdf-quality="2"  :manual-pagination="true" pdf-format="a4" pdf-orientation="landscape" pdf-content-width="1200px"
        @progress="onProgress($event)" @hasStartedGeneration="hasStartedGeneration()" @hasGenerated="hasGenerated($event)"
        ref="html2Pdf">


        <section slot="pdf-content">

            <section class="pdf-item">
                <v-app >
                    <!-- <v-btn @click="generateReport()"></v-btn> -->
                    <v-main class="grey lighten-4">

                        <slot ></slot>

                    </v-main>
                </v-app>
            </section>


        </section>




    </vue-html2pdf>
</template>

<script>

// import VueHtml2pdf from 'vue-html2pdf'
import VueHtml2pdf from '@/plugins/vue-html2pdf.vue';

// import Camara from '../simulacion/camara.vue'
import { mapState } from 'vuex'

export default {
    name: "informe",
    // props: {
    //     data: {
    //         type: Object
    //     },
    //     camaras: {
    //         type: Array
    //     }
    // },
    components: {
        VueHtml2pdf
    },

    data: () => ({

    }),

    methods: {


        /*
            Generate Report using refs and calling the
            refs function generatePdf()
        */
        generateReport() {

            // this.$refs.camaraActual.dale()
            // this.$refs.camaraProyeccion.dale()
            this.$refs.html2Pdf.generatePdf()


        },

        onProgress($event) {

            if ($event == 100) {
                this.$emit('done', true)
            }
        },

        hasStartedGeneration() {
            console.log("hasStartedGeneration");
        },

        hasGenerated($event) {
            console.log("hasGenerated", $event);
        },

        venceMandato(agrupacion) {

            let res = this.distribuciones.map(agrupaciondistribucion => agrupaciondistribucion.Fuerzas.find(fuerza => fuerza.Fuerza == agrupacion))
            return res.reduce((cantidadAcumulador, cantidad) => cantidadAcumulador + cantidad.Renueva, 0)
        },

        obtenidos(agrupacion) {

            let res = this.distribuciones.map(agrupaciondistribucion => agrupaciondistribucion.Fuerzas.find(fuerza => fuerza.Fuerza == agrupacion))
            return res.reduce((cantidadAcumulador, cantidad) => cantidadAcumulador + cantidad.Ganadas, 0)
        },

        venceMandatoDistrito(fuerza) {

            return fuerza.Renueva

            // let res = this.distribuciones.map(agrupaciondistribucion => agrupaciondistribucion.Fuerzas.find(fuerza => fuerza.Fuerza == agrupacion))
            // return res.reduce((cantidadAcumulador, cantidad) => cantidadAcumulador + cantidad.Renueva, 0)
        },

        obtenidosDistrito(fuerza) {

            return fuerza.Ganadas
            // let res = this.distribuciones.map(agrupaciondistribucion => agrupaciondistribucion.Fuerzas.find(fuerza => fuerza.Fuerza == agrupacion))
            // return res.reduce((cantidadAcumulador, cantidad) => cantidadAcumulador + cantidad.Ganadas, 0)
        },

        ganadosDistrito(fuerza) {
            // console.log(fuerza);
            return this.obtenidosDistrito(fuerza) - this.venceMandatoDistrito(fuerza)
        },

        totalDistrito(fuerza) {
            // console.log(fuerza);
            return fuerza.Bancas - fuerza.Renueva + fuerza.Ganadas
        },

        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }




    },

    computed: {

        proyeccion() {
            let camara = this.camaras.find(c => this.data.Id == c.value)
            return (camara) ? camara.text : ''
        },

        distribuciones() {
            return this.data.Distribucion.filter((d) => d.Fuerzas.reduce((acc, f) => acc + f.Renueva, 0) > 0)

        },

        cantidadVotosTotales() {
            return (!this.distribuciones.length) ? 0 : this.distribuciones.reduce((acc, cant) => acc + Number(((cant.Electores * cant.Participacion) / 100).toFixed()), 0)
        },

        cantidad() {
            return (!this.distribuciones.length) ? 0 : this.distribuciones.reduce((acc, cant) => acc + Number(((cant.Electores * cant.Participacion) / 100).toFixed()), 0)
        },

        electores() {
            return this.distribuciones.reduce((cantidadAcumulador, cantidad) => cantidadAcumulador + cantidad.Electores, 0)
        },

        participacion() {
            return this.data.Distribucion.reduce((cantidadAcumulador, cantidad) => cantidadAcumulador + cantidad.Participacion, 0)
        },

        blancos() {
            // console.log(this.distribuciones[0]);
            return this.distribuciones.reduce((acc, d) => acc + Number((Number(((d.Electores * d.Participacion) / 100).toFixed() * d.Blancos) / 100).toFixed()), 0)
        },

        nulos() {
            return this.distribuciones.reduce((acc, d) => acc + Number((Number(((d.Electores * d.Participacion) / 100).toFixed() * d.Nulos) / 100).toFixed()), 0)


        },

        ...mapState([
            'dataResultados',
        ])


    }


    // mounted() {
    //     console.log('mounted');
    //     this.generateReport()
    // }


}
</script>


<style lang="scss">
.twolines {
    height: 2rem;
    line-height: 1em;
}

.informetable {
    font-size: 0.7rem;

    td,
    th,
    .subheaderinforme {
        border: 1px solid #f1efef;
        padding: 0px !important;
        font-size: 0.7rem !important;
    }

    ;

}
</style>