<template>
  <v-row class="grey lighten-4 pt-5">
    <v-col cols="12">
      <v-chip label class="mr-2 text-subtitle-2" color="primary">{{
        categoria
      }}</v-chip>
      <span class="grey--text">Filtro aplicado:</span>
      <v-breadcrumbs class="pl-1 d-inline" divider="/" :items="breadcrumbs">
      </v-breadcrumbs>
    </v-col>
    <v-col v-if="!grafico.resultado.valores[0].cociente" md="1"> </v-col>

    <v-col cols="12" :md="grafico.resultado.valores[0].cociente ? '6' : '10'">
      <p class="text-h4">Votos obtenidos</p>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th width="40%" class="text-left text-capitalize">
                {{ grafico.resultado.tipo }}
              </th>
              <th width="30%" class="text-center">Union por la patria</th>
              <th width="30%" class="text-center">Juntos por el cambio</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(valor, i) in orderVal(grafico.resultado.valores)"
              :key="i"
            >
              <td>
                <strong> {{ getDescription(valor.zonaId) }}</strong>
              </td>
              <td class="pr-0">
                <v-progress-linear
                  :value="valor.fdt ? valor.fdt.votosPorcentaje : 0"
                  reverse
                  class="left"
                  :buffer-value="valor.fdt ? valor.fdt.votosPorcentaje : 0"
                  height="25"
                >
                  <strong>
                    {{ valor.fdt ? valor.fdt.votos : 0 }}({{
                      valor.fdt ? valor.fdt.votosPorcentaje : 0
                    }}%)</strong
                  >
                </v-progress-linear>
              </td>
              <td class="pl-0">
                <v-progress-linear
                  :value="valor.jpc ? valor.jpc.votosPorcentaje : 0"
                  color="yellow"
                  class="right"
                  :buffer-value="valor.jpc ? valor.jpc.votosPorcentaje : 0"
                  height="25"
                >
                  <strong>
                    {{ valor.jpc ? valor.jpc.votos : 0 }}({{
                      valor.jpc ? valor.jpc.votosPorcentaje : 0
                    }}%)</strong
                  >
                </v-progress-linear>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>

    <v-col cols="12" md="6" v-if="grafico.resultado.valores[0].cociente">
      <p class="text-h4">Bancas a obtener</p>

      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th style="width: 20%" class="text-left text-capitalize">
                {{ grafico.resultado.tipo }}
              </th>
              <th style="width: 20%" class="text-center text-capitalize">Cociente</th>
              <th style="width: 20%" class="text-center">Bancas totales</th>
              <th style="width: 20%" class="text-center">Frente de Todos</th>
              <th style="width: 20%" class="text-center">
                Juntos por el Cambio
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(valor, i) in orderVal(grafico.resultado.valores)" :key="i">
              <td >
                <strong class="text-no-wrap"> {{ getDescription(valor.zonaId) }}</strong>
              </td>
              <td>
                {{ valor.cociente | formatPorcentaje }}
              </td>
              <td class="text-center">
                {{ valor.bancasEnJuego }}
              </td>

              <!-- 
              <td class="text-center">
                {{ valor.fdt.bancas }}
              </td> -->

              <td class="pr-0">
                <v-progress-linear
                  :value="valor.fdt ? valor.fdt.bancasPorcentaje : 0"
                  reverse
                  :buffer-value="valor.fdt ? valor.fdt.bancasPorcentaje : 0"
                  height="25"
                >
                  <strong> {{ valor.fdt ? valor.fdt.bancas : 0 }}</strong>
                </v-progress-linear>
              </td>
              <td class="pl-0">
                <v-progress-linear
                  :value="valor.jpc ? valor.jpc.bancasPorcentaje : 0"
                  color="yellow"
                  :buffer-value="valor.jpc ? valor.jpc.bancasPorcentaje : 0"
                  height="25"
                >
                  <strong> {{ valor.jpc ? valor.jpc.bancas : 0 }}</strong>
                </v-progress-linear>
              </td>
              <!-- <td class="text-center">
                {{ valor.jpc.bancas }}
              </td> -->
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <!-- <apexchart :options="chartOptions"  :series="series"></apexchart>
      <div class="customcard">
        <v-card-text>
          <v-row align="center" justify="center">
            <v-col cols="3" sm="3">
              <v-img contain max-width="100px" :src="require('@/assets/img/images/240501.png')"></v-img>
            </v-col>
            <v-col cols="6" sm="6">
              <p class="text-left text-subtitle-1">Frente de Todos</p>
            </v-col>

            <v-col cols="3" sm="3">
              <p class="text-right text-subtitle-2 mb-0">
                {{ grafico.resultado.fdtPorcentaje | formatPorcentaje }}%
              </p>
              <p class="text-right text-subtitle-2">
                {{ grafico.resultado.fdtVotosTotal }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </div>

      <div class="pt-2 mt-2 customcard">
        <v-card-text>
          <v-row align="center" justify="center">
            <v-col cols="3" sm="3" class="pt-4">
              <v-img contain max-width="100px" :src="require('@/assets/img/images/240502.png')"></v-img>
            </v-col>
            <v-col cols="6" sm="6">
              <p class="text-left text-subtitle-1">Juntos por el cambio</p>
            </v-col>

            <v-col cols="3" sm="3">
              <p class="text-right text-subtitle-2 mb-0">
                {{ grafico.resultado.jpcPorcentaje | formatPorcentaje }}%
              </p>
              <p class="text-right text-subtitle-2">
                {{ grafico.resultado.jpcVotosTotal }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
      </div> -->
    </v-col>
  </v-row>
</template>

<script>
import dataJson from "../json/data.json";
import distritosJson from "../json/distritos.json";
import seccionesProvincialesJson from "../json/seccionesProvinciales.json";
import secciones from "../json/secciones.json";

import { mapState } from "vuex";

export default {
  name: "grafico",
  props: {
    grafico: {
      type: Object,
    },
    series: {
      type: Array,
    },
  },

  data() {
    return {
      modal: false,
      loading: true,
      intervalSeconds: 300,
      eliminando: false,
      filtros: [],
      filtroID: null,

      breadcrumbs: [],

      dataFilter: dataJson,
      dSecciones: secciones,
      dSeccionesProvinciales: seccionesProvincialesJson,
      dDistritos: distritosJson,

      chartOptions: {
        chart: {
          width: 600,
          type: "pie",
        },
        legend: {
          show: false,
        },
        colors: ["#2196F3", "#FFEB3B", "#9E9E9E"],
        labels: ["Frente de Todos", "Juntos", "Otros"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 600,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },

      // series: [
      //   this.formatPorcentaje(this.grafico.resultado.fdtPorcentaje),
      //   this.formatPorcentaje(this.grafico.resultado.jpcPorcentaje),
      //   this.formatPorcentaje(this.grafico.resultado.restoPorcentaje),
      // ],
    };
  },

  methods: {
    orderVal(valores) {
      let aux = [...valores];

      return  aux.sort((a, b) => {
        if (this.getDescription(a.zonaId) < this.getDescription(b.zonaId)) {
          return -1;
        }
        else if (this.getDescription(a.zonaId) > this.getDescription(b.zonaId)) {
          return 1;
        } else {

          return 0;
        }
      });

      // return aux;

      //return valores
    },

    formatBreadcrumb() {
      let auxbreadcrumb;
      auxbreadcrumb = this.grafico.breadcrumb
        ? this.grafico.breadcrumb.split(",")
        : [];

      this.breadcrumbs = auxbreadcrumb.map((bc) => {
        return {
          text: bc,
          disabled: true,
          href: "#",
        };
      });
    },

    getDescription(val) {
      let descripcion = "";
      let aux = "";

      // console.log(this.grafico.resultado.tipo);

      switch (this.grafico.resultado.tipo) {
        case "seccion":
          aux = descripcion = this.dSecciones.find(
            (s) => s.IDSeccion == val && s.IDDistrito == this.grafico.distritoId
          );
          descripcion = aux ? aux.Seccion : "";
          // console.log('seccion',this.dSecciones.filter((s) => s.IDSeccion == val));
          // console.log('seccionProvincial',this.dSeccionesProvinciales.filter((sp) => sp.IDSeccionProvincial == val ));
          // console.log('distrito',this.dDistritos.filter ((d) => d.IDDistrito == val));
          // console.log(this.grafico);
          // console.log('seccion',this.dSecciones.find((s) => s.IDSeccion == val && s.IDDistrito ==  this.grafico.distritoId));
          break;

        case "seccionProvincial":
          aux = this.dSeccionesProvinciales.find(
            (sp) => sp.IDSeccionProvincial == val
          );
          descripcion = aux ? aux.SeccionProvincial : "";
          // console.log('seccionProvincial',this.dSeccionesProvinciales.filter((s) => s.IDSeccionProvincial == parseInt(val) && s.IDDistrito ==  this.grafico.resultado.distritoID ));
          break;

        case "distrito":
          aux = this.dDistritos.find((d) => d.IDDistrito == val);
          descripcion = aux ? aux.Distrito : "";

          // console.log('distritos',this.dDistritos.find ((d) => d.IDDistrito == val));
          break;

        default:
          break;
      }
      return descripcion;
    },

    formatPorcentaje(val) {
      return parseFloat(val.toFixed(2));
    },
  },

  computed: {
    ...mapState(["categorias"]),

    categoria() {
      return this.grafico.categoriaId
        ? this.categorias.find(
            (cat) => cat.categoriaId == this.grafico.categoriaId
          ).nombre
        : "";
    },
  },

  created() {
    this.formatBreadcrumb();
  },
};
</script>

<style>
.left > div.v-progress-linear__content {
  justify-content: start;
}

.right > div.v-progress-linear__content {
  justify-content: end;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

tbody.clean tr:nth-of-type(odd) {
  background-color: #fff;
}
</style>