<template>
  <v-container   fluid>
    <v-container v-if="loading" fluid>
      <v-row style="height: 80vh" justify="center" align="center">
        <v-progress-circular :size="50" :width="7" color="primary" indeterminate></v-progress-circular>
      </v-row>
    </v-container>
    <v-container v-else-if="mesas">
      <v-row>
        <v-col>

          <v-btn class="float-right py-0 mx-2" color="primary" elevation="0" :loading="loadReport" small
            @click="downloadReport()">
            <v-icon x-small>mdi mdi-download</v-icon>

          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <apexchart :options="chartOptions" :height="getHeight" :series="series"></apexchart>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left text-capitalize">Estado</th>
                  <th class="text-center">Cantidad</th>
                  <th class="text-center">Porcentaje</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(valor, i) in valores" :key="i">
                  <td>
                    <strong>
                      {{ valor.estado }}
                    </strong>
                  </td>
                  <td class="pr-0">
                    {{ valor.acumulado }}
                  </td>
                  <td class="px-0 ">
                    <v-progress-linear :value="valor.acumuladoPorcentaje" color="primary" height="25">
                      <strong>
                        {{ valor.acumuladoPorcentaje | formatPorcentaje }}%
                      </strong>
                    </v-progress-linear>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
    <informe v-if="mesas.length" ref="informe" @done="done($event)">
      <avanceInforme :mesas="mesas"></avanceInforme>
    </informe>
  </v-container>
</template>

<script>
import axios from "axios";
import avanceService from "@/services/AvancesService";
import avanceInforme from '../components/informes/AvanceInforme.vue'
import informe from '../components/informe.vue'

import { mapState } from "vuex";
export default {
  name: "avance",
  comments: {
    avanceInforme,informe
  },
  data() {
    return {
      loadReport : false,

      intervalSeconds: 120,
      loading: true,
      mesas: [],

      valores: [],

      chartOptions: {
        legend: {
          show: true,
        },
        chart: {
          type: "treemap",
        },
        title: {
          text: "Avance mesas escrutadas",
          align: "center",
        },
        colors: ["#37bbecff", "#c4c4c4ff", "#10bb49ff", "#f79625ff"],
        plotOptions: {
          treemap: {
            distributed: true,
            enableShades: false,
          },
        },
      },

      series: [
        {
          data: [],
        },
      ],
    };
  },
  mounted() {
    this.getAll();
    setInterval(() => {
      this.user?.username != null && this.getAll();
    }, this.intervalSeconds * 1000);
  },
  methods: {

    done(event) {
      console.log("temrino", event);
      this.loadReport = false;
    },

    downloadReport() {
      this.loadReport = true;
      this.$refs.informe.generateReport()
    },
    
    formatPorcentaje(val) {
      val = parseFloat(val);
      return parseFloat(val.toFixed(2));
    },

    instanciarFormato() {
      this.series = [
        {
          data: [],
        },
      ];

      let auxData = [];
      this.mesas.procesado.forEach((m) => {
        auxData.push({
          x:
            m.estado +
            "(" +
            this.formatPorcentaje(m.acumuladoPorcentaje) +
            "%)",
          y: m.acumulado,
        });
      });



      this.valores = [...this.mesas.valores];

      this.valores = this.valores.sort((a, b) => {
        return a.orden - b.orden;
      });

      // let totales = this.valores.find(
      //   (valor) => valor.estado == "Telegramas totales"
      // );

      // if (totales) {
      //   const index = this.valores.indexOf(totales);
      //   if (index > -1) {
      //     this.valores.splice(index, 1);
      //   }

      //   this.valores.push(totales);
      // }


      this.series[0].data = auxData;
    },

    getAll() {
      // console.log("le pega al getAll");
      axios
        .all([this.getMesas()])
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getMesas() {
      try {
        let dataMesas = await avanceService.all();

        this.mesas = dataMesas.data;

        this.instanciarFormato();
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  },
  created() {
    // this.id = this.filtro.id;
    if (this.mesas.length > 0) {
      this.instanciarFormato();
    }
  },
  computed: {
    ...mapState(["user"]),

    getHeight() {
      return window.innerHeight * 0.8;
    },
  },
};
</script>

<style></style>