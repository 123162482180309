<template>
  <v-container fluid>
    <div class="home grey lighten-4">
      <v-container v-if="loading" fluid>
        <v-row style="height: 80vh" justify="center" align="center">
          <v-progress-circular :size="50" :width="7" color="primary" indeterminate></v-progress-circular>
        </v-row>
      </v-container>

      <v-container v-else-if="filtros.length" fluid>
        <v-row>
          <v-col cols="12" lg="8">
            <v-tabs v-model="filtroID" color="blue accent-4" background-color="grey lighten-4">
              <!-- <v-btn v-for="(filtro, i) in filtros" :key="i">
            {{filtro.nombre}}
          </v-btn> -->
              <v-tab class="white" v-for="filtro in filtros" :key="filtro._id">{{
                filtro.nombre
              }}</v-tab>
            </v-tabs>
          </v-col>
          <v-col lg="4" :class="{ 'text-right': $vuetify.breakpoint.smAndUp, 'text-center': $vuetify.breakpoint.xsOnly }">

            <v-spacer></v-spacer>
            <v-btn-toggle multiple class="mr-2 grey lighten-4" justify="center" align="center">
              <v-btn color="green" @click="toggleModalFilter" small class="white--text">Nuevo Tablero
                <v-icon class="white--text">mdi-filter</v-icon>
              </v-btn>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="red" @click.stop="activarModalEliminarFiltro()" small v-bind="attrs" v-on="on">
                    <v-icon color="white" small>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar Tablero</span>
              </v-tooltip>
            </v-btn-toggle>
            <v-btn-toggle v-model="format" class="grey lighten-4">
              <v-btn small class="d-none d-md-block">
                <v-icon small>mdi-view-list</v-icon>
              </v-btn>

              <v-btn small class="d-none d-md-block">
                <v-icon small>mdi-view-grid</v-icon>
              </v-btn>
            </v-btn-toggle>

            <v-btn class="py-0 mx-2" color="primary" elevation="0" :loading="loadReport" small @click="downloadReport()">
              <v-icon x-small>mdi mdi-download</v-icon>

            </v-btn>

            <!-- <v-btn fab small  color="primary" dark><v-icon color="white ">mdi-plus</v-icon></v-btn> -->
          </v-col>
        </v-row>

        <v-tabs-items v-model="filtroID">
          <v-tab-item v-for="filtro in filtros" :key="filtro._id">
            <tab :filtro="filtro" />
          </v-tab-item>
        </v-tabs-items>
      </v-container>

      <v-container v-else fluid>
        <v-row align="center">
          <v-col cols="12">
            <p class="text-h3">No existe tablero creado.</p>
          </v-col>

          <v-col cols="12">
            <v-btn color="green" @click="toggleModalFilter" dark>Nuevo tablero
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-dialog v-model="modalEliminar" persistent max-width="300">
        <v-card>
          <v-card-title class="text-h5"> Eliminar Tablero </v-card-title>
          <v-card-text class="text-left">¿Esta seguro que desea eliminar el Tablero "{{
            filtroEliminar.nombre
          }}" ?
          </v-card-text>
          <v-card-actions>
            <v-btn color="gray darken-1" @click="closeModalEliminar()" :disabled="eliminando">
              Cancelar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1 white--text" @click="eliminarFiltro()" :disabled="eliminando">
              <v-progress-circular v-show="eliminando" :size="15" :width="3" color="white"
                indeterminate></v-progress-circular>
              Eliminar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <filtros />
    <informe ref="informe" @done="done($event)" >
      <tableroInforme  :filtros="filtros"  ></tableroInforme>
    </informe>
  </v-container>
</template>

<style >
.cls-1 {
  fill: white;
}
</style>

<script>
// import CardAlianza from "../components/cardAlianza.vue";
import filtrosService from "@/services/FiltrosService";
import Tab from "../components/tab.vue";
import axios from "axios";
import { mapState, mapMutations } from "vuex";
import Filtros from "../components/filtros.vue";
import informe from '../components/informe.vue'
import tableroInforme from '../components/informes/Tablero.vue'
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Home",
  components: { Tab, Filtros, informe,tableroInforme },
  created() {
    this.$root.$refs.home = this;
    console.log("🚀 ~ file: Tablero.vue:131 ~ created ~ this.$root.$refs.home:", this.$root.$refs.home)
    if (this.$vuetify.breakpoint.smAndDown) {
      this.format = 0;
    }

  },
  data() {
    return {
      loadReport: false,
      modal: false,
      loading: true,
      intervalSeconds: 120,
      eliminando: false,
      filtros: [],
      filtroID: null,
      filtro: {},
    };
  },
  methods: {

    done(event) {
      console.log("temrino", event);
      this.loadReport = false;
    },

    downloadReport() {
      this.loadReport = true;
      this.$refs.informe.generateReport()
    },
    ...mapMutations(["closeModalEliminar", "toggleModalFilter"]),
    logout() {
      this.$store.dispatch("LOGOUT_REQUEST").then(() => {
        this.$router.push("/login");
      });
    },

    activarModalEliminarFiltro() {
      // console.log(this.filtroID);
      this.filtro = this.filtros[this.filtroID];
      this.$store.commit("modalEliminarFiltro", {
        id: this.filtro._id,
        nombre: this.filtro.nombre,
      });
    },

    getAll() {
      // console.log("le pega al getAll");
      axios
        .all([this.getFiltros()])
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          // this.showToast({
          //   vm: this,
          //   payload: {
          //     title: err.response.data.title || "Error",
          //     message: err.response.data.message || "",
          //     variant: "danger",
          //     status: err.response.status,
          //   },
          // });
        });
    },

    async eliminarFiltro() {
      try {
        this.eliminando = true;

        filtrosService.del(this.filtroEliminar.id).then(() => {
          this.eliminando = false;
          this.getFiltros();
          this.closeModalEliminar();
        });

        // this.filtros = this.filtros.filter((filtro) => {
        //   filtro.id == this.filtroEliminar.id;
        // });
      } catch (error) {
        this.eliminando = false;
        console.log(error);
        throw error;
      }
    },

    async getFiltros() {
      try {
        let dataFiltros = await filtrosService.all();

        this.filtros = dataFiltros.data.filtros;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  },
  mounted() {
    this.getAll();
    setInterval(() => {
      this.user?.username != null && this.getAll();
    }, this.intervalSeconds * 1000);


  },
  computed: {
    ...mapState(["modalEliminar", "filtroEliminar", "user"]),

    format: {
      get() {
        return this.$store.state.format;
      },
      set(val) {
        this.$store.commit("setFormat", val);
      },
    },
  },
};
</script>
