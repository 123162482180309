export default {
    namespaced:true,

    state: {

        notifications: []
        // toast: []
    
    },
    
    mutations: {
      
        SET_NOTIFICATION(state, notification) {
            state.notifications = state.notifications.concat(notification);
        },

    
    },

    actions : {

        setNotification({ commit }, notification) {
            notification.showing = true;
            notification.color = notification.color || 'black';
            commit('SET_NOTIFICATION', notification);
        },

        showToast(context, { payload }) {
            console.error('showToast deprecado, reemplazar por setNotification', [context,payload]);
        }

    }
}