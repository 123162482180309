import Api from '@/services/Api'

export default {
    all(credentials) {
        return Api().get('user', credentials)
    },

    add(credentials) {
        return Api().post('user', credentials)
    },
    
    del(credentials) {
        return Api().delete('user/' + credentials)
    },

    edit(queryparam,credentials) {
        return Api().patch('user/' + queryparam, credentials)
    },

    editPassword(credentials) {
        return Api().post('user/change_password', credentials)
    },

}