<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="8" class="py-0">
        <p :class="[(black) ? 'font-weight-bold' : 'font-weight-light', 'text-left text-subtitle-1 mb-0']">
          {{ partido.nombreLista }}
        </p>
      </v-col>
      <v-col cols="12" md="4" class="py-0 text-right">
        <span v-if="partido.votos" :class="[(black) ? 'font-weight-bold' : 'font-weight-light', 'text-subtitle-2']">
          {{ partido.votos | formatNumbers }} - 
        </span>
        <span :class="[(black) ? 'font-weight-bold' : 'font-weight-light', 'text-subtitle-2']">{{ partido.votosPorcentaje |
          formatPorcentaje }}% votos</span>

      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-progress-linear :color="color" class="my-2" :value="partido.votosPorcentaje" height="10"
          rounded></v-progress-linear>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>



export default {
  name: "agrupacionProgress",
  props: {
    color: String,
    black: Boolean,
    partido: {
      type: Object,
      default() {
        return {};
      },
    },


  },
  methods: {

    porcentVotosPartido(partido, partidos) {
      let totalVotosPartido = partidos.reduce(
        (sum, value) => sum + value.votos,
        0
      );
      return ((partido.votos * 100) / totalVotosPartido).toFixed(2);
    },
  },

};
</script>

<style></style>