<template>
  <v-container fluid>


    <v-row v-if="loading" style="height: 80vh" justify="center" align="center">
      <v-progress-circular :size="50" :width="7" color="primary" indeterminate></v-progress-circular>
    </v-row>

    <v-row v-else>
      <v-col class="home lighten-4 ">
        <v-card-title class=" black--text text-h5 justify-center d-print-block">
          Porcentaje de votos y mesas escrutadas
        </v-card-title>



        <div class="white">


          <v-card-text>
            <v-select :items="graficos" v-model="selectGrafico" v-on:change="updateChartOptions(selectGrafico)"
              label="Distrito"></v-select>

          </v-card-text>

          <v-card-text class="d-print-block">
            <v-card-title class="justify-center text-h5">
              {{ nombreDistrito(getResultado.distritoId) }}
            </v-card-title>

            <div v-if="getResultado.distritoId == '99'">
              <v-row>
                <v-col cols="12">
                  <apexchart type="line" height="450" :options="chartOptions" :series="series[0]"></apexchart>
                </v-col>
                <v-col cols="6" class="mx-0">
                  <v-select :items="distritosSelect" v-model="valueSelect1" v-on:change="updateChartOptionsDashboard()"
                    label="Distrito"></v-select>
                  <apexchart type="line" height="300" :options="chartOptionsSmall"
                    :series="mapearSerie(selecSeries(valueSelect1))">
                  </apexchart>

                </v-col>
                <v-col cols="6" class="mx-0">
                  <v-select :items="distritosSelect" v-model="valueSelect2" v-on:change="updateChartOptionsDashboard()"
                    label="Distrito"></v-select>
                  <apexchart type="line" height="300" :options="chartOptionsSmall2"
                    :series="mapearSerie(selecSeries(valueSelect2))">
                  </apexchart>

                </v-col>
              </v-row>
            </div>
            <div v-else>
              <apexchart type="line" height="450" :key="Number(getResultado.distritoId)" :options="chartOptions"
                :series="series[Number(getResultado.distritoId)]"></apexchart>


              <table width="100%">

                <thead>
                  <tr>
                    <th v-for="(header, index) in headers" :key="index" class="text-left">
                      {{ header.text }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in itemsForm(getResultado)" :key="index">
                    <td class="text-right">{{ item.hora }}</td>
                    <td class="text-right">{{ item.mesas }}</td>
                    <td class="text-right">{{ item.mesasPorcentaje }}</td>
                    <td class="text-right">{{ item.votosuxp }}</td>
                    <td class="text-right"> <span
                        :class="getColor(item.uxpporcentajevotos, (getPreviousItem(getResultado, item)).uxpporcentajevotos)">


                        <v-icon
                          v-if="item.uxpporcentajevotos == (getPreviousItem(getResultado, item)).uxpporcentajevotos">
                        </v-icon>
                        <v-icon
                          v-else-if="item.uxpporcentajevotos > (getPreviousItem(getResultado, item)).uxpporcentajevotos"
                          color="green">
                          mdi-arrow-up-thin
                        </v-icon>
                        <v-icon v-else color="red">
                          mdi-arrow-down-thin
                        </v-icon>

                        {{ item.porcentajeuxp }}

                      </span></td>
                    <!-- <td class="text-right">{{ item.uxpporcentajevotos }}</td> -->
                    <!-- <td class="text-right">{{ item.votosjxc }}</td>
                    <td class="text-right">
                      <span
                        :class="getColor(item.jxcporcentajevotos, (getPreviousItem(getResultado, item)).jxcporcentajevotos)">

                        <v-icon
                          v-if="item.jxcporcentajevotos == (getPreviousItem(getResultado, item)).jxcporcentajevotos">
                        </v-icon>
                        <v-icon
                          v-else-if="item.jxcporcentajevotos > (getPreviousItem(getResultado, item)).jxcporcentajevotos"
                          color="green">
                          mdi-arrow-up-thin
                        </v-icon>
                        <v-icon v-else color="red">
                          mdi-arrow-down-thin
                        </v-icon>

                        {{ item.porcentajejxc }}
                      </span>
                    </td> -->
                    <!-- <td class="text-right">{{ item.jxcporcentajevotos }}</td> -->
                    <td class="text-right">{{ item.votoslla }}</td>
                    <td class="text-right">
                      <span
                        :class="getColor(item.llaporcentajevotos, (getPreviousItem(getResultado, item)).llaporcentajevotos)">

                        <v-icon
                          v-if="item.llaporcentajevotos == (getPreviousItem(getResultado, item)).llaporcentajevotos">
                        </v-icon>
                        <v-icon
                          v-else-if="item.llaporcentajevotos > (getPreviousItem(getResultado, item)).llaporcentajevotos"
                          color="green">
                          mdi-arrow-up-thin
                        </v-icon>
                        <v-icon v-else color="red">
                          mdi-arrow-down-thin
                        </v-icon>

                        {{ item.porcentajella }}
                      </span>
                    </td>
                    <!-- <td class="text-right">{{ item.llaporcentajevotos }}</td> -->


                    <!-- <td class="text-right">{{ item.distritoId }}</td>
                    <td class="text-right">{{ item.nombre }}</td>
                    <td class="text-right">{{ item.mesasEsperadas }}</td>
                    <td class="text-right">{{ item.mesasTotalizadas }}</td>
                    <td class="text-right">{{ item.cantidadVotantes }}</td>
                    <td class="text-right">{{ item.participacionPorcentaje }}</td>
                    <td class=" text-right" style="background-color:#00a8e09e">{{ item.proyectadouxp }} </td>
                    <td class=" text-right" style="background-color:#fedc009a">{{ item.proyectadojxc }} </td>
                    <td class=" text-right" style="background-color:#753bbd93">{{ item.proyectadolla }} </td>
                    <td class=" text-right" style="background-color:#43488f8a">{{ item.proyectadohpnp }} </td>
                    <td class=" text-right" style="background-color:#f9546298">{{ item.proyectadofit }} </td>
                    <td class=" text-right" style="background-color:#7e7e7e61">{{ item.vvProyectado }} </td> -->

                  </tr>
                </tbody>

              </table>
      
            </div>
            <!-- <apexchart type="line" height="450" :options="chartOptions" :series="series[index]"></apexchart> -->



          </v-card-text>
        </div>


      


      </v-col>
    </v-row>
  </v-container>
</template>

<style >
.cls-1 {
  fill: white;
}

.apexcharts-toolbar {
  z-index: 0 !important;
}
</style>

<script>
// import CardAlianza from "../components/cardAlianza.vue";
// import ParabolaEquation from "@/services/ParabolaEquation.ts"
// import filtrosService from "@/services/FiltrosService";
import distritosJson from "@/json/distritos.json";
import moment from 'moment'
// import Tab from "../components/tab.vue";
// import axios from "axios";
import { mapState, mapMutations } from "vuex";
// import Filtros from "../components/filtros.vue";
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Pronostico",
  components: {},
  props: {
    iyectado: {
      type: Boolean,
      default: false
    },
    datosSecciones: [],
    resultados: {
      type: Array
    },
    selectGrafico: {
      type: String
    },
    valueSelect1: {
      type: String
    },
    valueSelect2: {
      type: String
    }
  },
  created() {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.format = 0;
    }


    if (this.resultados.length > 0) {
      this.instanciarFormato();
    }


  },
  data() {
    return {
      loadReport: false,

      modal: false,
      loading: true,
      intervalSeconds: 60,
      eliminando: false,
      // resultados: [],

      distritos: distritosJson,



      // selectGrafico: '99',
      // valueSelect1: '01',
      // valueSelect2: '02',

      previousItem: null,

      series: [

      ],

      //max % en el eje y de chartOptions
      max: 10,

      chartOptions: {
        chart: {
          animations: {
            enabled: false,
          },
          id: 'proyeccion',
          height: 450,
          toolbar: {
            autoSelected: 'pan',
            show: true
          },
          type: 'line',
          // redrawOnParentResize: false,
          // redrawOnWindowResize: false
        },
        stroke: {
          show: true,
          // curve: 'smooth',
          lineCap: 'round',
          // dashArray: 10,
          width: [0, 4, 4, 4, 4],
          dashArray: [0, 0, 0, 8, 8]
        },
        title: {
          text: 'Porcentaje votos'
        },
        dataLabels: {
          enabled: false,
          enabledOnSeries: [0],
          formatter: function (val) {
            return val
          },
          textAnchor: 'middle',
          distributed: false,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
            colors: ['#FFF']
          },
          background: {
            enabled: false,
            foreColor: '#fff',
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: '#fff',
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: '#000',
              opacity: 0.45
            }
          },
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },

        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (y !== null) {
                if (typeof y !== "undefined") {
                  return y + "%";
                }
                return y;
              }

            }
          }
        },
        labels: [],
        xaxis: {
          tickAmount: 50,

        },
        yaxis: [
          {
            opposite: false,
            title: {
              text: 'Porcentaje de mesas escrutadas',
            },
            labels: {
              formatter: (value) => value.toFixed(0) + '%',
            },
            min: 0,
            max: 100,

          },
          {
            opposite: true,
            title: {
              text: 'Porcentaje de votos'
            },
            labels: {
              formatter: (value) => value.toFixed(0) + '%',
            },
            min: 0,
            max: 100
          }, {
            show: false,
            min: 0,
            max: 100
          },
          {
            show: false,
            min: 0,
            max: 100

          },
          {
            show: false,
            min: 0,
            max: 100

          }
        ],
      },

      chartOptionsSmall: {
        chart: {
          animations: {
            enabled: false,
          },
          id: 'proyeccion1',
          height: 350,
          toolbar: {
            autoSelected: 'pan',
            show: true
          },
          type: 'line',

          brush: {
            target: 'proyeccion',
            enabled: false
          },
          selection: {
            enabled: false,
            // xaxis: {
            //   min:20,
            //   max:20,
            //   // min: new Date('19 Jun 2017').getTime(),
            //   // max: new Date('14 Aug 2017').getTime()
            // }
          },
          // redrawOnParentResize: false,
          // redrawOnWindowResize: false
        },
        stroke: {
          show: true,
          // curve: 'smooth',
          lineCap: 'round',
          // dashArray: 10,
          width: [0, 4, 4, 4],
        },
        title: {
          text: 'Porcentaje votos'
        },
        dataLabels: {
          enabled: false,
          enabledOnSeries: [0],
          formatter: function (val) {
            return val
          },
          textAnchor: 'middle',
          distributed: false,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
            colors: ['#FFF']
          },
          background: {
            enabled: false,
            foreColor: '#fff',
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: '#fff',
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: '#000',
              opacity: 0.45
            }
          },
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y + "%";
              }
              return y;

            }
          }
        },
        labels: [],
        xaxis: {
          tickAmount: 15,

        },
        yaxis: [
          {
            opposite: false,

            title: {
              text: 'Porcentaje de mesas escrutadas',
            },
            labels: {
              formatter: (value) => value.toFixed(0) + '%',
            },
            min: 0,
            max: 100,

          },
          {
            opposite: true,
            title: {
              text: 'Porcentaje de votos'
            },
            labels: {
              formatter: (value) => value.toFixed(0) + '%',
            },
            min: 0,
            max: 100,
          }, {
            show: false,
            min: 0,
            max: 100,
          }, {
            show: false,
            min: 0,
            max: 100,
          }
        ],
      },


      chartOptionsSmall2: {
        chart: {
          animations: {
            enabled: false,
          },
          id: 'proyeccion2',
          height: 350,
          toolbar: {
            autoSelected: 'pan',
            show: true
          },
          type: 'line',

          brush: {
            target: 'proyeccion',
            enabled: false
          },
          selection: {
            enabled: false,
            // xaxis: {
            //   min:20,
            //   max:20,
            //   // min: new Date('19 Jun 2017').getTime(),
            //   // max: new Date('14 Aug 2017').getTime()
            // }
          },
          // redrawOnParentResize: false,
          // redrawOnWindowResize: false
        },
        stroke: {
          show: true,
          // curve: 'smooth',
          lineCap: 'round',
          // dashArray: 10,
          width: [0, 4, 4, 4],
        },
        title: {
          text: 'Porcentaje votos'
        },
        dataLabels: {
          enabled: false,
          enabledOnSeries: [0],
          formatter: function (val) {
            return val
          },
          textAnchor: 'middle',
          distributed: false,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
            colors: ['#FFF']
          },
          background: {
            enabled: false,
            foreColor: '#fff',
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: '#fff',
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: '#000',
              opacity: 0.45
            }
          },
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
          }
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y + "%";
              }
              return y;

            }
          }
        },
        labels: [],
        xaxis: {
          tickAmount: 15,

        },
        yaxis: [
          {
            opposite: true,

            title: {
              text: 'Porcentaje de mesas escrutadas',
            },
            labels: {
              formatter: (value) => value.toFixed(0) + '%',
            },
            min: 0,
            max: 100,

          },
          {
            opposite: false,
            title: {
              text: 'Porcentaje de votos'
            },
            labels: {
              formatter: (value) => value.toFixed(0) + '%',
            },
            min: 0,
            max: 100,
          }, {
            show: false,
            min: 0,
            max: 100,
          }, {
            show: false,
            min: 0,
            max: 100,
          }
        ],
      },



      headers: [
        {
          text: 'Hora',
          align: 'start',
          // sortable: true,
          value: 'hora',
        },
        {
          text: 'Mesas escrutadas',
          align: 'end',
          sortable: true,
          value: 'mesas',
        }, {
          text: '% Mesas',
          align: 'center',
          sortable: true,
          value: 'mesasPorcentaje',
        },
        {
          text: 'Votos UxP',
          align: 'end',
          sortable: true,
          value: 'votosuxp',
        },
        {
          text: '% votos UxP',
          align: 'center',
          sortable: true,
          value: 'porcentajeuxp',
        },
       
        {
          text: 'Votos LLA',
          align: 'end',
          sortable: true,
          value: 'votoslla',
        },
        {
          text: 'Porcentaje votos LLA',
          align: 'center',
          sortable: true,
          value: 'porcentajella',
        }
      ],



      form: [{

      }]
    };
  },
  methods: {

    done() {
      this.loadReport = false;
    },

    downloadReport() {
      this.loadReport = true;
      this.$refs.informe.generateReport()
    },

    print() {
      window.print()
    },
    ...mapMutations(["closeModalEliminar", "toggleModalFilter"]),

    getPreviousItem(resultado, item) {
      const index = resultado.filtroDetalles.findIndex(itemdetalle => moment(itemdetalle.fechaTotalizacion).format('HH:mm:ss') == item.hora);
      return (index > 0) ? resultado.filtroDetalles[index - 1] : item;
    },

    getColor(valorActual, valorAnterior) {
      if (valorActual < valorAnterior) return 'red--text'
      else if (valorActual > valorAnterior) return 'green--text'
      else return ''
    },

    itemsForm(resultado) {
      let aux = []

      // console.log(resultado);

      if (resultado.filtroDetalles.length) {
        resultado.filtroDetalles.forEach((d) => {
          aux.push({
            hora: moment(d.fechaTotalizacion).format('HH:mm:ss'),
            mesas: d.mesasTotalizadas,
            mesasPorcentaje: d.mesasTotalizadasPorcentaje + '%',
            votosuxp: d.uxpvotos,
            porcentajeuxp: d.uxpporcentajevotos + '%',
            uxpporcentajevotos: d.uxpporcentajevotos,
            votosjxc: d.jxcvotos,
            porcentajejxc: d.jxcporcentajevotos + '%',
            jxcporcentajevotos: d.jxcporcentajevotos,
            votoslla: d.llavotos,
            porcentajella: d.llaporcentajevotos + '%',
            llaporcentajevotos: d.llaporcentajevotos,

          })
        })
      }

      aux = aux.reverse();
      return aux;
    },



    updateChartOptions(id) {
      let index;

      if (id == '99') {
        index = 0
      } else {
        index = parseInt(id)
      }


      // console.log("🚀 ~ file: Pronostico.vue:851 ~ updateChartOptions ~ index:", index)


      if (this.series[index].length) {
        let max = this.maxValue(this.series[index][1].data, this.series[index][2].data)
        // this.chartOptions.yaxis[2].max = max
        // this.chartOptions.yaxis[3].max = max
        // this.chartOptions.yaxis[4].max = max
        // this.chartOptions.yaxis[5].max = max
        // this.chartOptions.yaxis[6].max = max
        this.chartOptions = {
          ...this.chartOptions,
          yaxis: [
            {
              opposite: false,
              title: {
                text: 'Porcentaje de mesas escrutadas',
              },
              labels: {
                formatter: (value) => (value != null) ? value.toFixed(0) + '%' : '0%',
              },
              min: 0,
              max: 100,

            },
            {
              opposite: true,
              title: {
                text: 'Porcentaje de votos'
              },
              labels: {
                formatter: (value) => (value != null) ? value.toFixed(0) + '%' : '0%',
              },
              min: 0,
              max: max
            },
            { show: false, min: 0, max: max },
            { show: false, min: 0, max: max },
            { show: false, min: 0, max: max }
          ]
        };
      }


      // setTimeout(() => {
      //   window.dispatchEvent(new Event('resize'))
      // }, 400)

    },

    updateChartOptionsDashboard() {
      if (this.series[0].length) {

        let max = this.maxValue(this.series[0][1].data, this.series[0][2].data)
        // console.log("🚀 ~ file: Pronostico.vue:788 ~ updateChartOptionsDashboard ~ max:", max)

        this.chartOptions = {
          ... this.chartOptions,
          yaxis: [
            {
              opposite: false,
              title: {
                text: 'Porcentaje de mesas escrutadas',
              },
              labels: {
                formatter: (value) => (value != null) ? value.toFixed(0) + '%' : '0%',
              },
              min: 0,
              max: 100,

            },
            {
              opposite: true,
              title: {
                text: 'Porcentaje de votos'
              },
              labels: {
                formatter: (value) => (value != null) ? value.toFixed(0) + '%' : '0%',
              },
              min: 0,
              max: max
            },
            { show: false, min: 0, max: max },
            { show: false, min: 0, max: max },
            { show: false, min: 0, max: max }
          ]
        };



        // console.log(this.chartOptions.yaxis[6]);

        // console.log(this.selecSeries(this.valueSelect1));


        let maxsmall = this.maxValue(this.mapearSerie(this.selecSeries(this.valueSelect1))[1].data, this.mapearSerie(this.selecSeries(this.valueSelect1))[2].data)

        this.chartOptionsSmall = {
          ...this.chartOptionsSmall,
          yaxis: [
            {
              opposite: false,
              title: {
                text: 'Porcentaje de mesas escrutadas',
              },
              labels: {
                formatter: (value) => value.toFixed(0) + '%',
              },
              min: 0,
              max: 100,

            },
            {
              opposite: true,
              title: {
                text: 'Porcentaje de votos'
              },
              labels: {
                formatter: (value) => value.toFixed(0) + '%',
              },
              min: 0,
              max: maxsmall
            },
            { show: false, min: 0, max: maxsmall },
            { show: false, min: 0, max: maxsmall },
          ]
        };


        let maxsmall2 = this.maxValue(this.mapearSerie(this.selecSeries(this.valueSelect2))[1].data, this.mapearSerie(this.selecSeries(this.valueSelect2))[2].data)

        this.chartOptionsSmall2 = {
          ...this.chartOptionsSmall2,
          yaxis: [
            {
              opposite: false,
              title: {
                text: 'Porcentaje de mesas escrutadas',
              },
              labels: {
                formatter: (value) => value.toFixed(0) + '%',
              },
              min: 0,
              max: 100,

            },
            {
              opposite: true,
              title: {
                text: 'Porcentaje de votos'
              },
              labels: {
                formatter: (value) => value.toFixed(0) + '%',
              },
              min: 0,
              max: maxsmall2
            },
            { show: false, min: 0, max: maxsmall2 },
            { show: false, min: 0, max: maxsmall2 },
          ]
        };


      }

      // this.loading = false

      // setTimeout(() => {
      //   window.dispatchEvent(new Event('resize'))
      // }, 400)
    },

    selecSeries(select) {

      // console.log(select);

      // console.log( this.resultados);
      let aux = this.resultados.find((r) => {
        return r.distritoId == select
      })

      return aux


    },


    nombreDistrito(distritoId) {

      if (distritoId == '99') {
        return "Argentina"
      }

      if (distritoId == '0') {
        return "Argentina"
      }

      if (distritoId == null) {
        distritoId = '00'
      }

      let dis = this.distritos.find((d) => d.CodDistrito == distritoId)


      if (distritoId == '01') {
        return 'CABA'
      }

      if (dis) {
        return dis.Distrito
      } else {
        return ''
      }

    },


    mapearSerie(resultado) {


      // console.log(resultado);
      let labels = []

      let auxdata = [
        {
          name: 'Mesas',
          type: 'column',
          data: [],
          votos: [],
          color: '#BDBDBD'
        },
        {
          name: 'Union por la patria',
          type: 'line',
          data: [],
          votos: [],
          color: '#009cde'
        },
        // {
        //   name: 'Juntos por el cambio',
        //   type: 'line',
        //   data: [],
        //   votos: [],
        //   color: '#FEDD00'
        // },
        {
          name: 'La libertad avanza',
          type: 'line',
          data: [],
          votos: [],
          color: '#753BBD'

        }

      ]

      resultado?.filtroDetalles.forEach((d) => {

        auxdata[0].data.push(d.mesasTotalizadasPorcentaje)
        auxdata[0].votos.push(d.mesasTotalizadas)

        // auxdata[1].data.push(d.jxcporcentajevotos)
        // auxdata[1].votos.push(d.jxcvotos)

        auxdata[2].data.push(d.llaporcentajevotos)
        auxdata[2].votos.push(d.llavotos)

        auxdata[1].data.push(d.uxpporcentajevotos)
        auxdata[1].votos.push(d.uxpvotos)

        //  labels.push (4)
        //  labels.push ()
      })
      // let fechaUltima = {}

      if (resultado?.filtroDetalles.length > 0) {
        resultado.filtroDetalles.forEach((d) => {
          // console.log(moment(d.fechaTotalizacion).format('hh:mm'));

          // fechaUltima = d.fechaTotalizacion
          labels.push(moment(d.fechaTotalizacion).format('HH:mm'))
        })

      }


      // //le agrego 5 labels de tiempo cada 5 min a partir del ultimo para las proyecciones
      // for (let index = 1; index <= 5; index++) {
      //   labels.push(moment(fechaUltima).add((index * 5), 'minutes').format('HH:mm'))
      // }



      this.chartOptionsSmall.labels = labels
      this.chartOptionsSmall2.labels = labels


      return auxdata

    },


    maxValue(agrupacion1, agrupacion2, agrupacion3) {


      let auxmax = 0;


      if (agrupacion3) { 
        auxmax = Math.max(...agrupacion1, ...agrupacion2, ...agrupacion3)
      } else {
        auxmax = Math.max(...agrupacion1, ...agrupacion2)

      }

      auxmax += 5;




      return auxmax
    },


    mapearSeries(resultados) {

      let auxseries = []

      let labels = []
      let auxdata = []
      // console.log(resultados);


      let auxuxp = []
      // let auxjxc = []
      let auxlla = []

      if (Object.keys(resultados).length > 0) {




        resultados.forEach((r) => {

          auxdata = [
            {
              name: 'Mesas',
              type: 'column',
              data: [],
              votos: [],
              color: '#BDBDBD'
            },
            {
              name: 'Union por la patria',
              type: 'line',
              data: [],
              votos: [],
              color: '#009cde'
            },
            // {
            //   name: 'Juntos por el cambio',
            //   type: 'line',
            //   data: [],
            //   votos: [],
            //   color: '#FEDD00'
            // },
            {
              name: 'La libertad avanza',
              type: 'line',
              data: [],
              votos: [],
              color: '#753BBD'

            },
         
            {
              name: 'Union por la patria Proyeccion',
              type: 'line',
              data: [],
              votos: [],
              color: '#009cde'
            },
            // {
            //   name: 'Juntos por el cambio Proyeccion',
            //   type: 'line',
            //   data: [],
            //   votos: [],
            //   color: '#FEDD00'
            // },
            {
              name: 'La libertad avanza Proyeccion',
              type: 'line',
              data: [],
              votos: [],
              color: '#753BBD'

            },
          ]

          r.filtroDetalles.forEach((d) => {




            auxdata[0].data.push(d.mesasTotalizadasPorcentaje)
            auxdata[0].votos.push(d.mesasTotalizadas)

            // auxdata[1].data.push(d.jxcporcentajevotos)
            // auxdata[1].votos.push(d.jxcvotos)

            auxdata[2].data.push(d.llaporcentajevotos)
            auxdata[2].votos.push(d.llavotos)

            auxdata[1].data.push(d.uxpporcentajevotos)
            auxdata[1].votos.push(d.uxpvotos)

            //  labels.push (4)
            //  labels.push ()

          })
          // console.log("🚀 ~ file: Pronostico.vue:408 ~ resultados.forEach ~ r:", r)


          auxuxp = [...auxdata[1].data].reverse()
          // auxjxc = [...auxdata[1].data].reverse()
          auxlla = [...auxdata[2].data].reverse()


          if (auxuxp.length > 4  && auxlla.length > 4) {


            const ecuacionUXP = this.encontrarEcuacionParabola(0, auxuxp[4], 2, auxuxp[2], 4, auxuxp[0])
            // const ecuacionJXC = this.encontrarEcuacionParabola(0, auxjxc[4], 2, auxjxc[2], 4, auxjxc[0])
            const ecuacionLLA = this.encontrarEcuacionParabola(0, auxlla[4], 2, auxlla[2], 4, auxlla[0])






            for (let index = 0; index < auxdata[1].data.length; index++) {
              auxdata[3].data.push(null)
              auxdata[4].data.push(null)
              // auxdata[6].data.push(null)

            }


            // 4  es el ultimo punto X sobre el cual calcule la funciona de la parabola, entonces sigo en el 5
            // console.log((this.calcularPendienteParabola(ecuacionUXP[0], ecuacionUXP[1], ecuacionUXP[2], 5)))

            // 4 es el ultimo punto X sobre el cual calcule la funciona de la parabola, entonces sigo en el 5
            const puntosTangenteUXP = this.calcularPendienteParabola(ecuacionUXP[0], ecuacionUXP[1], ecuacionUXP[2], 5)
            // const puntosTangenteJXC = this.calcularPendienteParabola(ecuacionJXC[0], ecuacionJXC[1], ecuacionJXC[2], 5)
            const puntosTangenteLLA = this.calcularPendienteParabola(ecuacionLLA[0], ecuacionLLA[1], ecuacionLLA[2], 5)


            puntosTangenteUXP.forEach(p => {
              auxdata[0].data.push(null)
              auxdata[1].data.push(null)
              auxdata[3].data.push(p.y.toFixed(2))
            })

            // puntosTangenteJXC.forEach(p => {
            //   auxdata[1].data.push(null)
            //   auxdata[5].data.push(p.y.toFixed(2))
            // })

            puntosTangenteLLA.forEach(p => {
              auxdata[2].data.push(null)
              auxdata[4].data.push(p.y.toFixed(2))
            })

            //generar la linea de las 5 proyecciones por agrupacion  

            // for (let x = 5; x <= 9; x++) {
            // auxdata[4].data.push((ecuacionUXP[0] * x ** 4 + ecuacionUXP[1] * x ** 3 + ecuacionUXP[2] * x ** 2 + ecuacionUXP[3] * x).toFixed(2))
            // auxdata[5].data.push((ecuacionJXC[0] * x ** 4 + ecuacionJXC[1] * x ** 3 + ecuacionJXC[2] * x ** 2 + ecuacionJXC[3] * x).toFixed(2))
            // auxdata[6].data.push((ecuacionLLA[0] * x ** 4 + ecuacionLLA[1] * x ** 3 + ecuacionLLA[2] * x ** 2 + ecuacionLLA[3] * x).toFixed(2))


            // auxdata[4].data.push((ecuacionUXP[0] * (x ** 2) + ecuacionUXP[1] * x + ecuacionUXP[2]).toFixed(2))
            // auxdata[5].data.push((ecuacionJXC[0] * (x ** 2) + ecuacionJXC[1] * x + ecuacionJXC[2]).toFixed(2))
            // auxdata[6].data.push((ecuacionLLA[0] * (x ** 2) + ecuacionLLA[1] * x + ecuacionLLA[2]).toFixed(2))


            // auxdata[4].data.push(this.calcularPendienteParabola(ecuacionUXP[0], ecuacionUXP[1], ecuacionUXP[2], 5)).toFixed(2)
            // auxdata[5].data.push(this.calcularPendienteParabola(ecuacionJXC[0], ecuacionJXC[1], ecuacionJXC[2], 5)).toFixed(2)
            // auxdata[6].data.push(this.calcularPendienteParabola(ecuacionLLA[0], ecuacionLLA[1], ecuacionLLA[2], 5)).toFixed(2)

            // }


          }


          auxseries.push(auxdata)
          // this.auxseries[index].push(auxdata)
        })


        let fechaUltima = {}


        if (resultados.length) {
          if (resultados[0].filtroDetalles.length > 0) {
            resultados[0].filtroDetalles.forEach((d) => {
              // console.log(moment(d.fechaTotalizacion).format('hh:mm'));
              fechaUltima = d.fechaTotalizacion

              labels.push(moment(d.fechaTotalizacion).format('HH:mm'))
            })

          }
        }

        if (auxuxp.length > 4  && auxlla.length > 4) {


          //le agrego 5 labels de tiempo cada 5 min a partir del ultimo para las proyecciones
          for (let index = 1; index <= 5; index++) {
            labels.push(moment(fechaUltima).add((index * 5), 'minutes').format('HH:mm'))
          }
        }

      }




      // console.log(JSON.stringify(auxseries[0]));

      this.chartOptions.labels = labels
      this.series = auxseries

    },

    mapearSeriesSinProyeccion(resultados) {

      let auxseries = []

      let labels = []
      let auxdata = []
      // console.log(resultados);


      let auxuxp = []
      // let auxjxc = []
      let auxlla = []

      if (Object.keys(resultados).length > 0) {




        resultados.forEach((r) => {

          auxdata = [
            {
              name: 'Mesas',
              type: 'column',
              data: [],
              votos: [],
              color: '#BDBDBD'
            },
            {
              name: 'Union por la patria',
              type: 'line',
              data: [],
              votos: [],
              color: '#009cde'
            },
            // {
            //   name: 'Juntos por el cambio',
            //   type: 'line',
            //   data: [],
            //   votos: [],
            //   color: '#FEDD00'
            // },
            {
              name: 'La libertad avanza',
              type: 'line',
              data: [],
              votos: [],
              color: '#753BBD'

            },
            
            {
              name: 'Union por la patria Proyeccion',
              type: 'line',
              data: [],
              votos: [],
              color: '#009cde'
            },
            // {
            //   name: 'Juntos por el cambio Proyeccion',
            //   type: 'line',
            //   data: [],
            //   votos: [],
            //   color: '#FEDD00'
            // },
            {
              name: 'La libertad avanza Proyeccion',
              type: 'line',
              data: [],
              votos: [],
              color: '#753BBD'

            },
          ]

          r.filtroDetalles.forEach((d) => {




            auxdata[0].data.push(d.mesasTotalizadasPorcentaje)
            auxdata[0].votos.push(d.mesasTotalizadas)

            // auxdata[1].data.push(d.jxcporcentajevotos)
            // auxdata[1].votos.push(d.jxcvotos)

            auxdata[2].data.push(d.llaporcentajevotos)
            auxdata[2].votos.push(d.llavotos)

            auxdata[1].data.push(d.uxpporcentajevotos)
            auxdata[1].votos.push(d.uxpvotos)

            //  labels.push (4)
            //  labels.push ()

          })
          // console.log("🚀 ~ file: Pronostico.vue:408 ~ resultados.forEach ~ r:", r)



        

          auxuxp = [...auxdata[1].data].reverse()
          // auxjxc = [...auxdata[1].data].reverse()
          auxlla = [...auxdata[2].data].reverse()


          if (auxuxp.length > 4  && auxlla.length > 4) {


            const ecuacionUXP = this.encontrarEcuacionParabola(0, auxuxp[4], 2, auxuxp[2], 4, auxuxp[0])
            // const ecuacionJXC = this.encontrarEcuacionParabola(0, auxjxc[4], 2, auxjxc[2], 4, auxjxc[0])
            const ecuacionLLA = this.encontrarEcuacionParabola(0, auxlla[4], 2, auxlla[2], 4, auxlla[0])
            // console.log(r.breadcrumb);


            // const ecuacionUXP = this.encontrarEcuacionParabola5puntos([[0, auxuxp[4]], [1, auxuxp[3]], [2, auxuxp[2]], [3, auxuxp[1]], [4, auxuxp[0]]])
            // const ecuacionJXC = this.encontrarEcuacionParabola5puntos([[0, auxjxc[4]], [1, auxjxc[3]], [2, auxjxc[2]], [3, auxjxc[1]], [4, auxjxc[0]]])
            // const ecuacionLLA = this.encontrarEcuacionParabola5puntos([[0, auxlla[4]], [1, auxlla[3]], [2, auxlla[2]], [3, auxlla[1]], [4, auxlla[0]]])



            // console.log([[0, auxuxp[4]], [1, auxuxp[3]], [2, auxuxp[2]], [3, auxuxp[1]], [4, auxuxp[0]]]);


            for (let index = 0; index < auxdata[1].data.length; index++) {
              auxdata[3].data.push(null)
              auxdata[4].data.push(null)
              // auxdata[6].data.push(null)

            }


            // 4  es el ultimo punto X sobre el cual calcule la funciona de la parabola, entonces sigo en el 5
            // console.log((this.calcularPendienteParabola(ecuacionUXP[0], ecuacionUXP[1], ecuacionUXP[2], 5)))

            // 4 es el ultimo punto X sobre el cual calcule la funciona de la parabola, entonces sigo en el 5
            const puntosTangenteUXP = this.calcularPendienteParabola(ecuacionUXP[0], ecuacionUXP[1], ecuacionUXP[2], 5)
            // const puntosTangenteJXC = this.calcularPendienteParabola(ecuacionJXC[0], ecuacionJXC[1], ecuacionJXC[2], 5)
            const puntosTangenteLLA = this.calcularPendienteParabola(ecuacionLLA[0], ecuacionLLA[1], ecuacionLLA[2], 5)


            puntosTangenteUXP.forEach(p => {
              auxdata[0].data.push(null)
              auxdata[1].data.push(null)
              auxdata[3].data.push(p.y.toFixed(2))
            })

            // puntosTangenteJXC.forEach(p => {
            //   auxdata[1].data.push(null)
            //   auxdata[5].data.push(p.y.toFixed(2))
            // })

            puntosTangenteLLA.forEach(p => {
              auxdata[2].data.push(null)
              auxdata[4].data.push(p.y.toFixed(2))
            })

            //generar la linea de las 5 proyecciones por agrupacion  

            // for (let x = 5; x <= 9; x++) {
            // auxdata[4].data.push((ecuacionUXP[0] * x ** 4 + ecuacionUXP[1] * x ** 3 + ecuacionUXP[2] * x ** 2 + ecuacionUXP[3] * x).toFixed(2))
            // auxdata[5].data.push((ecuacionJXC[0] * x ** 4 + ecuacionJXC[1] * x ** 3 + ecuacionJXC[2] * x ** 2 + ecuacionJXC[3] * x).toFixed(2))
            // auxdata[6].data.push((ecuacionLLA[0] * x ** 4 + ecuacionLLA[1] * x ** 3 + ecuacionLLA[2] * x ** 2 + ecuacionLLA[3] * x).toFixed(2))


            // auxdata[4].data.push((ecuacionUXP[0] * (x ** 2) + ecuacionUXP[1] * x + ecuacionUXP[2]).toFixed(2))
            // auxdata[5].data.push((ecuacionJXC[0] * (x ** 2) + ecuacionJXC[1] * x + ecuacionJXC[2]).toFixed(2))
            // auxdata[6].data.push((ecuacionLLA[0] * (x ** 2) + ecuacionLLA[1] * x + ecuacionLLA[2]).toFixed(2))


            // auxdata[4].data.push(this.calcularPendienteParabola(ecuacionUXP[0], ecuacionUXP[1], ecuacionUXP[2], 5)).toFixed(2)
            // auxdata[5].data.push(this.calcularPendienteParabola(ecuacionJXC[0], ecuacionJXC[1], ecuacionJXC[2], 5)).toFixed(2)
            // auxdata[6].data.push(this.calcularPendienteParabola(ecuacionLLA[0], ecuacionLLA[1], ecuacionLLA[2], 5)).toFixed(2)

            // }


          }


          auxseries.push(auxdata)
          // this.auxseries[index].push(auxdata)
        })


        let fechaUltima = {}


        if (resultados.length) {
          if (resultados[0].filtroDetalles.length > 0) {
            resultados[0].filtroDetalles.forEach((d) => {
              // console.log(moment(d.fechaTotalizacion).format('hh:mm'));
              fechaUltima = d.fechaTotalizacion

              labels.push(moment(d.fechaTotalizacion).format('HH:mm'))
            })

          }
        }

        if (auxuxp.length > 4  && auxlla.length > 4) {


          //le agrego 5 labels de tiempo cada 5 min a partir del ultimo para las proyecciones
          for (let index = 1; index <= 5; index++) {
            labels.push(moment(fechaUltima).add((index * 5), 'minutes').format('HH:mm'))
          }
        }

      }




      // console.log(JSON.stringify(auxseries[0]));

      this.chartOptions.labels = labels
      this.series = auxseries

    },


    //método de interpolación de Lagrange
    encontrarEcuacionParabola5puntos(puntos) {
      if (puntos.length !== 5) {
        throw new Error("Se requieren exactamente 5 puntos para encontrar la ecuación de la parábola.");
      }

      // Extraer las coordenadas x e y de los puntos
      const x = puntos.map(punto => punto[0]);
      const y = puntos.map(punto => punto[1]);

      // Calcular los coeficientes de la ecuación de la parábola
      const a = ((y[0] - y[1]) * (x[0] - x[2]) * (x[0] - x[3]) +
        (y[0] - y[2]) * (x[0] - x[1]) * (x[0] - x[3]) +
        (y[0] - y[3]) * (x[0] - x[1]) * (x[0] - x[2])) /
        ((x[0] - x[1]) * (x[0] - x[2]) * (x[0] - x[3]));

      const b = ((y[1] - y[0]) / (x[1] - x[0])) -
        a * (x[0] + x[1]);

      const c = y[0] - (a * x[0] * x[0]) - (b * x[0]);

      // Crear la ecuación de la parábola en forma de cadena
      console.log(`y = ${a}x^2 + ${b}x + ${c}`);
      return [a, b, c];

      // return ecuacion;
    },


    //determina los puntos A,B,C de la formula de una parabola a partir de 3 puntos
    encontrarEcuacionParabola(x1, y1, x2, y2, x3, y3) {
      // Calcular los coeficientes de la ecuación de la parábola
      const denom = (x1 - x2) * (x1 - x3) * (x2 - x3);
      const A = ((x3 * (y2 - y1) + x2 * (y1 - y3) + x1 * (y3 - y2)) / denom);
      const B = ((x3 * x3 * (y1 - y2) + x2 * x2 * (y3 - y1) + x1 * x1 * (y2 - y3)) / denom);
      const C = ((x2 * x3 * (x2 - x3) * y1 + x3 * x1 * (x3 - x1) * y2 + x1 * x2 * (x1 - x2) * y3) / denom);


      // Construir la ecuación de la parábola
      // const ecuacion = `y = ${A}x^2 + ${B}x + ${C}`;

      return [A, B, C];
    },


    // calcula y devuelve un array de puntos x e y de la recta tangente de la pendiente de la parabola A,B,C en el punto pendiente x0
    calcularPendienteParabola(a, b, c, x0) {
      // Calcula la pendiente de la tangente en el punto (x0, y0)
      const y0 = a * x0 * x0 + b * x0 + c;
      const m = 2 * a * x0 + b;

      // Calcula los puntos de la recta tangente
      const puntos = [];
      for (let x = x0; x <= x0 + 4; x += 1) {
        const y = m * (x - x0) + y0;
        puntos.push({ x, y });
      }

      return puntos;
    },





    instanciarFormato() {
      this.series = [
        {
          data: [],
        },
      ];


    },



    // getAll() {
    //   // console.log("le pega al getAll");
    //   axios
    //     .all([this.getReultados()])
    //     .then(() => {
    //       this.loading = false;
    //     })
    //     .catch((err) => {
    //       console.log(err);

    //     });
    // },




    async getReultados() {
      try {
        // let dataResultadoas = await filtrosService.getProyeccion({ limit: 400 });
        // this.resultados = dataResultadoas.data;
        this.mapearSeries(this.resultados)
        this.updateChartOptions(this.selectGrafico)
        this.mapearSerie(this.selecSeries(this.valueSelect1))
        this.mapearSerie(this.selecSeries(this.valueSelect2))
        this.updateChartOptionsDashboard()
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  },
  mounted() {
    // if (!this.iyectado) {
    //   this.getAll();
    //   this.user?.username != null && this.getAll();
    //   // setInterval(() => {
    //   //   console.log("Actualizo");
    //   // }, this.intervalSeconds * 1000);
    // } else {
    //   try {
    //     this.resultados = this.datosSecciones;
    //     this.mapearSeriesSinProyeccion(this.resultados)
    //     this.updateChartOptions(this.selectGrafico)
    //     this.mapearSerie(this.selecSeries(this.valueSelect1))
    //     this.mapearSerie(this.selecSeries(this.valueSelect2))
    //     this.updateChartOptionsDashboard()
    //     this.loading = false
    //   } catch (error) {
    //     console.log(error);
    //     throw error;
    //   }
    // }


    // console.log("🚀 ~ file: PronosticoInforme.vue:1800 ~ mounted ~ this.resultados:", this.resultados)
    // console.log("🚀 ~ file: PronosticoInforme.vue:1802 ~ mounted ~ this.selectGrafico:", this.selectGrafico)
    // console.log("🚀 ~ file: PronosticoInforme.vue:1804 ~ mounted ~ this.valueSelect1:", this.valueSelect1)
    // console.log("🚀 ~ file: PronosticoInforme.vue:1806 ~ mounted ~ this.valueSelect2:", this.valueSelect2)

    this.mapearSeriesSinProyeccion(this.resultados)
    this.updateChartOptions(this.selectGrafico)
    this.mapearSerie(this.selecSeries(this.valueSelect1))
    this.mapearSerie(this.selecSeries(this.valueSelect2))
    this.updateChartOptionsDashboard()
    this.loading = false


  },
  computed: {
    ...mapState(["modalEliminar", "filtroEliminar", "user"]),



    getResultado() {


      if (this.selectGrafico == '99') {
        return {
          distritoId: '99'
        }
      }

      return this.resultados.find((r) => r.distritoId == this.selectGrafico)

      // return this.resultados[0]

    },

    graficos() {
      let graficos = [
        {
          value: '99',
          text: "Dashboard"
        }
      ]

      this.resultados.forEach(r => {
        // console.log(r);
        if (r.distritoId) {
          graficos.push({ text: this.nombreDistrito(r.distritoId), value: r.distritoId })
        }
      })
      return graficos



    },


    distritosSelect() {

      let aux = []
      this.resultados.forEach(r => {
        // console.log(r);
        if (r.distritoId) {
          aux.push({ text: this.nombreDistrito(r.distritoId), value: r.distritoId })
        }
      })
      return aux
    },

    format: {
      get() {
        return this.$store.state.format;
      },
      set(val) {
        this.$store.commit("setFormat", val);
      },
    },
  },
};
</script>
