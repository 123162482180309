<template>
  <v-container fluid>
    <v-container v-if="loading" fluid>
      <v-row style="height: 80vh" justify="center" align="center">
        <v-progress-circular :size="50" :width="7" color="primary" indeterminate></v-progress-circular>
      </v-row>
    </v-container>
    <v-container v-else-if="bancas">
      <v-row>
        <v-col cols="10">
          <v-tabs v-model="tabID" color="blue accent-4" background-color="grey lighten-4">
            <v-tab class="white">Senadores Nacionales</v-tab>
            <v-tab class="white">Diputados Nacionales</v-tab>
            <v-tab class="white">Senadores Provinciales PBA</v-tab>
            <v-tab class="white">Diputados Provinciales PBA</v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="2">
          <v-btn class="float-right mt-2 mx-2" color="primary" elevation="0" :loading="loadReport" small
            @click="downloadReport()">
            <v-icon x-small>mdi mdi-download</v-icon>
          </v-btn>

        </v-col>
      </v-row>

      <v-tabs-items v-model="tabID" class="pb-6 grey lighten-4">
        <v-tab-item class="pt-5">
          <p class="text-center text-h5"> Senadores Nacionales</p>
          <p class="text-right text-subtitle-2 px-3 my-0"> Mesas escrutadas:
            {{ bancas.senadores_nacionales.estadoRecuento.mesasTotalizadas + ' / ' +
              bancas.senadores_nacionales.estadoRecuento.porcentajeTotalizado.toFixed(2) }}% </p>

          <camara :legisladores="bancas.senadores_nacionales" tipoCamara="senadores_nacionales" class="pb-5"></camara>


        </v-tab-item>

        <v-tab-item class="pt-5">
          <!-- <v-divider class="pb-5 "></v-divider> -->
          <p class="text-center text-h5"> Diputados Nacionales</p>
          <p class="text-right text-subtitle-2 px-3 my-0"> Mesas escrutadas:
            {{ bancas.diputados_nacionales.estadoRecuento.mesasTotalizadas + ' / ' +
              bancas.diputados_nacionales.estadoRecuento.porcentajeTotalizado.toFixed(2) }}% </p>

          <camara :legisladores="bancas.diputados_nacionales" tipoCamara="diputados_nacionales"></camara>


          <v-card class="mx-3">
            <v-row>
              <v-col cols="12">
                <v-select class="px-3" :items="bancas.diputados_nacionales.desglose_actual" item-text="Distrito"
                  item-value="IdDistrito" v-model="distritoBancas" label="Distritos" outlined></v-select>
              </v-col>
              <v-col cols="6">

                <div class="text-center text-subtitle-1"> Bancas - Unión por la patria </div>
                <tablaCandidatos :canditatos="bancasDistrito('UxP', distritoBancas, bancas.diputados_nacionales)" />

              </v-col>
              <v-col cols="6">
                <div class="text-center text-subtitle-1"> Bancas - Juntos por el cambio </div>
                <tablaCandidatos :canditatos="bancasDistrito('JxC', distritoBancas, bancas.diputados_nacionales)" />
              </v-col>
              <v-col cols="6">

                <div class="text-center text-subtitle-1"> Bancas - La libertad avanza </div>
                <tablaCandidatos :canditatos="bancasDistrito('LLA', distritoBancas, bancas.diputados_nacionales)" />
              </v-col>
            </v-row>
          </v-card>


        </v-tab-item>

        <v-tab-item class="pt-5">
          <p class="text-center text-h5"> Senadores Provinciales</p>
          <p class="text-right text-subtitle-2 px-3 my-0"> Mesas escrutadas:
            {{ bancas.senadores_prov_ba.estadoRecuento.mesasTotalizadas + ' / ' +
              bancas.senadores_prov_ba.estadoRecuento.porcentajeTotalizado.toFixed(2) }}% </p>

          <camara :legisladores="bancas.senadores_prov_ba" tipoCamara="senadores_prov_ba" class="pb-5"></camara>

        </v-tab-item>

        <v-tab-item class="pt-5">
          <!-- <v-divider class="pb-5"></v-divider> -->
          <p class="text-center text-h5"> Diputados Provinciales</p>
          <p class="text-right text-subtitle-2 px-3 my-0"> Mesas escrutadas:
            {{ bancas.diputados_prov_ba.estadoRecuento.mesasTotalizadas + ' / ' +
              bancas.diputados_prov_ba.estadoRecuento.porcentajeTotalizado.toFixed(2) }}% </p>

          <camara :legisladores="bancas.diputados_prov_ba" tipoCamara="diputados_prov_ba"></camara>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
    <informe v-if="Object.keys(bancas).length" id="informe" ref="informe" @done="done($event)">
      <camarasInforme  :bancas="bancas" :distritoBancas="distritoBancas"></camarasInforme>
    </informe>
  </v-container>
</template>

<script>
import axios from "axios";
import bancasService from "@/services/BancasService";
import { mapState } from "vuex";
import camara from "../components/camara.vue";
import tablaCandidatos from '../components/TablaCandidatos.vue'
import candidatosJson from '../json/candidatos.json'
import camarasInforme from '../components/informes/CamarasInforme.vue'
import informe from '../components/informe.vue'

export default {
  components: {
    camara, tablaCandidatos, informe,
    camarasInforme
  },
  name: "Camaras",


  data() {
    return {
      loadReport: false,
      intervalSeconds: 180,
      loading: true,
      bancas: {},
      tabID: 0,
      canditatos: candidatosJson,
      distritos: [],
      distritoBancas: '01'
    };
  },

  mounted() {
    this.getAll();
    setInterval(() => {
      this.user?.username != null && this.getAll();
    }, this.intervalSeconds * 1000);
  },
  methods: {


    print() {
      window.print()
    },

    done() {
      // console.log("temrino", event);
      this.loadReport = false;
    },

    downloadReport() {
      this.loadReport = true;
      this.$refs.informe.generateReport()
    },


    bancasDistrito(partido, distrito, camara) {
      let distritoAux = this.canditatos.find(d => d.distrito.id == Number(distrito));
      let actualDis = camara.desglose_actual.find(d => d.IdDistrito == distrito)

      let agr = {}

      let res = {
        entrantes: [],
        salientes: [],
        ganadas: 0
      }
      let p = ''

      if (distritoAux) {

        agr = distritoAux.agrupaciones.find(a => a.agrupacion.nombre == partido)
        // console.log("🚀 ~ file: Camaras.vue:125 ~ bancasDistrito ~ agr:", agr)

        if (partido == "UxP") p = 'FDT'
        else if (partido == "JxC") p = 'JXC'
        else if (partido == "LLA") p = 'LLA'
        else p = partido
        let auxFue = actualDis.Fuerzas.find((f) => f.Fuerza == p);

        res.entrantes = agr ? agr.agrupacion.candidatosEntrantes : 0
        res.salientes = agr ? agr.agrupacion.candidatosSalientes : 0
        res.ganadas = auxFue ? auxFue.Ganadas : 0

      }
      return res
    },

    getAll() {
      // console.log("le pega al getAll");
      axios
        .all([this.getBancas()])
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getBancas() {
      try {
        let dataBancas = await bancasService.all();

        this.bancas = dataBancas.data;
        // console.log("🚀 ~ file: Camaras.vue:156 ~ getBancas ~ this.bancas:", this.bancas)


        // this.instanciarFormato();
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  },
  created() { },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style></style>