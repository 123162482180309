<template>
  <v-container fluid>
    <!-- <v-row class="grey lighten-4" align="center">
      
      <v-spacer></v-spacer>
    </v-row> -->

    <v-row class="grey lighten-4 px-3" :class="{ 'pt-3': $vuetify.breakpoint.smAndDown }" align="center">
      <v-chip label class="mr-2 text-subtitle-2" color="primary">{{
        categoria
      }}</v-chip>
      <div class="d-block" v-if="$vuetify.breakpoint.smAndDown"></div>
      <!-- <span class="text-subtitle-2 mr-5">{{ categoria }}</span> -->
      <span class="grey--text">Filtro aplicado:</span>
      <v-breadcrumbs class="pl-1" divider="/" :items="breadcrumbs">
      </v-breadcrumbs>
      <v-spacer></v-spacer>

      <span class="text-subtitle-2 ml-5">
        Mesas escrutadas {{ estadoRecuento.mesasTotalizadas | formatNumbers}}  /
        {{ estadoRecuento.mesasTotalizadasPorcentaje }}%</span>
      <!-- <span class="text-subtitle-1 ml-5">
        Participación Sobre Escrutado
        {{ estadoRecuento.mesasTotalizadasPorcentaje }}%
      </span> -->
      <!-- <span class="text-caption"
        >Ultima actualización {{ fechaTotalizacion }}</span
      > -->
    </v-row>
    <v-row class="grey lighten-4">
      <v-col v-for="(valor, i) in valoresFormat" :key="i" :cols="(print) ? '12' : format ? '6' : '12'">

        <div v-if="print">
          <card-agrupacion-informe :alianza="valor" :distrito="filtro.distritoId"></card-agrupacion-informe>

        </div>
        <div v-else>
          <card-agrupacion :alianza="valor" :distrito="filtro.distritoId"></card-agrupacion>
        </div>

        <!-- <card-alianza :alianza="valor" :distrito="filtro.distritoId" :categoria="filtro.categoriaId" /> -->
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import cardAgrupacion from "./cardAgrupacion.vue";
import cardAgrupacionInforme from "../components/informes/cardAgrupacionInforme.vue";
// import cardAlianza from "./cardAlianza.vue";
import { mapMutations, mapState } from "vuex";


export default {
  components: { cardAgrupacion,cardAgrupacionInforme },
  name: "tab",
  props: {
    print: {
      type: Boolean,
      default: false
    },
    filtro: {
      type: Object,
    },
  },
  computed: {
    ...mapState(["format"]),
    categoria() {
      return this.filtro.categoriaId
        ? this.categorias.find(
          (cat) => cat.categoriaId == this.filtro.categoriaId
        ).nombre
        : "";
    },
  },

  data() {
    return {
      deltab: false,
      toggleFormat: 0,
      fechaTotalizacion: "",
      id: "",
      valoresFormat: [],
      //   breadcrumbs: [
      //     {
      //       text: "Disputados Provinciales",
      //       disabled: true,
      //       href: "breadcrumbs_dashboard",
      //     },
      //     {
      //       text: "Buenos Aires",
      //       disabled: true,
      //       href: "breadcrumbs_link_1",
      //     },
      //   ],
      breadcrumbs: [],
      valores: [],
      estadoRecuento: [],

      "categorias": [
        {
          "orden": 0,
          "categoriaId": 1,
          "nombre": "PRESIDENCIALES"
        },
        {
          "orden": 1,
          "categoriaId": 2,
          "nombre": "SENADORES NACIONALES"
        },
        {
          "orden": 2,
          "categoriaId": 3,
          "nombre": "DIPUTADOS NACIONALES"
        },
        {
          "orden": 3,
          "categoriaId": 8,
          "nombre": "PARLASUR DISTRITO NACIONAL"
        },
        {
          "orden": 4,
          "categoriaId": 4,
          "nombre": "GOBERNADOR"
        },
        {
          "orden": 5,
          "categoriaId": 5,
          "nombre": "SENADORES PROVINCIALES"
        },
        {
          "orden": 6,
          "categoriaId": 6,
          "nombre": "DIPUTADOS PROVINCIALES"
        },
        {
          "orden": 7,
          "categoriaId": 9,
          "nombre": "PARLASUR DISTRITO REGIONAL"
        },
        {
          "orden": 8,
          "categoriaId": 7,
          "nombre": "CONCEJALES"
        },
        {
          "orden": 9,
          "categoriaId": 10,
          "nombre": "INTENDENTES"
        },
        {
          "orden": 10,
          "categoriaId": 11,
          "nombre": "CONSEJO MAGISTRATURA"
        }
      ],


    };
  },

  methods: {




    ...mapMutations(["closeModalEliminar", "toggleModalFilter"]),

    instanciarFormato() {
      // console.log(this.valoresFormat);

      this.formatValores();
      this.ordenar();
      this.estadoRecuento = this.filtro.filtroDetalles[0].estadoRecuento;
      this.fechaTotalizacion = this.filtro.filtroDetalles[0].fechaTotalizacion;
      this.$store.commit("setLastUpdate", this.fechaTotalizacion);
      this.fdtTop();

      // this.valoresFormat = this.valores.splice(0, 3);
      this.valoresFormat = this.valores;
      // console.log(this.valoresFormat);
    },

    formatBreadcrumb() {
      let auxbreadcrumb;
      auxbreadcrumb = this.filtro.breadcrumb
        ? this.filtro.breadcrumb.split(",")
        : [];

      this.breadcrumbs = auxbreadcrumb.map((bc) => {
        return {
          text: bc,
          disabled: true,
          href: "#",
        };
      });
    },

    formatValores() {
      this.valores = this.filtro.filtroDetalles[0].valoresTotalizadosPositivos;
    },

    fdtTop() {
      let fdt = this.valores.find(
        (valor) => valor.nombreAgrupacion == "UNION POR LA PATRIA"
      );

      if (fdt) {
        const index = this.valores.indexOf(fdt);
        if (index > -1) {
          this.valores.splice(index, 1);
        }

        this.valores.unshift(fdt);
      }

      // console.log(index);
    },

    ordenar() {
      // console.log(this.valores);
      this.valores = this.valores.sort((a, b) => {
        return b.votos - a.votos;
      });
    },
  },

  watch: {
    filtro: function () {
      this.instanciarFormato();
    },
  },
  mounted() {
    this.formatBreadcrumb();
    // this.id = this.filtro.id;

    if (this.filtro.filtroDetalles.length > 0) {
      this.instanciarFormato();
    }
  },
};
</script>

<style></style>