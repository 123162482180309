import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

import "@/plugins/apexcharts";
import "./veeValidate";
Vue.config.productionTip = false


Vue.filter("formatPorcentaje", function (value) {
  value = parseFloat(value);
  return value.toFixed(2); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("formatNumbers", function (value) {
  return new Intl.NumberFormat('es-AR').format(value);
});

// Vue.use(Vuetify, {
//   iconfont: 'mdi' // 'md' || 'mdi' || 'fa' || 'fa4'
// })

new Vue({
  vuetify,
  router,
  store,

  render: h => h(App)
}).$mount('#app')
