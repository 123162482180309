import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';

import Tablero from '../views/Tablero.vue'
import Pronostico from '../views/Pronostico.vue'
import Graficos from '@/views/Graficos.vue'
import Avance from '@/views/Avance.vue'
import Camaras from '@/views/Camaras.vue'
/*************Login ***********************/
// import Dashboard from '@/components/dashboard/Dashboard'
import Login from '@/components/user/Login'
// import Register from '@/components/user/Register'
// import Recovery from '@/components/user/Recovery'
import Usuarios from '@/views/Usuarios.vue'
import Tendencia from '@/views/Tendencia.vue'
import Secciones from '@/views/Secciones.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/components/dashboard.vue'),
    meta: {
      requireAuth: true
    },
    children: [
      {
        path: '',
        name: 'tableros',
        component: Tablero,
      },
      {
        path: 'pronostico',
        name: 'pronostico',
        component: Pronostico,
      },
      {
        path: 'grafico',
        name: 'grafico',
        component: Graficos,
      },
      {
        path: 'avance',
        name: 'avance',
        component: Avance,
      },
      {
        path: 'camaras',
        name: 'camaras',
        component: Camaras,
      },
      {
        path: 'usuarios',
        name: 'usuarios',
        component: Usuarios,
      },
      {
        path: 'tendencia',
        name: 'tendencia',
        component: Tendencia,
      },
      {
        path: 'secciones',
        name: 'secciones',
        component: Secciones,
      },
    ]
  },
  {
    path: '/user',
    component: () => import('@/components/user/BaseUser.vue'),
    meta: {
      requireAuth: false
    },
    children: [
      {
        path: '/login',
        name: 'login',
        component: Login
      },
      // {
      //   path: '/register',
      //   name: 'register',
      //   component: Register
      // },
      // {
      //   path: '/recovery',
      //   name: 'recovery',
      //   component: Recovery
      // }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  const requireAuth = to.matched.some(record => record.meta.requireAuth)

  /****Para cerrar el sidebar si esta en dispositivo pequeño */
  // if (store.state.sidebar.float) {
  //   store.state.sidebar.active = false;
  // }

  /*************Para controlar el acceso permitido ********* */
  if (requireAuth && !store.getters.isAuthenticated) {
    // console.log(from);
   
    next('/login')
  } else if (store.getters.isAuthenticated && to.name == 'login') {
    next('/')
  } else {
   
    next()
  }
  // next();
})

export default router
