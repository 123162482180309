<template>
  <v-row justify="center">
    <v-dialog v-model="modalFilter" persistent eager max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Crear nuevo tablero</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <ValidationObserver ref="filtro">
              <v-row>
                <v-col cols="12">
                  <ValidationProvider name="Nombre" :rules="'required'" mode="eager" v-slot="{ errors }">
                    <v-text-field name="nombre" label="Nombre" outlined v-model="nombre" id="fieldName"
                      :error-messages="errors" placeholder="Ingrese un nombre para el tablero"></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-0">
                  <span class="text-h6">Filtros</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <ValidationProvider name="Distrito" :rules="'required'" mode="eager" v-slot="{ errors }">
                    <v-select :items="provincias" item-value="CodDistrito" outlined item-text="Distrito"
                      @change="limpiarSeccion()" v-model="provincia" label="Distrito" :error-messages="errors"></v-select>
                  </ValidationProvider>
                  <ValidationProvider name="Sección Provincial" :rules="provincia == '02' ||
                    provincia == '13' ||
                    provincia == '23'
                    ? ''
                    : ''
                    " mode="eager" v-slot="{ errors }">
                    <v-select v-show="provincia == '02' ||
                      provincia == '13' ||
                      provincia == '23'
                      " outlined :items="seccionesProvinciales" item-text="SeccionProvincial"
                      item-value="IDSeccionProvincial" v-model="seccionProv" label="Seccion Provincial"
                      :error-messages="errors"></v-select>
                  </ValidationProvider>
                  <ValidationProvider name="Sección" :rules="''" mode="eager" v-slot="{ errors }">
                    <v-select v-show="provincia" @change="defaultsecProv()" :items="secciones" outlined
                      item-text="Seccion" item-value="IDSeccion" v-model="seccion" :error-messages="errors"
                      label="Seccion"></v-select>
                  </ValidationProvider>
                </v-col>
                <v-col cols="6">
                  <span class="text-subtitle-2">Categorías disponibles en el ámbito seleccionado</span>
                  <ValidationProvider name="Categoria" :rules="'required'" mode="eager" v-slot="{ errors }">
                    <v-radio-group v-model="cat" @change="checkinput()" :error-messages="errors">
                      <v-radio v-for="categoria in categorias" :key="categoria.categoriaId" :label="categoria.nombre"
                        :value="categoria.categoriaId"></v-radio>
                    </v-radio-group>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </ValidationObserver>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey lighten-3" @click="limpiarTodo()" :disabled="creando">
            Limpiar Filtro
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="clean()" :disabled="creando"> Cerrar </v-btn>
          <v-btn color="blue darken-1 white--text" @click="crearFiltro()" :disabled="creando">
            <v-progress-circular v-show="creando" :size="15" :width="3" color="white" indeterminate></v-progress-circular>
            Crear
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import dataJson from "../json/databallotage.json";
import distritosJson from "../json/distritos.json";
import coloresJson from "../json/colores.json"
import seccionesProvincialesJson from "../json/seccionesProvinciales.json";
import secciones from "../json/secciones.json";
import filtrosService from "@/services/FiltrosService";

export default {
  name: "filtros",
  data() {
    return {
      catalogo: dataJson,
      colores: coloresJson,
      dSecciones: secciones,
      dSeccionesProvinciales: seccionesProvincialesJson,
      dDistritos: distritosJson,
      creando: false,
      dialog: false,
      provincia: "",
      seccionProv: "",
      seccion: "",
      cat: "",
      nombre: "",
    };
  },

  created() {
    // let aux = []
    // this.colores.forEach(c => {
    //   if (!aux.find(a => a.id_agrup == c.id_agrup && a.DISTRITO == c.DISTRITO)) {


    //     switch (c.DISTRITO) {
    //       case 'ORDEN NACIONAL':
    //         c.distritoId = '00'
    //         break;

    //       case 'CABA':
    //         c.distritoId = '01'
    //         break;

    //       case 'Catamarca':
    //         c.distritoId = '03'
    //         break;

    //       case 'Chaco':
    //         c.distritoId = '06'
    //         break;

    //       case 'Chubut':
    //         c.distritoId = '07'
    //         break;

    //       case 'Córdoba':
    //         c.distritoId = '04'
    //         break;

    //       case 'Corrientes':
    //         c.distritoId = '05'
    //         break;

    //       case 'Entre Ríos':
    //         c.distritoId = '08'
    //         break;

    //       case 'Formosa':
    //         c.distritoId = '09'
    //         break;

    //       case 'Jujuy':
    //         c.distritoId = '10'
    //         break;

    //       case 'La Pampa':
    //         c.distritoId = '11'
    //         break;

    //       case 'La Rioja':
    //         c.distritoId = '12'
    //         break;

    //       case 'Mendoza':
    //         c.distritoId = '13'
    //         break;

    //       case 'Misiones':
    //         c.distritoId = '14'
    //         break;

    //       case 'Neuquén':
    //         c.distritoId = '15'
    //         break;

    //       case 'PBA':
    //         c.distritoId = '02'
    //         break;

    //       case 'Rio Negro':
    //         c.distritoId = '16'
    //         break;

    //       case 'Salta':
    //         c.distritoId = '17'
    //         break;

    //       case 'San Juan':
    //         c.distritoId = '18'
    //         break;

    //       case 'San Luis':
    //         c.distritoId = '19'
    //         break;

    //       case 'Santa Cruz':
    //         c.distritoId = '20'
    //         break;

    //       case 'Santa Fe':
    //         c.distritoId = '21'
    //         break;

    //       case 'Santiago del Estero':
    //         c.distritoId = '22'
    //         break;

    //       case 'Tierra del Fuego AIAS':
    //         c.distritoId = '24'
    //         break;

    //       case 'Tucumán':
    //         c.distritoId = '23'
    //         break;

    //       default:
    //         break;
    //     }

    //     aux.push(c)
    //   }
    // });


    // console.log(JSON.stringify(aux));
  },
  computed: {
    ...mapState(["modalFilter"]),

    secciones() {
      let secciones;

      let ambitos = [];

      if (this.provincia) {
        //si ya esta seleccionado la categoria
        if (this.cat) {
          ambitos = this.catalogo.ambitosPorCategoria.filter(
            (ambxcat) => ambxcat.categoriaId == this.cat
          )[0].ambitos;



          let secByamb = ambitos.filter(
            (amb) =>
              amb.nivelId == 4 && amb.codigoAmbitos.distritoId == this.provincia
          );



          if (this.seccionProv) {
            let secprov = this.dSeccionesProvinciales.filter(
              (secprov) =>
                secprov.IDSeccionProvincial == this.seccionProv &&
                secprov.IDDistrito == Number(this.provincia)
            );

            let seccionesaux = secByamb.filter((amb) =>
              secprov.some(
                (seccionprov) =>
                  seccionprov.IDSeccion == amb.codigoAmbitos.seccionId
              )
            );

            secciones = seccionesaux.map((amb) => {
              return {
                IDDistrito: amb.codigoAmbitos.distritoId,
                IDSeccion: amb.codigoAmbitos.seccionId,
                Seccion: amb.nombre,
              };
            });
          } else {
            secciones = secByamb.map((amb) => {
              return {
                IDDistrito: amb.codigoAmbitos.distritoId,
                IDSeccion: amb.codigoAmbitos.seccionId,
                Seccion: amb.nombre,
              };
            });
          }
        } else {
          //sino simplemente devuelvo todos las secciones  de esa provincia

          if (this.seccionProv) {
            let secprov = this.dSeccionesProvinciales.filter(
              (secprov) =>
                secprov.IDSeccionProvincial == this.seccionProv &&
                secprov.IDDistrito == Number(this.provincia)
            );

            secciones = this.dSecciones.filter(
              (seccion) =>
                seccion.IDDistrito == this.provincia &&
                secprov.some(
                  (seccionprov) => seccionprov.IDSeccion == seccion.IDSeccion
                )
            );
          } else {
            secciones = this.dSecciones.filter(
              (seccion) => seccion.IDDistrito == this.provincia
            );
          }
        }
      }

      return secciones;
    },

    seccionesProvinciales() {
      let seccionesProvinciales;

      // let ambitos = [];

      if (this.seccion) {
        seccionesProvinciales = this.dSeccionesProvinciales.filter(
          (seccion) =>
            seccion.IDDistrito == this.provincia &&
            seccion.IDSeccion == this.seccion
        );



        //si ya esta seleccionado la categoria
        // if (this.cat) {
        // ambitos = this.dataFilter.ambitosPorCategoria.filter(
        //   (ambxcat) => ambxcat.categoriaId == this.cat
        // )[0].ambitos;
        // let secProvByamb = ambitos.filter(
        //   (amb) =>
        //     amb.nivelId == 3 &&
        //     amb.codigoAmbitos.distritoId == this.provincia &&
        //     amb.codigoAmbitos.seccionId == this.seccion
        // );
        // seccionesProvinciales = secProvByamb.map((amb) => {
        //   return {
        //     IDDistrito: amb.codigoAmbitos.distritoId,
        //     IDSeccion: amb.codigoAmbitos.seccionProvincialId,
        //     IDSeccionProvincial: amb.codigoAmbitos.seccionProvincialId,
        //     Seccion: amb.nombre,
        //   };
        // });
      } else {
        //sino simplemente devuelvo todos las secciones provinciales de esa provincia
        seccionesProvinciales = this.dSeccionesProvinciales.filter(
          (seccion) => seccion.IDDistrito == this.provincia
        );

      }

      // console.log(seccionesProvinciales);
      return seccionesProvinciales;
    },

    provincias() {
      let prov;
      let ambitos = [];

      if (this.cat) {


        if (
          this.catalogo.ambitosPorCategoria.some(
            (ambxcat) => ambxcat.categoriaId == this.cat
          )
        ) {
          ambitos = this.catalogo.ambitosPorCategoria.filter(
            (ambxcat) => ambxcat.categoriaId == this.cat
          )[0].ambitos;
          let provByamb = ambitos.filter((amb) => amb.nivelId == 2);
          prov = provByamb.map((amb) => {
            return {
              CodDistrito: amb.codigoAmbitos.distritoId,
              Distrito: amb.nombre,
            };
          });

          if (this.cat == 1) {
            prov.push({
              CodDistrito: '00',
              Distrito: "Argentina"
            })
          }

        } else {
          return [];
        }
      } else {
        prov = this.dDistritos;
      }

      prov.sort((a, b) => a.CodDistrito - b.CodDistrito)

      return prov;
    },

    categorias() {
      let categorias;
      let categoriasId = [];

      if (this.provincia && this.provincia == '00') {

        categorias = [{ "orden": 0, "categoriaId": 1, "nombre": "PRESIDENCIALES" }]

      } else {


        if (this.provincia) {
          if (this.seccion) {
            categoriasId = this.catalogo.ambitosPorCategoria.filter((ambxcat) =>
              ambxcat.ambitos.some(
                (amb) =>
                  amb.codigoAmbitos.distritoId == this.provincia &&
                  amb.nivelId == 4 &&
                  amb.codigoAmbitos.seccionId == this.seccion
              )
            );
          } else {
            categoriasId = this.catalogo.ambitosPorCategoria.filter((ambxcat) =>
              ambxcat.ambitos.some(
                (amb) =>
                  amb.nivelId == 2 &&
                  amb.codigoAmbitos.distritoId == this.provincia
              )
            );
          }

          categorias = categoriasId.map((catId) => {
            return {
              categoriaId: catId.categoriaId,
              nombre: this.catalogo.categorias.find(
                (cat) => cat.categoriaId == catId.categoriaId
              ).nombre,
            };
          });
        } else {
          categorias = this.catalogo ? this.catalogo.categorias : [];
        }
      }


      categorias.sort((a, b) => a.categoriaId - b.categoriaId)


      return categorias;
    },
  },

  methods: {
    async validate() {
      let result = await this.$refs.filtro.validate();
      return result;
    },
    limpiarSeccion() {
      this.seccion = "";
      this.seccionProv = "";
    },

    limpiarTodo() {
      this.seccion = "";
      this.seccionProv = "";
      this.provincia = "";
      this.cat = "";
    },

    checkinput() {
      // console.log(this.seccion);
      if (this.seccion) {
        let aux = this.Seccion;
        this.seccion = aux;
      }
    },

    defaultsecProv() {
      if (
        ((this.seccionProv == "") & (this.seccionesProvinciales.length == 1) &&
          this.provincia == "02") ||
        this.provincia == "13" ||
        this.provincia == "23"
      ) {
        this.seccionProv = this.seccionesProvinciales[0].IDSeccionProvincial;
        // this.seccionProv = this.dSeccionesProvinciales.find(
        //   (seccion) =>
        //     seccion.IDDistrito == this.provincia &&
        //     seccion.IDSeccion == this.seccion
        // );
      }
    },

    async crearFiltro() {
      let valid = await this.validate();

      if (valid) {
        this.creando = true;

        try {
          let res = await filtrosService.add({
            nombre: this.nombre,
            breadcrumb:
              this.provincias.find((p) => p.CodDistrito == this.provincia)
                .Distrito +
              "," +
              (this.seccionProv
                ? this.seccionesProvinciales.find(
                  (s) => s.IDSeccionProvincial == this.seccionProv
                ).SeccionProvincial + ","
                : "") +
              (this.seccion
                ? this.secciones.find((s) => s.IDSeccion == this.seccion)
                  .Seccion
                : ""),
            categoriaId: this.cat,
            ...(this.provincia != '00' && { distritoId: this.provincia }),
            seccionProvincialId: this.seccionProv,
            seccionId: this.seccion,
          });

          if (res) {
            this.creando = false;

            this.clean();
            this.$root.$refs.home.getAll();
          }

          // console.log(this.filtros);
        } catch (error) {
          this.creando = false;

          console.log(error);
          throw error;
        }
      }

      return true;
    },

    clean() {
      this.$refs.filtro.reset();
      this.nombre = "";
      this.provincia = "";
      this.seccionProv = "";
      this.seccion = "";
      this.cat = "";

      this.toggleModalFilter();
    },

    ...mapMutations(["toggleModalFilter"]),
  },

  updated() { },
};
</script>

<style></style>