<template>
  <v-row>
    <v-col cols="12">
      <div class="white mx-3">
        <v-card-text>

          <v-row class="text-center">
            <v-col cols="12" lg="5" class="px-0 mx-0">
              <p class="text-subtitle-2 font-weight-black">
                {{ this.camara }} - Composición actual
              </p>

              <v-row class="ma-0 pa-0">
                <v-col v-if="$vuetify.breakpoint.smAndUp"></v-col>
                <v-col cols="2" class="ma-0 pa-0 text-right">
                  <v-icon color="#03a9f4">mdi-circle</v-icon>
                </v-col>
                <v-col cols="8" sm="4" class="ma-0 pa-0 text-no-wrap text-left">
                  Unión por la patria
                </v-col>
                <v-col cols="2" class="ma-0 pa-0 text-left font-weight-bold black--text">
                  {{ this.legisladores.previo.fdt.bancas }}
                </v-col>
                <v-col v-if="$vuetify.breakpoint.smAndUp"></v-col>
              </v-row>

              <v-row class="ma-0 pa-0">
                <v-col v-if="$vuetify.breakpoint.smAndUp"></v-col>
                <v-col cols="2" class="ma-0 pa-0 text-right">
                  <v-icon color="#ffea00">mdi-circle</v-icon>
                </v-col>
                <v-col cols="8" sm="4" class="ma-0 pa-0 text-no-wrap text-left">
                  Juntos por el cambio
                </v-col>
                <v-col cols="2" class="ma-0 pa-0 text-left font-weight-bold black--text">
                  {{ this.legisladores.previo.jxc.bancas }}
                </v-col>
                <v-col v-if="$vuetify.breakpoint.smAndUp"></v-col>
              </v-row>

              <v-row class="ma-0 pa-0">
                <v-col v-if="$vuetify.breakpoint.smAndUp"></v-col>
                <v-col cols="2" class="ma-0 pa-0 text-right">
                  <v-icon color="#753bbd">mdi-circle</v-icon>
                </v-col>
                <v-col cols="8" sm="4" class="ma-0 pa-0 text-no-wrap text-left">
                  La libertad avanza
                </v-col>
                <v-col cols="2" class="ma-0 pa-0 text-left font-weight-bold black--text">
                  {{ this.legisladores.previo.lla.bancas }}
                </v-col>
                <v-col v-if="$vuetify.breakpoint.smAndUp"></v-col>
              </v-row>
            </v-col>

            <v-col cols="12" lg="2" class="px-0 mx-0">
              <p class="text-subtitle-2 font-weight-black">Vence mandato</p>
              <v-row class="ma-0 pa-0">
                <v-col v-if="$vuetify.breakpoint.smAndUp && $vuetify.breakpoint.mdAndDown"></v-col>
                <v-col cols="2" class="ma-0 pa-0" :class="{
                  'text-left': $vuetify.breakpoint.lgAndUp,
                  'text-right': $vuetify.breakpoint.mdAndDown,
                }">
                  <v-icon color="#03a9f4">mdi-circle</v-icon>
                </v-col>
                <v-col cols="8" sm="4" lg="8" class="ma-0 pa-0 text-no-wrap text-left">
                  Unión por la patria
                </v-col>
                <v-col cols="2" class="ma-0 pa-0 font-weight-bold error--text" :class="{
                  'text-right': $vuetify.breakpoint.lgAndUp,
                  'text-left': $vuetify.breakpoint.mdAndDown,
                }">
                  -{{ this.legisladores.previo.fdt.renueva }}
                </v-col>
                <v-col v-if="$vuetify.breakpoint.smAndUp && $vuetify.breakpoint.mdAndDown"></v-col>
              </v-row>
              <v-row class="ma-0 pa-0">
                <v-col v-if="$vuetify.breakpoint.smAndUp && $vuetify.breakpoint.mdAndDown"></v-col>
                <v-col cols="2" class="ma-0 pa-0" :class="{
                  'text-left': $vuetify.breakpoint.lgAndUp,
                  'text-right': $vuetify.breakpoint.mdAndDown,
                }">
                  <v-icon color="#ffea00">mdi-circle</v-icon>
                </v-col>
                <v-col cols="8" sm="4" lg="8" class="ma-0 pa-0 text-no-wrap text-left">
                  Juntos por el cambio
                </v-col>
                <v-col cols="2" class="ma-0 pa-0 font-weight-bold error--text" :class="{
                  'text-right': $vuetify.breakpoint.lgAndUp,
                  'text-left': $vuetify.breakpoint.mdAndDown,
                }">
                  -{{ this.legisladores.previo.jxc.renueva }}
                </v-col>
                <v-col v-if="$vuetify.breakpoint.smAndUp && $vuetify.breakpoint.mdAndDown"></v-col>
              </v-row>
              <v-row class="ma-0 pa-0">
                <v-col v-if="$vuetify.breakpoint.smAndUp && $vuetify.breakpoint.mdAndDown"></v-col>
                <v-col cols="2" class="ma-0 pa-0" :class="{
                  'text-left': $vuetify.breakpoint.lgAndUp,
                  'text-right': $vuetify.breakpoint.mdAndDown,
                }">
                  <v-icon color="#753bbd">mdi-circle</v-icon>
                </v-col>
                <v-col cols="8" sm="4" lg="8" class="ma-0 pa-0 text-no-wrap text-left">
                  La libertad avanza
                </v-col>
                <v-col cols="2" class="ma-0 pa-0 font-weight-bold error--text" :class="{
                  'text-right': $vuetify.breakpoint.lgAndUp,
                  'text-left': $vuetify.breakpoint.mdAndDown,
                }">
                  -{{ this.legisladores.previo.lla.renueva }}
                </v-col>
                <v-col v-if="$vuetify.breakpoint.smAndUp && $vuetify.breakpoint.mdAndDown"></v-col>
              </v-row>
            </v-col>

            <v-col cols="12" lg="5" class="px-0 mx-0">
              <p class="text-subtitle-2 font-weight-black">
                {{ this.camara }} - Composición proyectada
              </p>
              <v-row class="ma-0 pa-0">
                <v-col cols="2" class="ma-0 pa-0 text-right">
                  <v-icon color="#03a9f4">mdi-circle</v-icon>
                </v-col>
                <v-col cols="6" sm="4" class="ma-0 pa-0 text-no-wrap text-left">
                  Unión por la patria
                </v-col>
                <v-col cols="1" sm="2" class="ma-0 pa-0 text-left font-weight-bold black--text">
                  {{
                    this.legisladores.previo.fdt.bancas -
                    this.legisladores.previo.fdt.renueva
                  }}
                </v-col>
                <v-col cols="1" sm="2" class="ma-0 pa-0 text-left font-weight-bold success--text">
                  +{{ this.legisladores.actual.fdt.ganadas }}
                </v-col>

                <v-col cols="1" sm="2" class="ma-0 pa-0 text-left font-weight-bold black--text">
                  {{ this.legisladores.actual.fdt.totalDespuesDeEleccion }}

                  (
                  <span v-if="legisladores.actual.fdt.totalDespuesDeEleccion - legisladores.previo.fdt.bancas > 0"
                    class="success--text">
                    ↑{{ legisladores.actual.fdt.totalDespuesDeEleccion - legisladores.previo.fdt.bancas }}
                  </span>
                  <span v-else-if="legisladores.actual.fdt.totalDespuesDeEleccion - legisladores.previo.fdt.bancas < 0"
                    class="error--text">
                    ↓{{ Math.abs(legisladores.actual.fdt.totalDespuesDeEleccion - legisladores.previo.fdt.bancas) }}
                  </span>
                  <span v-else class="grey-text">
                    ={{ legisladores.actual.fdt.totalDespuesDeEleccion - legisladores.previo.fdt.bancas }}
                  </span>
                  )
                </v-col>
              </v-row>

              <v-row class="ma-0 pa-0">
                <v-col cols="2" class="ma-0 pa-0 text-right">
                  <v-icon color="#ffea00">mdi-circle</v-icon>
                </v-col>
                <v-col cols="6" sm="4" class="ma-0 pa-0 text-no-wrap text-left">
                  Juntos por el cambio
                </v-col>
                <v-col cols="1" sm="2" class="ma-0 pa-0 text-left font-weight-bold black--text">
                  {{
                    this.legisladores.previo.jxc.bancas -
                    this.legisladores.previo.jxc.renueva
                  }}
                </v-col>
                <v-col cols="1" sm="2" class="ma-0 pa-0 text-left font-weight-bold success--text">
                  +{{ this.legisladores.actual.jxc.ganadas }}
                </v-col>
                <v-col cols="1" sm="2" class="ma-0 pa-0 text-left font-weight-bold black--text">
                  {{ this.legisladores.actual.jxc.totalDespuesDeEleccion }}

                  (
                  <span v-if="legisladores.actual.jxc.totalDespuesDeEleccion - legisladores.previo.jxc.bancas > 0"
                    class="success--text">
                    ↑{{ legisladores.actual.jxc.totalDespuesDeEleccion - legisladores.previo.jxc.bancas }}
                  </span>
                  <span v-else-if="legisladores.actual.jxc.totalDespuesDeEleccion - legisladores.previo.jxc.bancas < 0"
                    class="error--text">
                    ↓{{ Math.abs(legisladores.actual.jxc.totalDespuesDeEleccion - legisladores.previo.jxc.bancas) }}
                  </span>
                  <span v-else class="grey-text">
                    ={{ legisladores.actual.jxc.totalDespuesDeEleccion - legisladores.previo.jxc.bancas }}
                  </span>
                  )
                </v-col>
              </v-row>

              <v-row class="ma-0 pa-0">
                <v-col cols="2" class="ma-0 pa-0 text-right">
                  <v-icon color="#753bbd">mdi-circle</v-icon>
                </v-col>
                <v-col cols="6" sm="4" class="ma-0 pa-0 text-no-wrap text-left">
                  La libertad avanza
                </v-col>
                <v-col cols="1" sm="2" class="ma-0 pa-0 text-left font-weight-bold black--text">
                  {{
                    this.legisladores.previo.lla.bancas -
                    this.legisladores.previo.lla.renueva
                  }}
                </v-col>
                <v-col cols="1" sm="2" class="ma-0 pa-0 text-left font-weight-bold success--text">
                  +{{ this.legisladores.actual.lla.ganadas }}
                </v-col>
                <v-col cols="1" sm="2" class="ma-0 pa-0 text-left font-weight-bold black--text">
                  {{ this.legisladores.actual.lla.totalDespuesDeEleccion }}

                  (
                  <span v-if="legisladores.actual.lla.totalDespuesDeEleccion - legisladores.previo.lla.bancas > 0"
                    class="success--text">
                    ↑{{ legisladores.actual.lla.totalDespuesDeEleccion - legisladores.previo.lla.bancas }}
                  </span>
                  <span v-else-if="legisladores.actual.lla.totalDespuesDeEleccion - legisladores.previo.lla.bancas < 0"
                    class="error--text">
                    ↓{{ Math.abs(legisladores.actual.lla.totalDespuesDeEleccion - legisladores.previo.lla.bancas) }}
                  </span>
                  <span v-else class="grey--text">
                    ={{ legisladores.actual.lla.totalDespuesDeEleccion - legisladores.previo.lla.bancas }}
                  </span>
                  )
                </v-col>
              </v-row>
            </v-col>

          </v-row>
        </v-card-text>
      </div>
    </v-col>
    <v-col cols="12" md="6">
      <div class="white mx-3">
        <apexchart :options="chartOptionsPrevia" :series="seriesPrevia"></apexchart>
        <p class="text-center text-subtitle-1">
          Miembros:
          {{
            seriesPrevia.reduce((acc, current) => acc + current.data.length, 0)
          }}
        </p>
      </div>
    </v-col>
    <!-- <v-col order="3" order-lg="0" cols="12" lg="2">
      <v-row>
        <v-col cols="6" lg="12">
          <v-card :class="{ 'ml-3': $vuetify.breakpoint.mdAndDown }">
            <v-card-text class="text-center">
              <span class="blue--text font-weight-bold"> Frente de todos </span>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-icon x-large v-if="this.diferenciaFDT > 0" color="green"
                >mdi-arrow-up-drop-circle</v-icon
              >
              <v-icon
                x-large
                v-else-if="this.diferenciaFDT == 0"
                class="iconequal"
                color="light-blue"
                >mdi-pause-circle</v-icon
              >
              <v-icon x-large v-else-if="this.diferenciaFDT < 0" color="red"
                >mdi-arrow-down-drop-circle</v-icon
              >
              <span class="text-h5"> {{ this.diferenciaFDT }} </span>

              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="6" lg="12">
          <v-card :class="{ 'mr-3': $vuetify.breakpoint.mdAndDown }">
            <v-card-text class="text-center">
              <span class="yellow--text font-weight-bold">
                Juntos por el cambio
              </span>
            </v-card-text>
            <v-card-actions class="text-center">
              <v-spacer></v-spacer>
              <v-icon x-large v-if="this.diferenciaJPC > 0" color="green"
                >mdi-arrow-up-drop-circle</v-icon
              >
              <v-icon
                x-large
                v-else-if="this.diferenciaJPC == 0"
                class="iconequal"
                color="light-blue"
                >mdi-pause-circle</v-icon
              >
              <v-icon x-large v-else-if="this.diferenciaJPC < 0" color="red"
                >mdi-arrow-down-drop-circle</v-icon
              >
              <span class="text-h5"> {{ this.diferenciaJPC }} </span>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col> -->
    <v-col cols="12" md="6">
      <div class="white mx-3">
        <apexchart :options="chartOptions" :series="series"></apexchart>
        <p class="text-center text-subtitle-1">
          Miembros:
          {{ series.reduce((acc, current) => acc + current.data.length, 0) }}
        </p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "camara",
  props: {
    legisladores: Object,
    tipoCamara: String,
    print: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      camara: "",

      series: [
        {
          name: "Frente De Todos",
          data: [],
        },
        {
          name: "Juntos por el cambio",
          data: [],
        },
        {
          name: "La Libertad avanza",
          data: [],
        },
        {
          name: "Otros",
          data: [],
        },
      ],
      seriesPrevia: [
        {
          name: "Frente De Todos",
          data: [],
        },
        {
          name: "Juntos por el cambio",
          data: [],
        },
        {
          name: "La Libertad avanza",
          data: [],
        },
        {
          name: "Otros",
          data: [],
        },
      ],

      diferenciaFDT: 0,
      diferenciaJPC: 0,
      diferenciaLLa: 0,

      puntosSenadores: [
        [1, 2],
        [1, 3],
        [1, 4],
        [1, 5],

        [2, 1],
        [2, 2],
        [2, 3],
        [2, 4],
        [2, 5],
        [2, 6],

        [3, 1],
        [3, 2],
        [3, 3],
        [3, 4],
        [3, 5],
        [3, 6],
        [3, 7],

        [4, 1],
        [4, 2],
        [4, 3],
        [4, 4],
        [4, 5],
        [4, 6],
        [4, 7],
        [4, 8],

        [5, 3],
        [5, 4],
        [5, 5],
        [5, 6],
        [5, 7],
        [5, 8],

        [6, 4],
        [6, 5],
        [6, 6],
        [6, 7],
        [6, 8],

        [7, 4],
        [7, 5],
        [7, 6],
        [7, 7],
        [7, 8],

        [8, 3],
        [8, 4],
        [8, 5],
        [8, 6],
        [8, 7],
        [8, 8],

        [9, 1],
        [9, 2],
        [9, 3],
        [9, 4],
        [9, 5],
        [9, 6],
        [9, 7],
        [9, 8],

        [10, 1],
        [10, 2],
        [10, 3],
        [10, 4],
        [10, 5],
        [10, 6],
        [10, 7],

        [11, 1],
        [11, 2],
        [11, 3],
        [11, 4],
        [11, 5],
        [11, 6],

        [12, 2],
        [12, 3],
        [12, 4],
        [12, 5],
      ],

      puntosSenadoresPBA: [
        [1, 1],
        [1, 2],
        [1, 3],
        [1, 4],

        [2, 1],
        [2, 2],
        [2, 3],
        [2, 4],
        [2, 5],
        [2, 6],

        [3, 2],
        [3, 3],
        [3, 4],
        [3, 5],
        [3, 6],
        [3, 7],

        [4, 3],
        [4, 4],
        [4, 5],
        [4, 6],
        [4, 7],

        [5, 4],
        [5, 5],
        [5, 6],
        [5, 7],

        [6, 3],
        [6, 4],
        [6, 5],
        [6, 6],
        [6, 7],

        [7, 2],
        [7, 3],
        [7, 4],
        [7, 5],
        [7, 6],
        [7, 7],

        [8, 1],
        [8, 2],
        [8, 3],
        [8, 4],
        [8, 5],
        [8, 6],

        [9, 1],
        [9, 2],
        [9, 3],
        [9, 4],
      ],

      puntosDiputados: [
        [1, 3],
        [1, 4],
        [1, 5],
        [1, 6],
        [1, 7],

        [2, 2],
        [2, 3],
        [2, 4],
        [2, 5],
        [2, 6],
        [2, 7],
        [2, 8],
        [2, 9],

        [3, 1],
        [3, 2],
        [3, 3],
        [3, 4],
        [3, 5],
        [3, 6],
        [3, 7],
        [3, 8],
        [3, 9],
        [3, 10],
        [3, 11],

        [4, 1],
        [4, 2],
        [4, 3],
        [4, 4],
        [4, 5],
        [4, 6],
        [4, 7],
        [4, 8],
        [4, 9],
        [4, 10],
        [4, 11],
        [4, 12],

        [5, 1],
        [5, 2],
        [5, 3],
        [5, 4],
        [5, 5],
        [5, 6],
        [5, 7],
        [5, 8],
        [5, 9],
        [5, 10],
        [5, 11],
        [5, 12],
        [5, 13],

        [6, 1],
        [6, 2],
        [6, 3],
        [6, 4],
        [6, 5],
        [6, 6],
        [6, 7],
        [6, 8],
        [6, 9],
        [6, 10],
        [6, 11],
        [6, 12],
        [6, 13],
        [6, 14],

        [7, 1],
        [7, 2],
        [7, 3],
        [7, 4],
        [7, 5],
        [7, 6],
        [7, 7],
        [7, 8],
        [7, 9],
        [7, 10],
        [7, 11],
        [7, 12],
        [7, 13],
        [7, 14],

        [8, 1],
        [8, 2],
        [8, 3],
        [8, 4],
        [8, 5],
        [8, 6],
        [8, 7],
        [8, 8],
        [8, 9],
        [8, 10],
        [8, 11],
        [8, 12],
        [8, 13],
        [8, 14],
        [8, 15],

        [9, 5],
        [9, 6],
        [9, 7],
        [9, 8],
        [9, 9],
        [9, 10],
        [9, 11],
        [9, 12],
        [9, 13],
        [9, 14],
        [9, 15],

        [10, 7],
        [10, 8],
        [10, 9],
        [10, 10],
        [10, 11],
        [10, 12],
        [10, 13],
        [10, 14],
        [10, 15],

        [11, 8],
        [11, 9],
        [11, 10],
        [11, 11],
        [11, 12],
        [11, 13],
        [11, 14],
        [11, 15],

        [12, 8],
        [12, 9],
        [12, 10],
        [12, 11],
        [12, 12],
        [12, 13],
        [12, 14],
        [12, 15],

        [13, 7],
        [13, 8],
        [13, 9],
        [13, 10],
        [13, 11],
        [13, 12],
        [13, 13],
        [13, 14],
        [13, 15],

        [14, 8],
        [14, 9],
        [14, 10],
        [14, 11],
        [14, 12],
        [14, 13],
        [14, 14],
        [14, 15],

        [15, 7],
        [15, 8],
        [15, 9],
        [15, 10],
        [15, 11],
        [15, 12],
        [15, 13],
        [15, 14],
        [15, 15],

        [16, 5],
        [16, 6],
        [16, 7],
        [16, 8],
        [16, 9],
        [16, 10],
        [16, 11],
        [16, 12],
        [16, 13],
        [16, 14],
        [16, 15],

        [17, 1],
        [17, 2],
        [17, 3],
        [17, 4],
        [17, 5],
        [17, 6],
        [17, 7],
        [17, 8],
        [17, 9],
        [17, 10],
        [17, 11],
        [17, 12],
        [17, 13],
        [17, 14],
        [17, 15],

        [18, 1],
        [18, 2],
        [18, 3],
        [18, 4],
        [18, 5],
        [18, 6],
        [18, 7],
        [18, 8],
        [18, 9],
        [18, 10],
        [18, 11],
        [18, 12],
        [18, 13],
        [18, 14],

        [19, 1],
        [19, 2],
        [19, 3],
        [19, 4],
        [19, 5],
        [19, 6],
        [19, 7],
        [19, 8],
        [19, 9],
        [19, 10],
        [19, 11],
        [19, 12],
        [19, 13],
        [19, 14],

        [20, 1],
        [20, 2],
        [20, 3],
        [20, 4],
        [20, 5],
        [20, 6],
        [20, 7],
        [20, 8],
        [20, 9],
        [20, 10],
        [20, 11],
        [20, 12],
        [20, 13],

        [21, 1],
        [21, 2],
        [21, 3],
        [21, 4],
        [21, 5],
        [21, 6],
        [21, 7],
        [21, 8],
        [21, 9],
        [21, 10],
        [21, 11],
        [21, 12],

        [22, 1],
        [22, 2],
        [22, 3],
        [22, 4],
        [22, 5],
        [22, 6],
        [22, 7],
        [22, 8],
        [22, 9],
        [22, 10],
        [22, 11],

        [23, 2],
        [23, 3],
        [23, 4],
        [23, 5],
        [23, 6],
        [23, 7],
        [23, 8],
        [23, 9],

        [24, 3],
        [24, 4],
        [24, 5],
        [24, 6],
        [24, 7],
      ],

      puntosDiputadosPBA: [
        [1, 2],
        [1, 3],
        [1, 4],
        [1, 5],
        [1, 6],

        [2, 1],
        [2, 2],
        [2, 3],
        [2, 4],
        [2, 5],
        [2, 6],
        [2, 7],
        [2, 8],

        [3, 1],
        [3, 2],
        [3, 3],
        [3, 4],
        [3, 5],
        [3, 6],
        [3, 7],
        [3, 8],

        [4, 1],
        [4, 2],
        [4, 3],
        [4, 4],
        [4, 5],
        [4, 6],
        [4, 7],
        [4, 8],
        [4, 9],

        [5, 4],
        [5, 5],
        [5, 6],
        [5, 7],
        [5, 8],
        [5, 9],

        [6, 5],
        [6, 6],
        [6, 7],
        [6, 8],
        [6, 9],

        [7, 5],
        [7, 6],
        [7, 7],
        [7, 8],
        [7, 9],

        [8, 5],
        [8, 6],
        [8, 7],
        [8, 8],
        [8, 9],

        [9, 5],
        [9, 6],
        [9, 7],
        [9, 8],
        [9, 9],

        [10, 4],
        [10, 5],
        [10, 6],
        [10, 7],
        [10, 8],
        [10, 9],

        [11, 1],
        [11, 2],
        [11, 3],
        [11, 4],
        [11, 5],
        [11, 6],
        [11, 7],
        [11, 8],
        [11, 9],

        [12, 1],
        [12, 2],
        [12, 3],
        [12, 4],
        [12, 5],
        [12, 6],
        [12, 7],
        [12, 8],

        [13, 1],
        [13, 2],
        [13, 3],
        [13, 4],
        [13, 5],
        [13, 6],
        [13, 7],
        [13, 8],

        [14, 2],
        [14, 3],
        [14, 4],
        [14, 5],
        [14, 6],
      ],

      puntosCamara: [],

      chartOptions: {
        legend: {
          show: true,
        },
        markers: {
          size: 8,
        },
        chart: {
          type: "scatter",
          sparkline: {
            enabled: true,
          },
          animations: {
            enabled: !print,
          },
        },

        tooltip: {
          enabled: false,
        },
        colors: ["#03a9f4", "#ffea00", "#753bbd", "#9E9E9E"],

        // colors: ["#03a9f4", "#ffea00", "#9E9E9E", "#d8d8d8"],

        title: {
          text: this.leyenda,
          align: "center",
        },
      },

      chartOptionsPrevia: {
        legend: {
          show: true,
        },
        markers: {
          size: 8,
        },
        chart: {
          type: "scatter",
          sparkline: {
            enabled: true,
          },
          animations: {
            enabled: !print,
          },
        },

        tooltip: {
          enabled: false,
        },

        colors: ["#03a9f4", "#ffea00", "#753bbd", "#9E9E9E"],

        title: {
          text: this.leyendaPrevia,
          align: "center",
        },
      },
    };
  },

  methods: {
    generarGrafico() {
      let puntosCamaraPrevio = [...this.puntosCamara];
      let puntosCamaraActual = [...this.puntosCamara];

      // previo
      for (let index = 0; index < this.legisladores.previo.otros.bancas; index++) {
        let cordenada = puntosCamaraPrevio.pop();
        this.seriesPrevia[3].data.push(cordenada);
      }

      for (let index = 0; index < this.legisladores.previo.lla.bancas; index++) {
        let cordenada = puntosCamaraPrevio.pop();
        this.seriesPrevia[2].data.push(cordenada);
      }

      for (let index = 0; index < this.legisladores.previo.jxc.bancas; index++) {
        let cordenada = puntosCamaraPrevio.pop();
        this.seriesPrevia[1].data.push(cordenada);
      }
      for (let index = 0; index < this.legisladores.previo.fdt.bancas; index++) {
        let cordenada = puntosCamaraPrevio.pop();
        this.seriesPrevia[0].data.push(cordenada);
      }

      //actual
      for (
        let index = 0;
        index <
        this.puntosCamara.length -
        (this.legisladores.actual.jxc.totalDespuesDeEleccion +
          this.legisladores.actual.fdt.totalDespuesDeEleccion +
          this.legisladores.actual.lla.totalDespuesDeEleccion);
        index++
      ) {
        let cordenada = puntosCamaraActual.pop();
        this.series[3].data.push(cordenada);
      }
      for (
        let index = 0;
        index < this.legisladores.actual.lla.totalDespuesDeEleccion;
        index++
      ) {
        let cordenada = puntosCamaraActual.pop();
        this.series[2].data.push(cordenada);
      }

      for (
        let index = 0;
        index < this.legisladores.actual.jxc.totalDespuesDeEleccion;
        index++
      ) {
        let cordenada = puntosCamaraActual.pop();
        this.series[1].data.push(cordenada);
      }
      for (
        let index = 0;
        index < this.legisladores.actual.fdt.totalDespuesDeEleccion;
        index++
      ) {
        let cordenada = puntosCamaraActual.pop();
        this.series[0].data.push(cordenada);
      }

      this.diferenciaFDT = this.legisladores.actual.fdt.diferencia;
      this.diferenciaJPC = this.legisladores.actual.jxc.diferencia;
      this.diferenciaLLa = this.legisladores.actual.lla.diferencia;

      // if (puntosCamaraActual.length > 0) {
      //   let puntosCamaraAux = [...puntosCamaraActual];
      //   for (let index = 0; index < puntosCamaraActual.length; index++) {
      //     const cordenada = puntosCamaraAux.pop();
      //     this.series[3].data.push(cordenada);
      //   }
      // }
    },

    setSenadoresNacionales() {
      this.chartOptions.title = {
        text: "Senadores Nacionales proyección",
        align: "center",
      };

      this.camara = "Cámara de senadores";
      this.chartOptionsPrevia.title = {
        text: "Senadores Nacionales actual",
        align: "center",
      };
      this.puntosCamara = [...this.puntosSenadores];
      this.chartOptions.xaxis = { min: 0, max: 13 };
      this.chartOptionsPrevia.xaxis = { min: 0, max: 13 };
      this.chartOptions.yaxis = { min: -2, max: 9 };
      this.chartOptionsPrevia.yaxis = { min: -2, max: 9 };
      this.generarGrafico();
    },

    setDiputadosNacionales() {
      this.camara = "Cámara de diputados";

      this.chartOptions.title = {
        text: "Diputados Nacionales proyección",
        align: "center",
      };
      this.chartOptionsPrevia.title = {
        text: "Diputados Nacionales actual",
        align: "center",
      };
      this.puntosCamara = [...this.puntosDiputados];
      this.chartOptions.xaxis = { min: 0, max: 25 };
      this.chartOptionsPrevia.xaxis = { min: 0, max: 25 };
      this.chartOptions.yaxis = { min: -3, max: 17 };
      this.chartOptionsPrevia.yaxis = { min: -3, max: 17 };
      this.chartOptions.markers.size = 5;
      this.chartOptionsPrevia.markers.size = 5;
      this.generarGrafico();
    },

    setSenadoresProvBa() {
      this.camara = "Cámara de senadores";

      this.chartOptions.title = {
        text: "Senadores Provinciales PBA proyección",
        align: "center",
      };
      this.chartOptionsPrevia.title = {
        text: "Senadores Provinciales PBA actual",
        align: "center",
      };
      this.puntosCamara = [...this.puntosSenadoresPBA];
      this.chartOptions.xaxis = { min: 0, max: 10 };
      this.chartOptionsPrevia.xaxis = { min: 0, max: 10 };
      this.chartOptions.yaxis = { min: -2, max: 8 };
      this.chartOptionsPrevia.yaxis = { min: -2, max: 8 };
      this.generarGrafico();
    },

    setDiputadosProvBa() {
      this.camara = "Cámara de diputados";

      this.chartOptions.title = {
        text: "Diputados Provinciales PBA proyección",
        align: "center",
      };
      this.chartOptionsPrevia.title = {
        text: "Diputados Provinciales PBA actual",
        align: "center",
      };
      this.puntosCamara = [...this.puntosDiputadosPBA];
      this.chartOptions.xaxis = { min: 0, max: 15 };
      this.chartOptionsPrevia.xaxis = { min: 0, max: 15 };
      this.chartOptions.yaxis = { min: -2, max: 10 };
      this.chartOptionsPrevia.yaxis = { min: -2, max: 10 };
      this.chartOptions.markers.size = 6;
      this.chartOptionsPrevia.markers.size = 6;
      this.generarGrafico();
    },
  },

  created() {
    switch (this.tipoCamara) {
      case "senadores_nacionales":
        this.setSenadoresNacionales();
        break;
      case "diputados_nacionales":
        this.setDiputadosNacionales();
        break;
      case "senadores_prov_ba":
        this.setSenadoresProvBa();
        break;
      case "diputados_prov_ba":
        this.setDiputadosProvBa();
        break;

      default:
        break;
    }
  },
};
</script>

<style>
.iconequal {
  transform: rotate(90deg);
}
</style>