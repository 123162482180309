<template>
    <v-container fluid>

        <v-card-title class=" black--text text-h5 justify-center mb-3">
            Distritos computados por secciones
        </v-card-title>

        <v-container fluid>




            <v-row class="justiy-content-center">
                <v-col cols="6" class="justify-center">
                    <apexchart :type="'donut'" :options="chartOptions" :series="series"></apexchart>
                </v-col>
                <v-col cols="6">
                    <apexchart :type="'donut'" :options="chartOptionsConDatos" :series="seriesConDatos">
                    </apexchart>
                </v-col>
                <v-col cols="6" class="justify-center">

                    <apexchart :type="'donut'" :options="chartOptionsDistritosConDatos" :series="seriesDistritosConDatos">
                    </apexchart>


                </v-col>
                <v-col cols="6" class="justify-center">

                    <apexchart :type="'donut'" :options="chartOptionsSeccionesConDatos" :series="seriesSeccionesConDatos">
                    </apexchart>

                </v-col>

            </v-row>
            <PronosticoSecciones :key="2" v-if="secciones.resultadosdistritoslinea" print :iyectado="true"
                :value-select1-prop="valueSelect1" :select-grafico-prop="selectGrafico" :value-select2-prop="valueSelect2"
                :datosSecciones="secciones.resultadosdistritoslinea"></PronosticoSecciones>
            <v-row>
                <v-col>
                    <v-card-title class=" black--text text-h5 justify-center mb-3">
                        Distritos ponderados por sección
                        <v-spacer></v-spacer>
                    </v-card-title>


                    <table>

                        <thead>
                            <tr>
                                <th v-for="(header, index) in headers" :key="index" class="text-left">
                                    {{ header.text }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in tabla" :key="index">

                                <td class="text-right">{{ item.distritoId }}</td>
                                <td class="text-right">{{ item.nombre }}</td>
                                <td class="text-right">{{ item.mesasEsperadas }}</td>
                                <td class="text-right">{{ item.mesasTotalizadas }}</td>
                                <td class="text-right">{{ item.cantidadVotantes }}</td>
                                <td class="text-right">{{ item.participacionPorcentaje }}</td>
                                <td class=" text-right" style="background-color:#00a8e09e">{{ item.proyectadouxp }} </td>
                                <!-- <td class=" text-right" style="background-color:#fedc009a">{{ item.proyectadojxc }} </td> -->
                                <td class=" text-right" style="background-color:#753bbd93">{{ item.proyectadolla }} </td>
                                <!-- <td class=" text-right" style="background-color:#43488f8a">{{ item.proyectadohpnp }} </td> -->
                                <!-- <td class=" text-right" style="background-color:#f9546298">{{ item.proyectadofit }} </td> -->
                                <td class=" text-right" style="background-color:#7e7e7e61">{{ item.vvProyectado }} </td>

                            </tr>
                        </tbody>

                    </table>
                    <!-- <table :headers="headers" :items="tabla" class="elevation-1">

                        <template v-slot:item.proyectadouxp="{ item }">
                            <td class=" text-right" style="background-color:#00a8e09e">{{ item.proyectadouxp }} </td>
                        </template>
                        <template v-slot:item.proyectadojxc="{ item }">
                            <td class=" text-right" style="background-color:#fedc009a">{{ item.proyectadojxc }} </td>
                        </template>
                        <template v-slot:item.proyectadolla="{ item }">
                            <td class=" text-right" style="background-color:#753bbd93">{{ item.proyectadolla }} </td>
                        </template>
                        <template v-slot:item.proyectadohpnp="{ item }">
                            <td class=" text-right" style="background-color:#43488f8a">{{ item.proyectadohpnp }} </td>
                        </template>
                        <template v-slot:item.proyectadofit="{ item }">
                            <td class=" text-right" style="background-color:#f9546298">{{ item.proyectadofit }} </td>
                        </template>
                        <template v-slot:item.vvProyectado="{ item }">
                            <td class=" text-right" style="background-color:#7e7e7e61">{{ item.vvProyectado }} </td>
                        </template>

                        <template v-slot:no-data>
                            <v-btn color="primary" class="text-center" @click="initialize"> Recargar </v-btn>
                        </template>
                    </table> -->
                </v-col>
            </v-row>

        </v-container>



    </v-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
// import TendenciaService from "@/services/TendenciaService";
import PronosticoSecciones from "../../views/PronosticoSecciones.vue";
import distritosJson from "@/json/distritos.json";


export default {
    components: { PronosticoSecciones },
    props: {
        secciones: {
            type: Object
        },
        tabla: {
            type: Array
        },
        series: {
            type: Array
        },
        seriesConDatos: {
            type: Array
        },
        seriesDistritosConDatos: {
            type:Array
        },
        seriesSeccionesConDatos: {
            type:Array
        },
        selectGrafico: {
            type: String,

        },
        valueSelect1: {
            type: String,

        },
        valueSelect2: {
            type: String,

        },
      
    },
    data: () => ({
        loading: true,
        intervalSeconds: 60,
        dialog: false,
        search: '',
        distritos: distritosJson,

        select: { state: 'Argentina', abbr: 0 },

        headers: [
            { text: "DistritoId", value: "distritoId", sortable: true, align: 'left' },
            { text: "Provincia", value: "nombre", sortable: true, align: 'center' },
            { text: "Total Mesas", value: "mesasEsperadas", sortable: true, align: 'cneter' },
            { text: "Mesas escrutadas", value: "mesasTotalizadas", sortable: true, align: 'd-none' },
            { text: "Votos validos", value: "cantidadVotantes", sortable: true, align: 'right', class: 'text-center' },
            { text: "Participación", value: "participacionPorcentaje", sortable: true, align: 'right' },
            { text: "UxP Proyectado", value: "proyectadouxp", sortable: true, align: 'right', class: 'text-center' },
            // { text: "JxC Proyectado", value: "proyectadojxc", sortable: true, align: 'right', class: 'text-center' },
            { text: "LLA Proyectado", value: "proyectadolla", sortable: true, align: 'right', class: 'text-center' },
            // { text: "FIT Proyectado", value: "proyectadofit", sortable: true, align: 'right', class: 'text-center' },
            // { text: "HpNP Proyectado", value: "proyectadohpnp", sortable: true, align: 'right', class: 'text-center' },
            { text: "Votos validos proyectado ", value: "vvProyectado", sortable: true, align: 'right', class: 'text-center' },
        ],

        headersSecciones: [{ text: "DistritoId", value: "distritoId", sortable: true, align: 'left' },
        { text: "Sección", value: "nombre", sortable: true, align: 'center' },
        { text: "Total Mesas", value: "mesasEsperadas", sortable: true, align: 'cneter' },
        { text: "% Mesas escrutadas", value: "mesasTotalizadasPorcentaje", sortable: true, align: 'right' },
        { text: "Mesas escrutadas", value: "mesasTotalizadas", sortable: true, align: 'center' },
        { text: "UxP", value: "votosuxp", sortable: true, align: 'right' },
        { text: "UxP Porcentaje", value: "votosPorcentajeuxp", sortable: true, align: 'right', class: 'text-center' },
        // { text: "JxC", value: "votosjxc", sortable: true, align: 'right' },
        // { text: "JxC Porcentaje", value: "votosPorcentajejxc", sortable: true, align: 'right', class: 'text-center' },
        { text: "LLA", value: "votoslla", sortable: true, align: 'right' },
        { text: "LLA Porcentaje", value: "votosPorcentajella", sortable: true, align: 'right', class: 'text-center' },
        // { text: "FIT", value: "votosfit", sortable: true, align: 'right' },
        // { text: "FIT Porcentaje", value: "votosPorcentajefit", sortable: true, align: 'right', class: 'text-center' },
        // { text: "HpNP", value: "votoshpnp", sortable: true, align: 'right' },
        // { text: "HpNP Porcentaje", value: "votosPorcentajehpnp", sortable: true, align: 'right', class: 'text-center' },
        { text: "Votos validos", value: "cantidadVotantes", sortable: true, align: 'right' },
        { text: "Participación", value: "participacionPorcentaje", sortable: true, align: 'right' },
        { text: "UxP Proyectado", value: "proyectadouxp", sortable: true, align: 'right', class: 'text-center' },
        // { text: "JxC Proyectado", value: "proyectadojxc", sortable: true, align: 'right', class: 'text-center' },
        { text: "LLA Proyectado", value: "proyectadolla", sortable: true, align: 'right', class: 'text-center' },
        // { text: "FIT Proyectado", value: "proyectadofit", sortable: true, align: 'right', class: 'text-center' },
        // { text: "HpNP Proyectado", value: "proyectadohpnp", sortable: true, align: 'right', class: 'text-center' },
        { text: "Votos validos proyectado ", value: "vvProyectado", sortable: true, align: 'right', class: 'text-center' },
        { text: 'Multiplicador', value: 'multiplicador', sortable: true, align: 'right' },],
        // secciones: [],
        editedIndex: -1,
        chartOptions: {},
        // series: [],
        chartOptionsConDatos: {},
        // seriesConDatos: [],
        chartOptionsConTelegrama: {},
        seriesConTelegrama: [],


        chartOptionsDistritosConDatos:{},
        chartOptionsSeccionesConDatos: {},

        tablaSecciones: [],
        tablaSeccionesRender: [],

        seriesVotosLinea: [

        ],
        chartOptionsVotosLinea: {
            chart: {
                height: 450,
                type: 'line',
            },
            stroke: {
                show: true,
                // curve: 'smooth',
                lineCap: 'round',
                // dashArray: 10,
                width: [0, 4, 4, 4, 4],
            },
            title: {
                text: 'Porcentaje votos'
            },
            dataLabels: {
                enabled: false,
                enabledOnSeries: [0],
                formatter: function (val) {
                    return val
                },
                textAnchor: 'middle',
                distributed: false,
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 'bold',
                    colors: ['#FFF']
                },
                background: {
                    enabled: false,
                    foreColor: '#fff',
                    padding: 4,
                    borderRadius: 2,
                    borderWidth: 1,
                    borderColor: '#fff',
                    opacity: 0.9,
                    dropShadow: {
                        enabled: false,
                        top: 1,
                        left: 1,
                        blur: 1,
                        color: '#000',
                        opacity: 0.45
                    }
                },
                dropShadow: {
                    enabled: false,
                    top: 1,
                    left: 1,
                    blur: 1,
                    color: '#000',
                    opacity: 0.45
                }
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y + "%";
                        }
                        return y;

                    }
                }
            },
            labels: [],
            xaxis: {
                tickAmount: 50
            },
            yaxis: [
                {
                    title: {
                        text: 'Porcentaje de mesas escrutadas',
                    },
                    labels: {
                        formatter: (value) => value.toFixed(0) + '%',
                    },
                    min: 0,
                    max: 100,

                },
                {
                    opposite: true,
                    title: {
                        text: 'Porcentaje de votos'
                    },
                    labels: {
                        formatter: (value) => value.toFixed(0) + '%',
                    },
                    min: 0,
                    max: 100,
                }, {
                    show: false,
                    min: 0,
                    max: 100,
                }, {
                    show: false,
                    min: 0,
                    max: 100,
                }, {
                    show: false,
                    min: 0,
                    max: 100,
                }
            ],
        },
    }),

    watch: {

    },

    methods: {
        ...mapActions("general", ["showToast", "setNotification"]),
        async initialize() {

            // let resu = await TendenciaService.allSecciones();
            // this.secciones = resu.data



            let upxProyectadoTotal = 0;
            // let jxcProyectadoTotal = 0;
            let llaProyectadoTotal = 0;
            // let fitProyectadoTotal = 0;
            // let hpnpProyectadoTotal = 0;
            let mesasEscrutadas = 0;
            let mesasTotales = 0;
            this.tabla.forEach(t => {

                if (t.distritoId == '0') {
                    return
                }

                mesasTotales += Number(t.mesasEsperadas);
                mesasEscrutadas += Number(t.mesasTotalizadas);
                // console.log(t);
                upxProyectadoTotal += Number(t.proyectadouxp);
                // jxcProyectadoTotal += Number(t.proyectadojxc);
                llaProyectadoTotal += Number(t.proyectadolla);
                // fitProyectadoTotal += Number(t.proyectadofit);
                // hpnpProyectadoTotal += Number(t.proyectadohpnp);

            });


            // this.series = [Math.round(upxProyectadoTotal), Math.round(jxcProyectadoTotal), Math.round(llaProyectadoTotal), Math.round(fitProyectadoTotal), Math.round(hpnpProyectadoTotal)];
            this.chartOptions = {
                title: {
                    text: "Total de votos proyectados por agrupación",
                    align: 'center',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                        fontFamily: undefined,
                        color: '#263238'
                    },
                },

                theme: { palette: 'palette1' }, ...this.config(),
                colors: ['#009CDE', '#753BBD'],
                labels: [`Union por la Patria: ${Math.round(upxProyectadoTotal)}`, `La libertad avanza: ${Math.round(llaProyectadoTotal)}`]
            }


            // this.seriesConDatos = [mesasEscrutadas, mesasTotales - mesasEscrutadas];
            this.chartOptionsConDatos = {
                title: {
                    text: "Mesas con datos digitados y totalizados",
                    align: 'center',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                        fontFamily: undefined,
                        color: '#263238'
                    },
                },
                theme: { palette: 'palette3' }, ...this.configSinTotal(),
                labels: [`Mesas escrutadas: ${mesasEscrutadas}`, `Mesas sin escrutar: ${mesasTotales - mesasEscrutadas}`]
            }

              // Distritos con secciones con mesas totalizadas
              const distritos = [...this.secciones.resultadosdistritos.filter(distrito => distrito.codigoAmbitos.distritoId != "0")];


            this.chartOptionsDistritosConDatos = {
                title: {
                    animations: {
                        enabled: false,

                    },
                    text: "Distritos con telegramas",
                    align: 'center',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                        fontFamily: undefined,
                        color: '#263238'
                    },
                },
                theme: { palette: 'palette4' }, ...this.configSinTotal(),
                labels: [
                    `Distritos con telegramas: ${distritos.filter(distrito => distrito.estadoRecuento.mesasTotalizadas > 0).length}`,
                    `Sin telegramas: ${distritos.filter(distrito => distrito.estadoRecuento.mesasTotalizadas == 0).length}`
                ]
            }

            this.chartOptionsSeccionesConDatos = {
                title: {
                    animations: {
                        enabled: false,

                    },
                    text: "Secciones con telegramas",
                    align: 'center',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                        fontFamily: undefined,
                        color: '#263238'
                    },
                },
                theme: { palette: 'palette5' }, ...this.configSinTotal(),
                labels: [
                    `Secciones con telegramas: ${this.secciones.resultadossecciones.filter(seccion => seccion.estadoRecuento.mesasTotalizadas > 0).length}`,
                    `Sin telegramas: ${this.secciones.resultadossecciones.filter(seccion => seccion.estadoRecuento.mesasTotalizadas == 0).length}`
                ]
            }




            this.tablaSecciones = []
            this.secciones.resultadossecciones.forEach((s) => {
                this.tablaSecciones.push({

                    distritoId: s.codigoAmbitos.distritoId,
                    nombre: s.nombre,
                    mesasEsperadas: s.estadoRecuento.mesasEsperadas,
                    mesasTotalizadasPorcentaje: s.estadoRecuento.mesasTotalizadasPorcentaje?.toFixed(2),
                    mesasTotalizadas: s.estadoRecuento.mesasTotalizadas,
                    votosuxp: s.valoresTotalizadosPositivos.find((vtp) => vtp.idAgrupacion == '134')?.votos,
                    votosPorcentajeuxp: s.valoresTotalizadosPositivos.find((vtp) => vtp.idAgrupacion == '134')?.votosPorcentaje && s.valoresTotalizadosPositivos.find((vtp) => vtp.idAgrupacion == '134')?.votosPorcentaje.toFixed(2) || 0,
                    votoslla: s.valoresTotalizadosPositivos.find((vtp) => vtp.idAgrupacion == '135')?.votos,
                    votosPorcentajella: s.valoresTotalizadosPositivos.find((vtp) => vtp.idAgrupacion == '135')?.votosPorcentaje && s.valoresTotalizadosPositivos.find((vtp) => vtp.idAgrupacion == '135')?.votosPorcentaje.toFixed(2) || 0,
                    // votosjxc: s.valoresTotalizadosPositivos.find((vtp) => vtp.idAgrupacion == '132')?.votos,
                    // votosPorcentajejxc: s.valoresTotalizadosPositivos.find((vtp) => vtp.idAgrupacion == '132')?.votosPorcentaje && s.valoresTotalizadosPositivos.find((vtp) => vtp.idAgrupacion == '132')?.votosPorcentaje.toFixed(2) || 0,
                    // votosfit: s.valoresTotalizadosPositivos.find((vtp) => vtp.idAgrupacion == '136')?.votos,
                    // votosPorcentajefit: s.valoresTotalizadosPositivos.find((vtp) => vtp.idAgrupacion == '136')?.votosPorcentaje && s.valoresTotalizadosPositivos.find((vtp) => vtp.idAgrupacion == '136')?.votosPorcentaje.toFixed(2) || 0,

                    // votoshpnp: s.valoresTotalizadosPositivos.find((vtp) => vtp.idAgrupacion == '133')?.votos,
                    // votosPorcentajehpnp: s.valoresTotalizadosPositivos.find((vtp) => vtp.idAgrupacion == '133')?.votosPorcentaje && s.valoresTotalizadosPositivos.find((vtp) => vtp.idAgrupacion == '133')?.votosPorcentaje.toFixed(2) || 0,


                    cantidadVotantes: s.estadoRecuento.cantidadVotantes,
                    participacionPorcentaje: s.estadoRecuento.participacionPorcentaje && s.estadoRecuento.participacionPorcentaje.toFixed(2),
                    proyectadouxp: s.valoresTotalizadosPositivos.find((vtp) => vtp.idAgrupacion == '134')?.proyectado.toFixed(),
                    // proyectadojxc: s.valoresTotalizadosPositivos.find((vtp) => vtp.idAgrupacion == '132')?.proyectado.toFixed(),
                    proyectadolla: s.valoresTotalizadosPositivos.find((vtp) => vtp.idAgrupacion == '135')?.proyectado.toFixed(),
                    // proyectadofit: s.valoresTotalizadosPositivos.find((vtp) => vtp.idAgrupacion == '136')?.proyectado.toFixed(),
                    // proyectadohpnp: s.valoresTotalizadosPositivos.find((vtp) => vtp.idAgrupacion == '133')?.proyectado.toFixed(),
                    vvProyectado: s.vvProyectado && s.vvProyectado.toFixed(),
                    multiplicador: s.multiplicador && s.multiplicador,
                })
            })
            this.tablaSeccionesRender = this.tablaSecciones

            this.loading = false;
        },

        config() {
            return {
                plotOptions: {
                    pie: {
                        donut: {
                            size: "50%",
                            labels: {
                                show: false,
                                name: {
                                    show: true,
                                    fontSize: "5px",
                                    color: "#000",
                                },
                                value: {
                                    show: true,
                                    fontSize: "22px",
                                    fontWeight: "bold",
                                    color: "#000",
                                    offsetY: 16,
                                    formatter: (val) => {
                                        return Number(val).toLocaleString();
                                    },
                                },
                                total: {
                                    show: false,
                                    fontSize: "22px",
                                    label: "Total",
                                    fontWeight: "bold",
                                    color: "#000",
                                    // formatter: function (w) {
                                    //   return w.globals.seriesTotals.reduce(
                                    //     (a, b) => {
                                    //       return a + b;
                                    //     },
                                    //     0
                                    //   );
                                    // },
                                    formatter: () => {
                                        // console.log('char',votantes);
                                        return 0;
                                    },
                                },
                            },
                        },
                    },
                },
                chart: {
                    type: "donut",
                    width: '100%',
                    animations: {
                        enabled: false,
                        // easing: "easeinout",
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150,
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350,
                        },
                    },
                },
                legend: {
                    position: "bottom",
                    floating: false,
                    // formatter: function (seriesName, opts) {
                    //     return [seriesName, " - ", ((opts.w.globals.series[opts.seriesIndex] * 100) / opts.w.globals.series.reduce((prev, sig) => prev + sig, 0)).toFixed(1) + '%']
                    // }
                },
            }
        },
        configSinTotal() {
            return {
                plotOptions: {
                    pie: {
                        donut: {
                            size: "50%",
                        },
                    },
                },
                chart: {
                    type: "donut",
                    animations: {
                        enabled: true,
                        easing: "easeinout",
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150,
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350,
                        },
                    },
                },
                legend: {
                    position: "bottom",
                    floating: false,
                    // formatter: function (seriesName, opts) {
                    //     return [seriesName, " - ", ((opts.w.globals.series[opts.seriesIndex] * 100) / opts.w.globals.series.reduce((prev, sig) => prev + sig, 0)).toFixed(1) + '%']
                    // }
                },
            }
        },
        distritosSelect() {
            return this.distritos.map(distrito => {
                return { state: distrito.Distrito, abbr: distrito.IDDistrito }
            })
        },
        updateDashboard() {
            this.tablaSeccionesRender = Number(this.select.abbr) == 0 && this.tablaSecciones || this.tablaSecciones.filter(sec => Number(sec.distritoId) == Number(this.select.abbr))
        },
        descargarCSV(csvData, fileName, cabeza = []) {
            let cabezainst = cabeza.map(cab => cab.text)
            const csv = [cabezainst, ...csvData].map(item => {
                const values = Object.values(item);
                return values.map(value => `"${value}"`).join(",");
            }).join("\n");

            const blob = new Blob([csv], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            URL.revokeObjectURL(url);
        }
    },

    mounted() {
        // console.log("LLEgo");
        this.initialize();
        // setInterval(() => {

        //     this.user?.username != null && this.initialize();
        // }, this.intervalSeconds * 1000);


    },

    computed: {
        ...mapState(["user"]),
    }
};
</script>