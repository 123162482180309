<template>
  <v-app>
    <v-snackbar v-for="(notification, index) in notifications.filter((n) => n.showing)" :key="index" top
      v-model="notification.showing" :timeout="notification.timeout" :color="notification.color">
      <!-- :style="`bottom: ${(index * 60) + 8}px`" -->
      {{ notification.message }}

      <template v-slot:action="{ attrs }">
        <v-btn icon color="white" v-bind="attrs" @click="notification.showing = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-main class="grey lighten-4">
      <router-view></router-view>

      <v-bottom-navigation app v-if="$vuetify.breakpoint.smAndDown" class="grey darken-4">

        <v-tabs centered dark show-arrows background-color="grey darken-4">
          <v-tab x-large :to="{ name: 'tableros' }">
            <v-icon>$vuetify.icons.tabladatos</v-icon>
          </v-tab>
          <v-tab x-large :to="{ name: 'pronostico' }">
            <v-icon color="white">mdi mdi-chart-box</v-icon>
          </v-tab>
          <!-- <v-tab x-large :to="{ name: 'grafico' }">
            <v-icon>$vuetify.icons.accesoespecialistas</v-icon>
          </v-tab> -->
          <v-tab x-large :to="{ name: 'avance' }">
            <v-icon>$vuetify.icons.fdtsol</v-icon>
          </v-tab>
          <v-tab :to="{ name: 'tendencia' }">
            <v-icon color="white" x-large>mdi mdi-chart-pie</v-icon>
          </v-tab>
          <v-tab :to="{ name: 'secciones' }">
            <v-icon color="white" x-large>mdi mdi-table</v-icon>
          </v-tab>
          <!-- <v-tab x-large :to="{ name: 'camaras' }">
            <v-icon color="white">mdi-account-group</v-icon>
          </v-tab> -->
          <v-tab v-if="rol == 'ADMIN'" x-large :to="{ name: 'usuarios' }">
            <v-icon>$vuetify.icons.config</v-icon>
          </v-tab>
        </v-tabs>
      </v-bottom-navigation>
      <!-- <filtros /> -->
    </v-main>
  </v-app>
</template>

<script>
// import styles from '@/assets/app.css'
import { mapState } from "vuex";

export default {
  name: "App",

  data: () => ({
    //
  }),
  computed: {
    ...mapState("general", ["notifications"]),
    ...mapState(["rol"]),
  },
};
</script>

<style >


@import url('./assets/font/css/materialdesignicons.min.css'); 

/* Roboto regular */

@font-face {
    font-family: "Roboto";
    /* src: url("assets/fonts/Roboto/Roboto-Regular.eot"); */
    src: url("assets/fonts/Roboto/Roboto-Regular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
}

/* Roboto italic */

@font-face {
    font-family: "Roboto";
    src: url('assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

/* Roboto bold */

@font-face {
    font-family: "Roboto";
    src: url('assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}



/* Cabin regular */

@font-face {
    font-family: "Cabin";
    src: url("assets/fonts/Cabin/static/Cabin-Regular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
}

/* Cabin italic */

@font-face {
    font-family: "Cabin";
    src: url('assets/fonts/Cabin/static/Cabin-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

/* Cabin bold */

@font-face {
    font-family: "Cabin";
    src: url('assets/fonts/Cabin/static/Cabin-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}


/* @import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Encode+Sans:wght@100;400;500;700&display=swap'); */

* {
  font-family: 'Cabin';
}
</style>