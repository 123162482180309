import Vue from 'vue'

import {
    ValidationProvider,
    extend,
    ValidationObserver
} from 'vee-validate';
import {
    required,
    email,
    min,
    max,
    min_value,
    max_value,
    numeric,
    between
} from 'vee-validate/dist/rules';
import {
    localize
} from 'vee-validate';


import es from 'vee-validate/dist/locale/es.json';

localize('es', es);




// Add a rule.
extend('email', {
    ...email,
    message: es.messages['email']
});

extend('numeric', {
    ...numeric,
    message: es.messages['numeric']
});

extend('min', {
    ...min,
    message: es.messages['min']
});
extend('max', {
    ...max,
    message: es.messages['max']
});

extend('required', {
    ...required,
    message: es.messages['required']
});


extend('between', {
    ...between,
    message: es.messages['between']
});

extend('min_value', {
    ...min_value,
    message: es.messages['min_value']
});

extend('max_value', {
    ...max_value,
    message: es.messages['max_values']
});







// Register it globally

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);