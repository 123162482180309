<template>
  <v-container>
    <v-data-table :headers="headers" :items="desserts" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Usuarios</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <v-dialog v-model="dialogPassword" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="text-h5"
                  >Editar contraseña de {{ editedItem.username }}</span
                >
              </v-card-title>
              <v-card-text>
                <v-container>
                  <ValidationObserver ref="usuarioPassword">
                    <v-row>
                      <v-col cols="12">
                        <ValidationProvider
                          name="Contraseña"
                          :rules="'required'"
                          mode="eager"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            v-model="editedItem.newPassword"
                            :error-messages="errors"
                            type="password"
                            outlined
                            label="Contraseña"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </ValidationObserver>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  @click="closePassword"
                  :disabled="creando"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="primary"

                  @click="savePassword"
                  :disabled="creando"
                >
                  <v-progress-circular
                    v-show="creando"
                    :size="15"
                    :width="3"
                    color="blue"
                    indeterminate
                  ></v-progress-circular>
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Nuevo usuario
                <v-icon>mdi-account</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <ValidationObserver ref="usuario">
                    <v-row>
                      <v-col cols="12">
                        <ValidationProvider
                          name="Nombre"
                          :rules="'required'"
                          mode="eager"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            v-model="editedItem.name"
                            :error-messages="errors"
                            label="Nombre"
                            outlined
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>

                      <v-col cols="12" v-if="!formCreate">
                        <ValidationProvider
                          name="Nombre usuario"
                          :rules="'required'"
                          mode="eager"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            v-model="editedItem.username"
                            :error-messages="errors"
                            label="Nombre usuario"
                            outlined
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>

                      <v-col cols="12" sm="6" v-if="formCreate">
                        <ValidationProvider
                          name="Nombre usuario"
                          :rules="'required'"
                          mode="eager"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            v-model="editedItem.username"
                            :error-messages="errors"
                            label="Nombre usuario"
                            outlined
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>

                      <v-col cols="12" sm="6" v-if="formCreate">
                        <ValidationProvider
                          name="Contraseña"
                          :rules="'required'"
                          mode="eager"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            v-model="editedItem.password"
                            :error-messages="errors"
                            type="password"
                            label="Contraseña"
                            outlined
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>

                      <v-col cols="12">
                        <ValidationProvider
                          name="Rol"
                          :rules="'required'"
                          mode="eager"
                          v-slot="{ errors }"
                        >
                          <v-select
                            :items="itemsRoles"
                            v-model="editedItem.role"
                            :error-messages="errors"
                            item-value="role"
                            outlined
                            item-text="descripcion"
                            label="Rol"
                          >
                          </v-select>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </ValidationObserver>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="close" :disabled="creando">
                  Cancelar
                </v-btn>
                <v-btn color="primary" @click="save" :disabled="creando">
                  <v-progress-circular
                    v-show="creando"
                    :size="15"
                    :width="3"
                    color="blue"
                    indeterminate
                  ></v-progress-circular>
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-col cols="12">
                <h6 class="text-h5 text-center">
                  ¿ Desea eliminar al usuario {{ editedItem.username }}?
                </h6>
              </v-col>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  @click="closeDelete"
                  :disabled="creando"
                  >Cancelar</v-btn
                >
                <v-btn
                  color="error"
                  
                  @click="deleteItemConfirm"
                  :disabled="creando"
                >
                  <v-progress-circular
                    v-show="creando"
                    :size="15"
                    :width="3"
                    color="blue"
                    indeterminate
                  ></v-progress-circular>
                  Eliminar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small class="mr-2" @click="editItemPassword(item)">
          mdi-key
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
          v-if="user && user.id != item._id"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> Recargar </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import usuariosService from "@/services/UsuariosService";

export default {
  data: () => ({
    dialog: false,
    dialogPassword: false,
    dialogDelete: false,
    creando: false,
    headers: [
      { text: "Nombre usuario", value: "username", sortable: true },
      { text: "Nombre", value: "name", sortable: true },
      { text: "Rol", value: "role", sortable: true },
      { text: "Ultima actulizacion", value: "updatedAt", sortable: true },
      { text: "Creacion", value: "createdAt", sortable: true },
      { text: "Actions", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      _id: "",
      username: "",
      name: "",
      password: "",
      newPassword: "",
      role: "",
      filtros: [],
      updatedAt: "",
      createdAt: "",
      __v: 0,
    },
    defaultItem: {
      _id: "",
      username: "",
      name: "",
      password: "",
      newPassword: "",
      role: "",
      filtros: [],
      updatedAt: "",
      createdAt: "",
      __v: 0,
    },
    itemsRoles: [
      { descripcion: "Administrador - Administradora", role: "ADMIN" },
      { descripcion: "Auditor - Auditora", role: "AUDIT" },
    ],
  }),

  computed: {
    ...mapState(["user"]),
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo usuario" : "Editar usuario";
    },
    formCreate() {
      return this.editedIndex === -1;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogPassword(val) {
      val || this.closePassword();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions("general", ["showToast", "setNotification"]),
    async validatePassword() {
      let result = await this.$refs.usuarioPassword.validate();
      return result;
    },
    async validate() {
      let result = await this.$refs.usuario.validate();
      return result;
    },
    async initialize() {
      let resu = await usuariosService.all();
      this.desserts = resu.data;
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    editItemPassword(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogPassword = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      try {
        this.creando = true;
        let { _id } = this.editedItem;
        let resu = await usuariosService.del(_id);
        this.initialize();
        this.dialogDelete && this.closeDelete();
        this.setNotification({
          title: "Aviso",
          message: resu.data || "Usuario borrado.",
          timeout: "6000",
          color: "success",
        });
        this.creando = false;
      } catch (error) {
        this.setNotification({
          title: "Error",
          message: "Error al borrar el usuario.",
          timeout: "6000",
          color: "error",
        });
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closePassword() {
      this.dialogPassword = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (await this.validate()) {
        try {
          this.creando = true;
          let { username, name, password, role, _id } = this.editedItem;
          let resu =
            (this.formCreate &&
              (await usuariosService.add({
                username,
                name,
                password,
                role,
              }))) ||
            (await usuariosService.edit(_id, { username, name, role }));
          this.initialize();
          this.dialog && this.close();
          this.setNotification({
            title: "Aviso",
            message: resu.data || "Usuario guardado.",
            timeout: "6000",
            color: "success",
          });
          this.creando = false;
        } catch (error) {
          this.setNotification({
            title: "Error",
            message: "Error al operar sobre el usuario.",
            timeout: "6000",
            color: "error",
          });
        }
      }
    },

    async savePassword() {
      if (await this.validatePassword()) {
        try {
          this.creando = true;
          let { newPassword, _id } = this.editedItem;
          // console.log(this.editedItem);
          let resu =
            !this.formCreate &&
            (await usuariosService.editPassword({ newPassword, userId: _id }));
          // console.log(resu);

          this.initialize();
          this.dialogPassword && this.closePassword();
          this.setNotification({
            title: "Aviso",
            message: resu.data || "Cambio de contraseña exitoso.",
            timeout: "6000",
            color: "success",
          });
          this.creando = false;
        } catch (error) {
          this.setNotification({
            title: "Error",
            message: "Error al operar sobre el usuario.",
            timeout: "6000",
            color: "error",
          });
        }
      }
    },
  },
};
</script>