import Vue from 'vue'
import Vuex from 'vuex'
//import axios from 'axios';
import general from "./store/general"
Vue.use(Vuex)




export default new Vuex.Store({
  modules: {
    general
  },
  state: {

    catalogo: JSON.parse(localStorage.getItem('catalogo')) || [],
    lastUpdate: sessionStorage.getItem('lastUpdate') || '',
    token: localStorage.getItem('token') || null,
    rol: localStorage.getItem('rol') || null,
    user: {
      id: localStorage.getItem('id') || null,
      username: localStorage.getItem('username') || null
    },




    modalFilter: false,
    modalEliminar: false,
    filtroEliminar: {
      id: '',
      nombre: ''
    },

    format: localStorage.getItem('format') || false,



    "categorias": [
      {
        "orden": 0,
        "categoriaId": 1,
        "nombre": "PRESIDENCIALES"
      },
      {
        "orden": 1,
        "categoriaId": 2,
        "nombre": "SENADORES NACIONALES"
      },
      {
        "orden": 2,
        "categoriaId": 3,
        "nombre": "DIPUTADOS NACIONALES"
      },
      {
        "orden": 3,
        "categoriaId": 8,
        "nombre": "PARLASUR DISTRITO NACIONAL"
      },
      {
        "orden": 4,
        "categoriaId": 4,
        "nombre": "GOBERNADOR"
      },
      {
        "orden": 5,
        "categoriaId": 5,
        "nombre": "SENADORES PROVINCIALES"
      },
      {
        "orden": 6,
        "categoriaId": 6,
        "nombre": "DIPUTADOS PROVINCIALES"
      },
      {
        "orden": 7,
        "categoriaId": 9,
        "nombre": "PARLASUR DISTRITO REGIONAL"
      },
      {
        "orden": 8,
        "categoriaId": 7,
        "nombre": "CONCEJALES"
      },
      {
        "orden": 9,
        "categoriaId": 10,
        "nombre": "INTENDENTES"
      },
      {
        "orden": 10,
        "categoriaId": 11,
        "nombre": "CONSEJO MAGISTRATURA"
      }
    ],
  },
  mutations: {

    setId(state, payload) {
      state.user.id = payload
      localStorage.setItem("id", payload)
    },

    clearId(state) {
      state.user.id = null
      localStorage.removeItem("id")
    },

    setToken(state, payload) {
      state.token = payload
      localStorage.setItem("token", payload)
    },

    setRol(state, payload) {
      state.rol = payload
      localStorage.setItem("rol", payload)
    },

    clearToken(state) {
      state.token = null,
        state.user.username = null;
      state.user.id = null;
      state.rol = null,
        localStorage.removeItem("token")
      localStorage.removeItem("rol")
    },




    setUsername(state, payload) {
      state.user.username = payload
      localStorage.setItem("username", payload)
    },


    modalEliminarFiltro(state, payload) {
      state.modalEliminar = true;
      state.filtroEliminar = payload;
    },

    toggleModalFilter: state => {
      state.modalFilter = !state.modalFilter
    },

    setFormat: (state, payload) => {
      state.format = payload
      localStorage.setItem("format", payload)
    },

    setLastUpdate: (state, payload) => {
      state.lastUpdate = payload
      sessionStorage.setItem('lastUpdate', payload)
    },


    setCatalogo: (state, payload) => {
      state.catalogo = payload,
        localStorage.setItem("usuario", JSON.stringify(payload));
    },

    closeModalEliminar: state => {
      state.modalEliminar = false
    },
  },
  actions: {

    setId({ commit }, token) {
      commit('setId', token)
    },

    modalEliminarFiltro({ commit }, filtro) {
      commit('modalEliminarFiltro', filtro)
    },

    setToken({ commit }, token) {
      commit('setToken', token)
    },

    setRol({ commit }, rol) {
      commit('setRol', rol)
    },


    setUsername({ commit }, username) {
      commit('setUsername', username)
    },

    clearToken({ commit }) {
      commit('clearToken');
    },

    SET_CATALOGO({ commit }, catalogo) {
      commit('setCatalogo', catalogo)
    },

    LOGOUT_REQUEST: ({ state, commit }) => {
      state.username = "";
      localStorage.clear()
      commit('clearToken');
    },
    LOGOUT_AND_REDIRECT: ({ state, commit }) => {
      state.user.username = "";
      commit('clearToken');
      localStorage.clear()
      this.$router.push("/login");
    },


  },
  getters: {

    isAuthenticated(state) {
      return state.token != null
    }
  },
  computed: {

  }

})
