<template>
  <v-card elevation="2">
    <v-card-title> </v-card-title>

    <v-card-text>
      <v-container fluid>
        <v-row align="center">
          <v-col cols="3" sm="3" lg="2">
            <v-img contain eager max-width="60px" :src="getImage(alianza)"></v-img>
          </v-col>
          <v-col cols="6" sm="6" lg="6">
            <p class="text-left text-subtitle-1">
              {{ alianza.nombreAgrupacion }}
            </p>
          </v-col>

          <v-col cols="3" sm="3" lg="4">
            <p class="text-right text-subtitle-2 mb-0">
              {{ alianza.votosPorcentaje  }}%
            </p>
            <p class="text-right text-subtitle-2">
              {{ alianza.votos | formatNumbers }} Votos
              <!-- {{ totalVotos(alianza.partidos) }} Votos -->
            </p>
          </v-col>
        </v-row>
      </v-container>
      <div>
        <agrupacion-progress
          :partido="{ nombreLista: alianza.nombreAgrupacion, votosPorcentaje: alianza.votosPorcentaje }" :black="true"
          :color="determinarColor(alianza)" />
        <agrupacion-progress v-if="alianza.votosPorcentajePaso" :partido="{ nombreLista: 'PASO', votosPorcentaje: alianza.votosPorcentajePaso, votos: alianza.votosPaso }"
          :color="'grey'" />
          <agrupacion-progress v-if="alianza.votosPorcentajeGenerales" :partido="{ nombreLista: 'Generales', votosPorcentaje: alianza.votosPorcentajeGenerales, votos: alianza.votosGenerales }"
          :color="'grey'" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import dataJson from "../json/coloresFinal.json";
import dataColor from "../json/coloresAg.json";
import agrupacionProgress from "./agrupacionProgress.vue";
export default {
  components: { agrupacionProgress },
  name: "cardAgrupacion",
  data() {
    return {
      show: true,
      colores: dataJson,
      colorAlt: dataColor,

      paso: {
        nombreLista: "PASO",
        votosPorcentaje: 20.23,
        votos: 12345,
      },
    };
  },
  props: {
    alianza: {
      type: Object,
    },
    distrito: String,
  },
  methods: {

    determinarColor(alianza) {
      let color = this.colores.find((c) => c.id_agrup == alianza.idAgrupacionTelegrama)
      if (color) {
        return color.Hexadecimal
      } else {
        return "primary"
      }
    },
    getImage(alianza) {
      let img = "";
      try {
        let agrupacion = this.colores.find((c) => c.id_agrup == alianza.idAgrupacionTelegrama)


        if (agrupacion) {
          img = require("../assets/img/images/alianzafromjson/" + agrupacion.Logo + ".jpg");
        }

      } catch (error) {
        img = require("../assets/img/no_image_available.svg");
      }

      return img;
    },
    // getImage() {
    //   let img = "";

    //   try {
    //     let path =
    //       this.distrito +
    //       "" +
    //       ("0000" + this.alianza.idAgrupacionTelegrama).slice(-4);
    //     img = require("@/assets/img/images/" + path + ".png");
    //   } catch (error) {
    //     img = require("@/assets/img/no_image_available.svg");
    //   }

    //   return img;
    // },

    totalVotos(partidos) {
      return partidos.reduce((sum, value) => sum + value.votos, 0);
    },
  },
  mounted() {
    // console.log('alianza', this.alianza);
  },
};
</script>

<style></style>