<template>
    
      <v-container v-if="filtros.length" fluid>
        <v-row>
          <v-col cols="12" >

            <div v-for="filtro in filtros" :key="filtro._id">
              <div class="text-h5 text-center">
                Filtro aplicado: {{filtro.nombre}}
              </div>
            <tab :filtro="filtro" print />
          </div>

         
          </v-col>
        
        </v-row>

       
      </v-container>

      <v-container v-else fluid>
        <v-row align="center">
          <v-col cols="12">
            <p class="text-h3">No existe tablero creado.</p>
          </v-col>

         
        </v-row>
      </v-container>

    


</template>

<style >
.cls-1 {
  fill: white;
}
</style>

<script>
// import CardAlianza from "../components/cardAlianza.vue";
import tab from "../tab.vue";
import { mapState, mapMutations } from "vuex";
// import Filtros from "../../components/filtros.vue";
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "TableroInforme",
  components: { tab},
  created() {
    
    if (this.$vuetify.breakpoint.smAndDown) {
      this.format = 0;
    }

  },
  props: {
    filtros: {
      type:Array
    }
  },
  data() {
    return {
      loadReport: false,
      modal: false,
      loading: true,
      intervalSeconds: 120,
      eliminando: false,
      // filtros: [],
      filtroID:null,
      filtro: {},
    };
  },
  methods: {

    ...mapMutations(["closeModalEliminar", "toggleModalFilter"]),
   

    

    // async getFiltros() {
    //   try {
    //     let dataFiltros = await filtrosService.all();

    //     this.filtros = dataFiltros.data.filtros;
    //   } catch (error) {
    //     console.log(error);
    //     throw error;
    //   }
    // },
  },
  mounted() {
  },
  computed: {
    ...mapState(["modalEliminar", "filtroEliminar", "user"]),

    format: {
      get() {
        return this.$store.state.format;
      },
      set(val) {
        this.$store.commit("setFormat", val);
      },
    },
  },
};
</script>
