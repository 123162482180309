<template>
  <v-container fluid>
    <div class="">
      <v-container v-if="loading" fluid>
        <v-row style="height: 80vh" justify="center" align="center">
          <v-progress-circular
            :size="50"
            :width="7"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-row>
      </v-container>

      <v-container v-else-if="graficos" fluid>
        <v-row>
          <v-col cols="12" >
            <v-tabs
              v-model="graficoID"
              color="blue accent-4"
              background-color="grey lighten-4"
            >
              <!-- <v-btn v-for="(filtro, i) in filtros" :key="i">
            {{filtro.nombre}}
          </v-btn> -->
              <v-tab
                class="white"
                v-for="grafico in graficos"
                :key="grafico._id"
                >{{ grafico.nombre || "nombre" }}</v-tab
              >
            </v-tabs>
          </v-col>
        </v-row>

        <!-- <v-tab-item v-for="filtro in filtros" :key="filtro._id">
            <tab :filtro="filtro" />
          </v-tab-item> -->

        <v-tabs-items v-model="graficoID">
          <v-tab-item v-for="grafico in graficos" :key="grafico._id">
            <grafico :grafico="grafico" :series="series(grafico)"></grafico>
          </v-tab-item>
        </v-tabs-items>
      </v-container>

      <v-container v-else fluid>
        <v-row align="center">
          <v-col cols="12">
            <p class="text-h3">No existe tablero creado.</p>
          </v-col>

          <v-col cols="12">
            <v-btn color="green" @click="toggleModalFilter" dark
              >Nuevo Tablero
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <filtros />
  </v-container>
</template>

<style >
.customcard {
  border-radius: 25px;
  background-color: white;
  border: 1px solid #9b9b9b;
}

.cls-1 {
  fill: white;
}
</style>

<script>
// import CardAlianza from "../components/cardAlianza.vue";
// import filtrosService from "@/services/FiltrosService";
import graficosService from "@/services/GraficosService";
import axios from "axios";
import { mapState, mapMutations } from "vuex";
import Filtros from "../components/filtros.vue";
import Grafico from "@/components/grafico.vue";
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Graficos",
  components: { Filtros, Grafico },
  data() {
    return {
      modal: false,
      loading: true,
      intervalSeconds: 120,
      eliminando: false,
      filtros: [],
      graficoID: null,

      graficos: [],

    

    };
  },
  methods: {
    ...mapMutations(["closeModalEliminar", "toggleModalFilter"]),
   

    formatPorcentaje(val) {
      return parseFloat(val.toFixed(2));
    },


    series (grafico) {
      return [
        this.formatPorcentaje(grafico.resultado.fdtPorcentaje),
        this.formatPorcentaje(grafico.resultado.jpcPorcentaje),
        this.formatPorcentaje(grafico.resultado.restoPorcentaje),
      ]
    },

    getAll() {
      // console.log("le pega al getAll");
      axios
        .all([this.getGraficos()])
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          // this.showToast({
          //   vm: this,
          //   payload: {
          //     title: err.response.data.title || "Error",
          //     message: err.response.data.message || "",
          //     variant: "danger",
          //     status: err.response.status,
          //   },
          // });
        });
    },

    async eliminarFiltro() {
      try {
        this.eliminando = true;

        graficosService.del(this.filtroEliminar.id).then(() => {
          this.eliminando = false;
          this.getGraficos();
          this.closeModalEliminar();
        });

        // this.filtros = this.filtros.filter((filtro) => {
        //   filtro.id == this.filtroEliminar.id;
        // });
      } catch (error) {
        this.eliminando = false;
        console.log(error);
        throw error;
      }
    },

    async getGraficos() {
      try {
        let dataGraficos = await graficosService.all();

        this.graficos = dataGraficos.data;

        // this.$store.commit("setLastUpdate", this.fechaTotalizacion);

      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  },
  mounted() {
    this.getAll();
    setInterval(() => {
      this.user?.username != null &&  this.getAll();
    }, this.intervalSeconds * 1000);
  },
  computed: {
    ...mapState(["modalEliminar", "filtroEliminar", "user", "categorias"]),

   

    format: {
      get() {
        return this.$store.state.format;
      },
      set(val) {
        this.$store.commit("setFormat", val);
      },
    },
  },
};
</script>
