<template>
  <v-container fluid>

    <table v-if="print" dense>

      <thead>
        <tr>
          <th class="text-left">
            Orden
          </th>
          <th class="text-left">
            Entrante
          </th>
          <th class="text-left">
            Saliente
          </th>

        </tr>
      </thead>
      <tbody>
        <tr v-for="item in renovacion" :key="item.orden">
          <td>{{ item.orden }}</td>
          <td :class="[item.entro ? 'green--text' : 'red--text']">{{ item.entrante }}</td>
          <td>{{ item.saliente }}</td>

        </tr>
      </tbody>

    </table>

    <v-simple-table  v-else dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Orden
            </th>
            <th class="text-left">
              Entrante
            </th>
            <th class="text-left">
              Saliente
            </th>

          </tr>
        </thead>
        <tbody>
          <tr v-for="item in renovacion" :key="item.orden">
            <td>{{ item.orden }}</td>
            <td :class="[item.entro ? 'green--text' : 'red--text']">{{ item.entrante }}</td>
            <td>{{ item.saliente }}</td>

          </tr>
        </tbody>
      </template>
    </v-simple-table>

  </v-container>
</template>

<script>

export default {
  components: {},
  name: "TablaCandidatos",
  props: {
    canditatos: {
      type: Object
    },
    print:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
    }
  },

  created() { },
  computed: {

    renovacion() {


      let longitud = (this.canditatos.salientes.length >= this.canditatos.ganadas) ? this.canditatos.salientes.length : this.canditatos.ganadas
      longitud = (this.canditatos.entrantes.length >= longitud) ? this.canditatos.entrantes.length : longitud


      let aux = []
      for (let index = 1; index <= longitud; index++) {

        // console.log(this.canditatos);

        let candidatoSaliente = this.canditatos.salientes.find(c => c.orden == index)
        let candidatoEntrante = this.canditatos.entrantes.find(c => c.orden == index)


        aux.push({
          orden: index,
          entrante: candidatoEntrante ? candidatoEntrante.nombre : '',
          saliente: candidatoSaliente ? candidatoSaliente.nombre : '',
          entro: (index <= this.canditatos.ganadas) ? true : false
        })

      }


      // console.log(aux);
      return aux
    }

  },
  mounted() {

  },
  methods: {

  },

  watch: {
    canditatos() {
      console.log(this.canditatos);
    }
  }

};
</script>

<style></style>