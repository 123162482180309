import Api from '@/services/Api'
// const API_PARTIDO = "partido/";
// const API_URL = "";

export default {
    all(credentials) {
        return Api().get('bancas', credentials)
    },

  

    // add(credentials) {
    //     return Api().post(API_URL + 'add', credentials)
    // },

    // edit(credentials) {
    //     return Api().put(API_URL + API_PARTIDO + 'edit', credentials)
    // },


}