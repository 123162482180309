<template>
    <v-row >


        <v-container>

            <v-row>

                <v-col cols="10">

                    <div class=" black--text text-h5 text-center  mb-3">
                        Mesas testigo
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <v-col cols="3" class="justify-center">
            <apexchart :type="'donut'" :options="chartOptionsConDatos" :height="280" :width="'270'"
                :series="seriesConDatos">
            </apexchart>
        </v-col>

        <!-- <v-col auto v-if="$vuetify.breakpoint.name == 'xl'"></v-col> -->
        <v-col cols="3" class="justify-center">
            <apexchart :type="'donut'" :options="chartOptions" :height="280" :width="'300'" :series="series">
            </apexchart>
        </v-col>

      
        <v-col cols="3" class="justify-center">
            <apexchart :type="'donut'" :options="chartOptionsConTelegrama" :height="280" :width="'270'"
                :series="seriesConTelegrama">
            </apexchart>
        </v-col>


        <v-col cols="9" v-if="seriesVotosLinea.length > 0">
            <apexchart type="line" height="400" :options="chartOptionsVotosLinea" :series="seriesVotosLinea[0]">
            </apexchart>
        </v-col>
    </v-row>
</template>
<script>
import { mapActions, mapState } from "vuex";
// import TendenciaService from "@/services/TendenciaService";
import moment from 'moment'


export default {
    props: {
        tendencia: {
            type: Array
        },
        tendenciaLinea: {
            type: Object
        }
    },
    data: () => ({
        loading: true,
        intervalSeconds: 60,
        dialog: false,
        headers: [
            { text: "Establecimiento/Escuela", value: "ESTABLECIMIENTO_ESCUELA", sortable: true, align: 'left' },
            { text: "Provincia", value: "PROVINCIA", sortable: true, align: 'center' },
            { text: "Mesa número", value: "MESA", sortable: true, align: 'cneter' },
            { text: "Factor", value: "FEXP1", sortable: true, align: 'right' },
            { text: "Estrato", value: "ESTRATO", sortable: true, align: 'center' },
            { text: "UxP", value: "UxP", sortable: true, align: 'right' },
            { text: "JxC", value: "JxC", sortable: true, align: 'right' },
            { text: "LLA", value: "LLA", sortable: true, align: 'right' },
            { text: "Otros", value: "otros", sortable: true, align: 'right' },
            { text: "NH. Muestra total", value: "ocurrencia", sortable: true, align: 'right' },
            { text: "Ocu. Sin valor", value: "ocurrenciaSinValores", sortable: true, align: 'right', class: 'text-no-wrap' },
            { text: "Factor Expansión Ponderado", value: "factorActual", sortable: true, align: 'right' },
            { text: "UxP Ponderado", value: "UxP_Ponderado", sortable: true, align: 'right' },
            { text: "JxC Ponderado", value: "JxC_Ponderado", sortable: true, align: 'right' },
            { text: "LLA Ponderado", value: "LLA_Ponderado", sortable: true, align: 'right' },
            { text: "Otros Ponderado ", value: "otros_Ponderado", sortable: true, align: 'right' },
        ],
        // tendencia: [],
        editedIndex: -1,
        chartOptions: {},
        series: [],
        chartOptionsConDatos: {},
        seriesConDatos: [],
        chartOptionsConTelegrama: {},
        seriesConTelegrama: [],


        seriesVotosLinea: [

        ],
        chartOptionsVotosLinea: {
            chart: {
                height: 200,
                type: 'line',
            },
            stroke: {
                show: true,
                // curve: 'smooth',
                lineCap: 'round',
                // dashArray: 10,
                width: [0, 4, 4, 4, 4],
            },
            title: {
                text: 'Porcentaje votos'
            },
            dataLabels: {
                enabled: false,
                enabledOnSeries: [0],
                formatter: function (val) {
                    return val 
                },
                textAnchor: 'middle',
                distributed: false,
                offsetX: 0,
                offsetY: 0,
                style: {
                    fontSize: '14px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 'bold',
                    colors: ['#FFF']
                },
                background: {
                    enabled: false,
                    foreColor: '#fff',
                    padding: 4,
                    borderRadius: 2,
                    borderWidth: 1,
                    borderColor: '#fff',
                    opacity: 0.9,
                    dropShadow: {
                        enabled: false,
                        top: 1,
                        left: 1,
                        blur: 1,
                        color: '#000',
                        opacity: 0.45
                    }
                },
                dropShadow: {
                    enabled: false,
                    top: 1,
                    left: 1,
                    blur: 1,
                    color: '#000',
                    opacity: 0.45
                }
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (y) {
                        if (typeof y !== "undefined") {
                            return y + "%";
                        }
                        return y;

                    }
                }
            },
            labels: [],
            xaxis: {
                tickAmount: 50
            },
            yaxis: [
                {
                    title: {
                        text: 'Porcentaje de mesas escrutadas',
                    },
                    labels: {
                        formatter: (value) => value.toFixed(0) + '%',
                    },
                    min: 0,
                    max: 100,

                },
                {
                    opposite: true,
                    title: {
                        text: 'Porcentaje de votos'
                    },
                    labels: {
                        formatter: (value) => value.toFixed(0) + '%',
                    },
                    min: 0,
                    max: 100,
                }, {
                    show: false,
                    min: 0,
                    max: 100,
                }, {
                    show: false,
                    min: 0,
                    max: 100,
                }, {
                    show: false,
                    min: 0,
                    max: 100,
                }
            ],
        },
    }),

    watch: {

    },

    // created() {
    //     this.initialize();
    // },

    methods: {


        formatNumbers(value) {
            return new Intl.NumberFormat('es-AR').format(value);
        },
        ...mapActions("general", ["showToast", "setNotification"]),
        async initialize() {

            // let resu = await TendenciaService.all();
            // this.tendencia = resu.data.tendencias;
            this.mapearSeries([this.tendenciaLinea])
            let uxp = 0;
            // let jxc = 0;
            let lla = 0;
            // let otros = 0;
            let con_telegrama = 0;
            let con_datos = 0;
            this.tendencia.forEach(t => {

                t.UxP_Ponderado = Number(t.UxP_Ponderado.toFixed(2))
                // t.JxC_Ponderado = Number(t.JxC_Ponderado.toFixed(2))
                t.LLA_Ponderado = Number(t.LLA_Ponderado.toFixed(2))

                // t.otros_Ponderado = t.otros_Ponderado ? Number(t.otros_Ponderado.toFixed(2)) : 0

                uxp += t.UxP_Ponderado;
                // jxc += t.JxC_Ponderado;
                lla += t.LLA_Ponderado;
                // otros += t.otros_Ponderado ? t.otros_Ponderado : 0;
                t.TIENE_DATOS && (con_datos++);
                t.TIENE_TELEGRAMA && (con_telegrama++);
                t.MESA = `${t.PROVINCIA_COD.toString().padStart(2, '0')}${t.PARTIDO_COD.toString().padStart(3, '0')}${t.MESA_NRO.toString().padStart(5, '0')}X`;
                t.PROVINCIA = t.PROVINCIA + ' - ' + t.PARTIDO;
            });
            // this.series = [Math.round(uxp), Math.round(jxc), Math.round(lla), Math.round(otros)];
            this.series = [Math.round(uxp), Math.round(lla), ];
            this.chartOptions = {
                title: {
                    text: "Total de votos ponderado por agrupación",
                    align: 'center',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                        fontFamily: undefined,
                        color: '#263238'
                    },
                },

                theme: { palette: 'palette1' }, ...this.config(),
                colors: ['#009CDE', '#753BBD'],
                // colors: ['#009CDE', '#FEDD00', '#753BBD', '#808080'],
                labels: [`Union por la Patria: ${this.formatNumbers(Math.round(uxp)) }`, `La libertad avanza: ${this.formatNumbers(Math.round(lla))}`]
            }
            this.seriesConTelegrama = [con_telegrama, this.tendencia.length - con_telegrama];
            this.chartOptionsConTelegrama = {
                title: {
                    text: "Mesas con telegramas recibidos",
                    align: 'center',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                        fontFamily: undefined,
                        color: '#263238'
                    },
                },
                theme: { palette: 'palette4' }, ...this.configSinTotal(),
                labels: [`Mesas con telegrama: ${con_telegrama}`, `Sin telegrama aún: ${this.tendencia.length - con_telegrama}`]
            }
            this.seriesConDatos = [con_datos, this.tendencia.length - con_datos];
            this.chartOptionsConDatos = {
                title: {
                    text: "Mesas con datos digitados y totalizados",
                    align: 'center',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                        fontFamily: undefined,
                        color: '#263238'
                    },
                },
                theme: { palette: 'palette3' }, ...this.configSinTotal(),
                labels: [`Mesas con datos digitados: ${con_datos}`, `Sin datos aún: ${this.tendencia.length - con_datos}`]
            }
            this.loading = false;
        },
        mapearSeries(resultados) {

            let auxseries = []

            let labels = []
            let auxdata = []
            // console.log(resultados);

            if (Object.keys(resultados).length > 0) {




                resultados.forEach((r) => {

                    auxdata = [
                        {
                            name: 'Mesas',
                            type: 'column',
                            data: [],
                            votos: [],
                            color: '#BDBDBD'
                        },
                        {
                            name: 'Union por la patria',
                            type: 'line',
                            data: [],
                            votos: [],
                            color: '#009cde'
                        },
                        // {
                        //     name: 'Juntos por el cambio',
                        //     type: 'line',
                        //     data: [],
                        //     votos: [],
                        //     color: '#FEDD00'
                        // },
                        {
                            name: 'La libertad avanza',
                            type: 'line',
                            data: [],
                            votos: [],
                            color: '#753BBD'

                        },
                      
                        // {
                        //     name: 'Otros',
                        //     type: 'line',
                        //     data: [],
                        //     votos: [],
                        //     color: '#6E6E6E'
                        // }
                    ]

                    r.filtroDetalles.forEach((d) => {




                        auxdata[0].data.push(d.mesasTotalizadasPorcentaje)
                        auxdata[0].votos.push(d.mesasTotalizadas)

                        // auxdata[1].data.push(d.jxcporcentajevotos)
                        // auxdata[1].votos.push(d.jxcvotos)

                        auxdata[2].data.push(d.llaporcentajevotos)
                        auxdata[2].votos.push(d.llavotos)

                        auxdata[1].data.push(d.uxpporcentajevotos)
                        auxdata[1].votos.push(d.uxpvotos)

                        // auxdata[4].data.push(d.otroporcentajevotos)
                        // auxdata[4].votos.push(d.totalVotosOtro)

                        //  labels.push (4)
                        //  labels.push ()

                    })
                    // console.log("🚀 ~ file: Pronostico.vue:408 ~ resultados.forEach ~ r:", r)



                    auxseries.push(auxdata)
                    // this.auxseries[index].push(auxdata)
                })


                if (resultados.length) {
                    if (resultados[0].filtroDetalles.length > 0) {
                        resultados[0].filtroDetalles.forEach((d) => {
                            labels.push(moment(d.fechaTotalizacion).format('HH:mm'))
                        })

                    }
                }
            }

            this.chartOptionsVotosLinea.labels = labels
            this.seriesVotosLinea = auxseries

        }
        ,
        config() {
            return {
                plotOptions: {
                    pie: {
                        donut: {
                            size: "50%",
                            labels: {
                                show: false,
                                name: {
                                    show: true,
                                    fontSize: "5px",
                                    color: "#000",
                                },
                                value: {
                                    show: true,
                                    fontSize: "22px",
                                    fontWeight: "bold",
                                    color: "#000",
                                    offsetY: 16,
                                    formatter: (val) => {
                                        return Number(val).toLocaleString();
                                    },
                                },
                                total: {
                                    show: false,
                                    fontSize: "22px",
                                    label: "Total",
                                    fontWeight: "bold",
                                    color: "#000",
                                    // formatter: function (w) {
                                    //   return w.globals.seriesTotals.reduce(
                                    //     (a, b) => {
                                    //       return a + b;
                                    //     },
                                    //     0
                                    //   );
                                    // },
                                    formatter: () => {
                                        // console.log('char',votantes);
                                        return 0;
                                    },
                                },
                            },
                        },
                    },
                },
                chart: {
                    type: "donut",
                    width: '100%',
                    animations: {
                        enabled: false,
                        // easing: "easeinout",
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150,
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350,
                        },
                    },
                },
                legend: {
                    position: "bottom",
                    floating: false,
                    // formatter: function (seriesName, opts) {
                    //     return [seriesName, " - ", ((opts.w.globals.series[opts.seriesIndex] * 100) / opts.w.globals.series.reduce((prev, sig) => prev + sig, 0)).toFixed(1) + '%']
                    // }
                },
            }
        },
        configSinTotal() {
            return {
                plotOptions: {
                    pie: {
                        donut: {
                            size: "50%",
                        },
                    },
                },
                chart: {
                    type: "donut",
                    animations: {
                        enabled: false,
                        easing: "easeinout",
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150,
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350,
                        },
                    },
                },
                legend: {
                    position: "bottom",
                    floating: false,
                    // formatter: function (seriesName, opts) {
                    //     return [seriesName, " - ", ((opts.w.globals.series[opts.seriesIndex] * 100) / opts.w.globals.series.reduce((prev, sig) => prev + sig, 0)).toFixed(1) + '%']
                    // }
                },
            }
        }
    },

    mounted() {
        // console.log("LLEgo");
        this.initialize();

        // this.user?.username != null && this.initialize();


    },

    computed: {
        ...mapState(["user"]),
    }
};
</script>