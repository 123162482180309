<template>
  <v-card :loading="!form.ingresar" class="pb-3 login">
    <!-- <p class="title text-h5 mt-2 text-center">Login</p>
    <hr />-->

    <v-card-title class="justify-center">Iniciar sesión</v-card-title>
    <v-card-actions>
      <v-divider></v-divider>
    </v-card-actions>
    <v-card-text>
      <ValidationObserver ref="login">
        <v-container class="pb-0">
          <v-row>
            <v-col cols="12">
              <ValidationProvider
                name="Email"
                :rules="'required'"
                mode="eager"
                v-slot="{ errors }"
              >
                <v-text-field
                  label="Usuario"
                  dense
                  prepend-inner-icon="mdi-account"
                  type="text"
                  autocomplete="username"
                  v-model="form.user"
                  outlined
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12">
              <ValidationProvider
                name="Password"
                :rules="'required'"
                mode="lazy"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="form.password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  prepend-inner-icon="mdi-key"
                  autocomplete="current-password"
                  :type="showPassword ? 'text' : 'password'"
                  label="Contraseña"
                  dense
                  outlined
                  @keypress.enter="login"
                  @click:append="showPassword = !showPassword"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-container>
      </ValidationObserver>
    </v-card-text>

    <v-card-actions class="pt-0 justify-center">
      <v-btn
        small
        rounded
        color="primary"
        @click="login"
        :loading="!form.ingresar"
        >Ingresar</v-btn
      >
    </v-card-actions>
    <v-card-actions>
      <v-divider></v-divider>
    </v-card-actions>
    <v-card-actions>
      <!-- <v-row justify="center">
        <v-btn
          text
          small
          color="primary"
          class="text-capitalize"
          :to="{ name: 'recovery' }"
          >¿olvidaste tu contraseña?</v-btn
        >
      </v-row> -->
    </v-card-actions>
  </v-card>
</template>

<script>
import AuthenticationService from "@/services/AuthenticationService";
import { mapActions } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      showPassword: false,
      form: {
        user: "",
        password: "",
        ingresar: true,
      },
      error: {
        show: false,
        message: "",
      },
    };
  },
  methods: {
    ...mapActions("general", ["showToast", "setNotification"]),

    async validate() {
      let result = await this.$refs.login.validate();
      return result;
    },

    async login() {
      let valid = await this.validate();

      if (valid) {
        try {
          this.form.ingresar = false;
          const { user, password } = this.form;
          const response = await AuthenticationService.login({
            username: user,
            password: password,
          });
          this.$store.dispatch("setToken", response.data.token);
          this.$store.dispatch("setId", response.data.id);
          this.$store.dispatch("setRol", response.data.rol);
          this.$store.dispatch("setUsername", response.data.username || user);
          this.$router.push("/");
        } catch (err) {
          this.form.ingresar = true;

          this.setNotification({
            title: "Error",
            message:
              err.response.data.message || "Error al intentar iniciar sesión",
            timeout: "6000",
            color: "error",
          });

          // this.showToast({
          //   vm: this,
          //   payload: {
          //     title: "Error",
          //     message: err.response.data.message,
          //     variant: "danger",
          //   },
          // });
        }
      }
    },
  },
};
</script>

<style scope>
.title {
  color: #1f1f1f;
}
</style>
