<template>
  <v-container fluid>


      <v-row>
        <v-col cols="12">

          <div>

            <p class="text-center text-h5"> Senadores Nacionales</p>
            <p class="text-right text-subtitle-2 px-3 my-0"> Mesas escrutadas:
              {{ bancas.senadores_nacionales.estadoRecuento.mesasTotalizadas + ' / ' +
                bancas.senadores_nacionales.estadoRecuento.porcentajeTotalizado.toFixed(2) }}% </p>

            <camara print :legisladores="bancas.senadores_nacionales" tipoCamara="senadores_nacionales" class="pb-5"></camara>





          </div>

          <div class="mt-3">

            <p class="text-center text-h5"> Diputados Nacionales</p>
            <p class="text-right text-subtitle-2 px-3 my-0"> Mesas escrutadas:
              {{ bancas.diputados_nacionales.estadoRecuento.mesasTotalizadas + ' / ' +
                bancas.diputados_nacionales.estadoRecuento.porcentajeTotalizado.toFixed(2) }}% </p>

            <camara print :legisladores="bancas.diputados_nacionales" tipoCamara="diputados_nacionales"></camara>


            <div class="white mx-3">
              <v-row>
                <v-col cols="12">
                  <v-select class="px-3" :items="bancas.diputados_nacionales.desglose_actual" item-text="Distrito"
                    item-value="IdDistrito" v-model="distritoBancas" label="Distritos" outlined></v-select>
                </v-col>
                <v-col cols="6">

                  <div class="text-center text-subtitle-1"> Bancas - Unión por la patria </div>
                  <tablaCandidatos print :canditatos="bancasDistrito('UxP', distritoBancas, bancas.diputados_nacionales)" />

                </v-col>
                <v-col cols="6">
                  <div class="text-center text-subtitle-1"> Bancas - Juntos por el cambio </div>
                  <tablaCandidatos print :canditatos="bancasDistrito('JxC', distritoBancas, bancas.diputados_nacionales)" />
                </v-col>
                <v-col cols="6">

                  <div class="text-center text-subtitle-1"> Bancas - La libertad avanza </div>
                  <tablaCandidatos  print :canditatos="bancasDistrito('LLA', distritoBancas, bancas.diputados_nacionales)" />
                </v-col>
              </v-row>
            </div>




          </div>

          <div class="mt-5">

            <p class="text-center text-h5"> Senadores Provinciales</p>
            <p class="text-right text-subtitle-2 px-3 my-0"> Mesas escrutadas:
              {{ bancas.senadores_prov_ba.estadoRecuento.mesasTotalizadas + ' / ' +
                bancas.senadores_prov_ba.estadoRecuento.porcentajeTotalizado.toFixed(2) }}% </p>

            <camara print :legisladores="bancas.senadores_prov_ba" tipoCamara="senadores_prov_ba" class="pb-5"></camara>




          </div>

          <div class="mt-3">


            <p class="text-center text-h5"> Diputados Provinciales</p>
            <p class="text-right text-subtitle-2 px-3 my-0"> Mesas escrutadas:
              {{ bancas.diputados_prov_ba.estadoRecuento.mesasTotalizadas + ' / ' +
                bancas.diputados_prov_ba.estadoRecuento.porcentajeTotalizado.toFixed(2) }}% </p>

            <camara :legisladores="bancas.diputados_prov_ba" tipoCamara="diputados_prov_ba"></camara>

          </div>


        </v-col>

      </v-row>



      <!-- <v-btn class="float-right d-print-none " color="primary" small @click="print()">
          <v-icon>mdi-download</v-icon>
        </v-btn> -->


  </v-container>
</template>

<script>
import axios from "axios";
// import bancasService from "@/services/BancasService";
import { mapState } from "vuex";
import camara from "../../components/camara.vue";
import tablaCandidatos from '../../components/TablaCandidatos.vue'
import candidatosJson from '../../json/candidatos.json'
export default {
  components: { camara, tablaCandidatos },
  name: "CamarasInforme",
  props: {
    bancas: {
      type: Object
    },
    distritoBancas : {
      type:String,
      default:'01'
    },
   
  },
  data() {
    return {
      intervalSeconds: 180,
      loading: true,
      // bancas: [],
      tabID: 0,
      canditatos: candidatosJson,
      distritos: [],
      // distritoBancas: '01'
    };
  },

  mounted() {

    console.log(this.bancas);
    // this.getAll();
    // this.user?.username != null && this.getAll();
    // setInterval(() => {
    // }, this.intervalSeconds * 1000);
  },
  methods: {


    print() {
      window.print()
    },

    bancasDistrito(partido, distrito, camara) {
      let distritoAux = this.canditatos.find(d => d.distrito.id == Number(distrito));
      let actualDis = camara.desglose_actual.find(d => d.IdDistrito == distrito)

      let agr = {}

      let res = {
        entrantes: [],
        salientes: [],
        ganadas: 0
      }
      let p = ''

      if (distritoAux) {

        agr = distritoAux.agrupaciones.find(a => a.agrupacion.nombre == partido)
        // console.log("🚀 ~ file: Camaras.vue:125 ~ bancasDistrito ~ agr:", agr)

        if (partido == "UxP") p = 'FDT'
        else if (partido == "JxC") p = 'JXC'
        else if (partido == "LLA") p = 'LLA'
        else p = partido
        let auxFue = actualDis.Fuerzas.find((f) => f.Fuerza == p);

        res.entrantes = agr ? agr.agrupacion.candidatosEntrantes : 0
        res.salientes = agr ? agr.agrupacion.candidatosSalientes : 0
        res.ganadas = auxFue ? auxFue.Ganadas : 0

      }
      return res
    },

    getAll() {
      // console.log("le pega al getAll");
      axios
        .all([this.getBancas()])
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getBancas() {
      try {
        // let dataBancas = await bancasService.all();

        // this.bancas = dataBancas.data;
        // console.log("🚀 ~ file: Camaras.vue:156 ~ getBancas ~ this.bancas:", this.bancas)


        // this.instanciarFormato();
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  },
  created() { },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style></style>